import React, { useContext, useState, useEffect, useRef } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import { Space, Input, Button, Form, Select, Modal, message, Alert, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postTicket, postTicketHistory } from 'src/api/teacket';
import { getAccountForOrgId, getAccountDeployments } from 'src/api/snowflake';
import useUserInfo from 'src/hooks/useUserInfo';
import { notArrayOrEmpty, notEmptyArray, splitBufferIntoBatches } from 'src/misc/Misc';
import { getServicesIsMaster } from 'src/api/teacket';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import PropertySection from 'src/components/layout/PropertySection';
import SearchServiceUser from 'src/components/pages/Teacket/Components/ServiceUserPicker';
import { CONTENT_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../Misc/misc';
import { uuid } from 'short-uuid';
import UserImage from 'src/components/layout/UserImage';
import TicketCreateCompletionDates from './TicketCreateCompletionDates';
import CategoryUrls from '../../Components/CategoryUrls';
import TicketCreateSharepointFiles from './TicketCreateSharepointFiles';
import { postTeacketCreateUploadSession, patchTeacketUpdateFields, patchTeacketUpdatePermission } from 'src/api/microsoft';
import { putUploadFile } from 'src/api/common';
import dayjs from 'dayjs';
import StakeholderDetails from '../../Components/StakeholderDetails';
import { FileExclamationOutlined } from '@ant-design/icons';

const TicketCreateControl = () => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [createTicketForm] = Form.useForm();
  const { servicesForTenant, getServiceSettingForServiceId, currentTicketOppDetails, setCurrentTicketOppDetails, getWidgetListForServiceId } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const formRef = useRef(null);

  //#region Use States

  const [isMaster, setIsMaster] = useState(null);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(null);
  const [requesterModalOpen, setRequesterModalOpen] = useState(false);
  const [requester, setRequester] = useState(null);
  const [requesterImage, setRequesterImage] = useState(null);
  const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [accOrOppMandatory, setAccOrOppMandatory] = useState(null);
  const [deploymentOptions, setDeploymentOptions] = useState(null);
  const [isOkDisabled, setIsOkDisabled] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [showCompletionDates, setShowCompletionDates] = useState(false);
  const [showSharepointFiles, setShowSharepointFiles] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [notRelatedToAnOpportunity, setNotRelatedToAnOpportunity] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [requiredFileForSubCategoryIsMissing, setRequiredFileForSubCategoryIsMissing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  //#endregion

  //#region Use Effects

  useEffect(() => {
    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
    if (servicesForTenant?.length === 1) {
      createTicketForm.setFieldValue('service_id', servicesForTenant?.[0]?.id);
      handleOnServiceChange(servicesForTenant?.[0]?.id);
    }
  }, []);

  useEffect(() => {
    console.log('deploymentOptions:', deploymentOptions);
  }, [deploymentOptions]);

  // useEffect(() => {
  //   console.log('=== account:', account);
  //   createTicketForm.setFieldValue('acc_id', account?.ACCOUNT_ID ?? undefined);
  //   createTicketForm.setFieldValue('acc_name', account?.ACCOUNT_NAME ?? undefined);
  // }, [account]);

  useEffect(() => {
    // console.log('=== account:', account);
    // console.log('=== opportunity:', opportunity);

    createTicketForm.setFieldValue('acc_id', (opportunity?.ACCOUNT_ID || account?.ACCOUNT_ID) ?? undefined);
    createTicketForm.setFieldValue('acc_name', (opportunity?.ACCOUNT_NAME || account?.ACCOUNT_NAME) ?? undefined);
    createTicketForm.setFieldValue('opp_id', opportunity?.OPPORTUNITY_ID ?? undefined);
    createTicketForm.setFieldValue('opp_name', opportunity?.OPPORTUNITY_NAME ?? undefined);

    setCurrentTicket(convertToObject(createTicketForm));

    // console.log('=== current ticket aaa 1:', currentTicket);
    // console.log('=== current ticket aaa 2:', createTicketForm.getFieldsValue(true));
  }, [account, opportunity]);

  useEffect(() => {
    // console.log('=== form values:', createTicketForm.getFieldsValue(true));
    setCurrentTicket(convertToObject(createTicketForm));
  }, [createTicketForm]);

  useEffect(() => {
    console.log('Current ticket:', currentTicket);
  }, [currentTicket]);

  //#endregion

  //#region "FORM HANDLERS"

  const handleFormOnFinish = async (values) => {
    console.log('Success:', values, currentTicketOppDetails);

    //#region "ADDITIONAL VALIDATIONS"

    if (true === accOrOppMandatory) {
      if (!createTicketForm.getFieldValue('acc_id') || !createTicketForm.getFieldValue('acc_name')) {
        messageApi.open({
          type: 'error',
          content: 'Please select an account or an opportunity',
        });
        return;
      }
    }

    if (true === notEmptyArray(fileList) && !createTicketForm.getFieldValue('acc_name')) {
      messageApi.open({
        type: 'error',
        content: 'Please select opportunity or account before uploading files',
      });
      return;
    }

    //#endregion

    const body = { ...values, content: values?.content, status: createTicketForm.getFieldValue('status'), owner_name: undefined };

    // add SF opportunity fields
    if (true === snowflakeOpportunitySupport) {
      body.props = currentTicketOppDetails;
    }

    const key = uuid();
    await executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting the ticket...',
          duration: 0,
        });
        const ticket = await postTicket(body);

        if (true === notEmptyArray(fileList) && ticket) {
          for (const file of fileList) {
            messageApi.open({
              key,
              type: 'loading',
              content: `Uploading file ${file.name}...`,
              duration: 0,
            });
            await uploadFile(ticket.id, file);
          }
        }

        messageApi.open({
          key,
          type: 'success',
          content: 'Ticket submitted',
        });
        if (ticket) {
          navigate(`/teacket/tickets/${ticket.id}`);
        }
      } catch (error) {
        console.error(error);
        messageApi.open({
          key,
          type: 'error',
          content: 'Failed to submit ticket',
        });
      }
    });
  };

  const handleFormOnFailure = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormReset = () => {
    setSubCategoryList([]);
    setCategoryList([]);
    setPriorityList([]);
    setDeploymentOptions(null);
    setGcDeploymentSupport(null);
    setAccOrOppMandatory(null);
    setShowCompletionDates(false);
    setShowSharepointFiles(false);
    setCategoryUrlList(null);
    setIsMaster(null);
  };

  const handleOnServiceChange = async (value) => {
    // <reset owner>
    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
    setRequesterImage(userInfo.user_pic);
    // </reset owner>

    // <reset form options>
    setDefaultStatus(value);
    setPriorityOptions(value);
    setCategoryOptions(value);
    setShowCompletionDates(false);
    setShowSharepointFiles(false);
    setCategoryUrlList(null);
    // </reset form options>

    // <reset selected acc or opp>
    createTicketForm.setFieldValue('acc_id', undefined);
    createTicketForm.setFieldValue('acc_name', undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);
    createTicketForm.setFieldValue('category', undefined);
    createTicketForm.setFieldValue('sub_category', undefined);
    setCurrentTicketOppDetails(null);
    // </reset selected acc or opp>

    // <determine if the current user's tenant is a master one for the selected service>
    setIsMaster(null);
    const im = await isTenantMasterForService(value);
    setIsMaster(im);
    // </determine if the current user's tenant is a master one for the selected service>

    // <determine account>
    if (true !== im) {
      await determineAccount();
    }
    // </determine account>

    // <determine if snowflake opportunity support is enabled>
    const sf = getServiceSettingForServiceId(value, 'SnowflakeOpportunitySupport');
    console.log('sf', sf);
    setSnowflakeOpportunitySupport('1' === sf);
    // </determine if snowflake opportunity support is enabled>

    // <determine if GC deployment support is enabled>
    const gc = getServiceSettingForServiceId(value, 'GcDeploymentSupport');
    setGcDeploymentSupport('1' === gc);
    // </determine if GC deployment support is enabled>

    // <determine if GC acc or opp is mantadory>
    const aom = getServiceSettingForServiceId(value, 'AccOrOppMandatory');
    setAccOrOppMandatory('1' === aom);
    // </determine if GC acc or opp is mantadory>

    // <determine if completion dates should be shown>
    const cd = getWidgetListForServiceId(value)?.find((x) => x.widgetId === 'Completion Dates') ? true : false;
    setShowCompletionDates(cd);
    // </determine if completion dates should be shown>

    // <determine if sharepoint files should be shown>
    const sp = getWidgetListForServiceId(value)?.find((x) => x.widgetId === 'Sharepoint Files') ? true : false;
    setShowSharepointFiles(sp);
    // </determine if sharepoint files should be shown>
  };

  const handleCategorySelect = (value) => {
    setSelectedCategory(value);
    createTicketForm.setFieldValue('sub_category', undefined);
    setSubCategoryOptions(value);
    setCategoryUrls(value);
  };

  const handleSubCategorySelect = (value) => {
    setSelectedSubCategory(value);
    const selectedSubCat = subCategoryList.find((item) => item.value === value);
    console.log('Selected sub category:', selectedSubCat);
    if (selectedSubCat?.fileOrLinkRequired) {
      setRequiredFileForSubCategoryIsMissing(true);
    } else setRequiredFileForSubCategoryIsMissing(false);
  };

  const handleOnDeploymentChange = (value) => {
    console.log('Selected deployment:', value);
    const deployment = deploymentOptions.find((item) => item.ORG_ID === value);
    console.log('deployment', deployment?.ORG_NAME__C);
    createTicketForm.setFieldValue('dep_name', deployment?.ORG_NAME__C);
  };

  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk');
    createTicketForm.setFieldValue('acc_id', account?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', account?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);
    setAccountModalOpen(false);
    setCurrentTicketOppDetails(null);
    if (gcDeploymentSupport && account?.ACCOUNT_ID) getDeploymentsForAccount(account?.ACCOUNT_ID);
  };

  const handleOpportunityModalOnOk = () => {
    console.log('handleOpportunityModalOnOk');
    createTicketForm.setFieldValue('acc_id', opportunity?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', opportunity?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', opportunity?.OPPORTUNITY_ID ?? undefined);
    createTicketForm.setFieldValue('opp_name', opportunity?.OPPORTUNITY_NAME ?? undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);

    setOpportunityModalOpen(false);
    if (gcDeploymentSupport && account?.ACCOUNT_ID) getDeploymentsForAccount(account?.ACCOUNT_ID);

    setCurrentTicketOppDetails(opportunity);
  };

  const handleAccountModalOnSelect = async (v) => {
    console.log('handleAccountModalOnSelect', v);
    setAccount(v);
  };

  const handleRequesterModalOnOk = () => {
    console.log('handleRequesterModalOnOk');
    createTicketForm.setFieldValue('owner_id', requester?.user_id ?? undefined);
    createTicketForm.setFieldValue('owner_name', requester?.user_name ?? undefined);
    setRequesterImage(requester?.user_pic);
    setRequesterModalOpen(false);
  };

  const handleOpportunityModalOnSelect = (v) => {
    console.log('handleOpportunityModalOnSelect', v);
    setOpportunity(v);
  };

  const handleRequesterModalOnSelect = (v) => {
    console.log('handleRequesterModalOnSelect', v);
    setRequester(v);
  };

  const handleSharepointFilesOnChange = (files) => {
    console.log('handleSharepointFilesOnChange()', files);
    setFileList(files);
  };

  const handleNotRelatedToOpportunityChange = (e) => {
    const isChecked = e.target.checked;
    setNotRelatedToAnOpportunity(isChecked);

    setAccount(null);
    setOpportunity(null);
    createTicketForm.setFieldValue('acc_id', undefined);
    createTicketForm.setFieldValue('acc_name', undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    // if (!isChecked) {
    //   setAccount(null);
    //   createTicketForm.setFieldValue('acc_id', undefined);
    //   createTicketForm.setFieldValue('acc_name', undefined);
    // } else {
    //   setOpportunity(null);
    //   createTicketForm.setFieldValue('opp_id', undefined);
    //   createTicketForm.setFieldValue('opp_name', undefined);
    // }
  };

  //#endregion

  //#region "VALUES MANIPULATION"

  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryUrlList, setCategoryUrlList] = useState([]);

  const setPriorityOptions = async (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      let prioList = null;
      if (true === (await isTenantMasterForService(service_id))) {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList : [];
      } else {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList.filter((x) => !x.masterOnly) : [];
      }
      setPriorityList(prioList);
      const defaultPriority = service?.props?.priorityList?.find((status) => true === status.default);
      if (!defaultPriority) return;
      createTicketForm.setFieldValue('priority', defaultPriority.priority);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load priority options',
        duration: 3,
      });
    }
  };

  const setDefaultStatus = (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const defaultStatus = service?.props?.statusList?.find((status) => true === status.default);
      if (!defaultStatus) throw new Error('Default status not found');
      createTicketForm.setFieldValue('status', defaultStatus.status);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load status',
        duration: 3,
      });
    }
  };

  const setCategoryOptions = (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const cl = (true === notEmptyArray(service?.props?.categoryList) ? service.props?.categoryList : [])
        .filter((i) => !i.hideForRequester)
        .map((item) => {
          return { value: item.category, label: item.displayName || item.category || 'n/a', description: item.description || '' };
        });
      setCategoryList(cl);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load category options',
        duration: 3,
      });
    }
  };

  const setSubCategoryOptions = (category) => {
    try {
      const service_id = createTicketForm.getFieldValue('service_id');
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const categoryItem = service?.props?.categoryList?.find((item) => item.category === category);
      const scl = (true === notEmptyArray(categoryItem?.items) ? categoryItem.items : []).map((item) => {
        return { value: item.subCategory, label: item.displayName || item.subCategory || 'n/a', description: item.description || '', fileOrLinkRequired: item.fileOrLinkRequired || false };
      });
      setSubCategoryList(scl);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load sub category options',
        duration: 3,
      });
    }
  };

  const setCategoryUrls = (category) => {
    try {
      const c = categoryList.find((x) => x.category === category);
      if (!c || true === notArrayOrEmpty(c?.urlList)) {
        setCategoryUrlList(null);
        return;
      }
      setCategoryUrlList(c.urlList);
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region "MISC"

  const isTenantMasterForService = async (service_id) => {
    let result = null;
    await executeAsyncProcess(async () => {
      try {
        const resp = await getServicesIsMaster(service_id);
        result = resp?.isMaster === true;
      } catch (error) {
        console.error(error);
        return false;
      }
    });
    return result;
  };

  const determineAccount = async () => {
    await executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name, deployments } = await getAccountForOrgId(true);
        console.log('determineAccount', acc_id, acc_name);

        createTicketForm.setFieldValue('acc_id', acc_id);
        createTicketForm.setFieldValue('acc_name', acc_name);

        setCurrentTicket(convertToObject(createTicketForm));

        if (deployments) setDeploymentOptions(deployments);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getDeploymentsForAccount = async (acc_id) =>
    await executeAsyncProcess(async () => {
      try {
        const deployments = await getAccountDeployments(acc_id);
        setDeploymentOptions(deployments);
      } catch (error) {
        console.error(error);
      }
    });

  const uploadFile = async (ticket_id, file) => {
    //#region "create upload session"
    const { uploadUrl } = (await postTeacketCreateUploadSession(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name)) ?? {};
    const BATCH_SIZE = 4000000;
    //#endregion

    //#region "upload file to sharepoint"
    const fileData = new Blob([file]);
    const arrayBuffer = await fileData.arrayBuffer();
    const batches = splitBufferIntoBatches(arrayBuffer, BATCH_SIZE);
    if (notArrayOrEmpty(batches)) throw new Error('The buffer is empty');
    for (const i in batches) {
      console.log(`[TicketCreateControl] uploading batch ${Number(i) + 1} of ${batches.length}`);
      await putUploadFile(uploadUrl, batches[i].batch, batches[i].from, batches[i].to, arrayBuffer.byteLength);
    }
    //#endregion

    //#region "update fields"
    const fields = { RequestID: ticket_id, OppName: createTicketForm.getFieldValue('opp_name'), OppID: createTicketForm.getFieldValue('opp_id'), AccName: createTicketForm.getFieldValue('acc_name'), AccID: createTicketForm.getFieldValue('acc_id') };
    console.log(`[TicketCreateControl] updating fields:\r\n${JSON.stringify(fields, null, 2)}`);
    await patchTeacketUpdateFields(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name, fields);
    //#endregion

    //#region "update permission"
    await patchTeacketUpdatePermission(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name);
    //#endregion

    //#region "update history"
    await postTicketHistory(ticket_id, 'SharePoint', 'Upload', { file: file.name });
    //#endregion
  };

  //#endregion

  const convertToObject = (form) => {
    if (!form) return {};

    const obj = {};
    for (const [key, value] of Object.entries(form.getFieldsValue(true))) {
      obj[key] = value;
    }

    // Need to load props for the opportunity
    if (opportunity) {
      obj.props = opportunity;
    }
    // } else {
    //   obj.opp_id = undefined;
    //   obj.opp_name = undefined;
    // }

    if (account) {
      obj.props = account;
    }
    // } else {
    //   obj.acc_id = undefined;
    //   obj.acc_name = undefined;
    // }

    // console.log('=== converted form to object:', obj);
    return obj;
  };

  // Triggerred when the stakeholder details are updated by the user
  const handleTicketPropertiesChange = async (properties) => {
    console.log('[TicketCreateControl] Updated properties', properties);

    setOpportunity({ ...opportunity, ...properties });
  };

  const isThereUrl = () => {
    let url = false;
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    if (urlPattern.test(createTicketForm.getFieldValue('content'))) {
      url = true;
    }

    if (urlPattern.test(createTicketForm.getFieldValue('title'))) {
      url = true;
    }
    console.log('URL in Title or Content present:', url);
    return url;
  };

  return (
    <div>
      {contextHolder}

      {accountModalOpen && (
        <Modal
          title={'Search for account'}
          open={true}
          onCancel={() => {
            setAccountModalOpen(false);
          }}
          onOk={handleAccountModalOnOk}>
          <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
        </Modal>
      )}
      {opportunityModalOpen && (
        <Modal
          title={account?.ACCOUNT_ID ? 'Select or search for a New Opportunity' : 'Search for opportunity'}
          open={true}
          onCancel={() => {
            setOpportunityModalOpen(false);
          }}
          onOk={handleOpportunityModalOnOk}
          okButtonProps={{ disabled: isOkDisabled }}>
          <SnowflakeOpportunityPicker onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} limit={20} accountId={account?.ACCOUNT_ID ?? undefined} />
        </Modal>
      )}
      {requesterModalOpen && (
        <Modal
          title={'Search for requester'}
          open={true}
          onCancel={() => {
            setRequesterModalOpen(false);
          }}
          onOk={handleRequesterModalOnOk}>
          <SearchServiceUser serviceId={createTicketForm.getFieldValue('service_id')} onSelect={handleRequesterModalOnSelect} />
        </Modal>
      )}
      <Form ref={formRef} form={createTicketForm} name='ticket-create-form' initialValues={{ remember: true }} onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} onReset={handleFormReset} autoComplete='off' layout='vertical'>
        <div>
          <div>
            <Form.Item label='Service' name='service_id' rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder='Select a service...' onChange={handleOnServiceChange}>
                {servicesForTenant?.map((service) => (
                  <Select.Option key={service.id} value={service.id}>
                    {service.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* Requester */}
            {createTicketForm.getFieldValue('service_id') && true === isMaster && (
              <div className='mb-6'>
                {/* <REQUESTER> */}
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    <div className='flex flex-row gap-2'>
                      <div className='flex-initial w-56'>
                        <Form.Item label='Requester Name' name='owner_name' rules={[{ required: true, message: 'This field is required' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item label=' '>
                          <Button type='primary' icon={<SearchOutlined />} onClick={() => setRequesterModalOpen(true)}></Button>
                        </Form.Item>
                      </div>

                      <div className='flex-auto'>
                        <Form.Item hidden label='Requester ID' name='owner_id' rules={[{ required: true, message: 'This field is required' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                        <div className='mt-3 text-right'>
                          <UserImage image={requesterImage} size={'48px'} scaleOnHover={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PropertySection>
                {/* </ REQUESTER> */}
              </div>
            )}
            {/* Account & Opportunity */}
            {createTicketForm.getFieldValue('service_id') && (
              <div className='mb-6'>
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    {/* Account */}
                    {(notRelatedToAnOpportunity || false === snowflakeOpportunitySupport) && (
                      <div className='flex flex-row gap-2'>
                        <div className='flex-initial w-56'>
                          <Form.Item label='Account Id' name='acc_id' rules={[{ required: false }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                        <div className='flex-auto'>
                          <Form.Item label='Account Name' name='acc_name' rules={[{ required: false }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                        <div>
                          {true === isMaster && (
                            <Form.Item label=' '>
                              <Button type='primary' icon={<SearchOutlined />} onClick={() => setAccountModalOpen(true)}></Button>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    )}
                    {true === gcDeploymentSupport && deploymentOptions?.length === 0 && <Alert className='mb-4' style={{ width: '100%' }} message='No deployments were found for this account' type='info' showIcon closable />}
                    {/* Opportunity */}
                    {true === isMaster && true === snowflakeOpportunitySupport && (
                      <>
                        {!notRelatedToAnOpportunity && (
                          <div className='flex flex-row gap-2 items-start'>
                            <div className='flex-initial w-56'>
                              <Form.Item label='Opportunity Id' name='opp_id'>
                                <Input disabled={true} />
                              </Form.Item>
                            </div>
                            <div className='flex-auto'>
                              <Form.Item label='Opportunity Name' name='opp_name'>
                                <Input disabled={true} />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item label=' '>
                                <Button type='primary' icon={<SearchOutlined />} onClick={() => setOpportunityModalOpen(true)}></Button>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        <div>
                          <Checkbox checked={notRelatedToAnOpportunity} onChange={handleNotRelatedToOpportunityChange}>
                            Not related to an opportunity
                          </Checkbox>
                          {notRelatedToAnOpportunity && (
                            <div className='mt-2 mb-4'>
                              <Alert message='Tickets associated with revenue opportunities will be handled with higher priority' type='warning' showIcon />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className='pb-4 mt-2'>{(createTicketForm.getFieldValue('acc_id') || createTicketForm.getFieldValue('acc_name')) && <StakeholderDetails ticket={currentTicket} onPropertiesChange={handleTicketPropertiesChange} noEdit={true} />}</div>
                  </div>
                </PropertySection>
                {/* </ SF ACCOUNT OPPORTUNITY> */}
              </div>
            )}
            {gcDeploymentSupport && true === notEmptyArray(deploymentOptions) && (
              <Form.Item label='GC Deployment' name='dep_id'>
                <Select placeholder='Select a deployment...' onChange={handleOnDeploymentChange}>
                  {deploymentOptions?.map((item) => (
                    <Select.Option key={item.ORG_ID} value={item.ORG_ID}>
                      {item.ORG_NAME__C}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {/* Category */}
            <Form.Item label='Category' name='category' rules={[{ required: true, message: 'This field is required' }]}>
              <Select
                value={selectedCategory}
                showSearch={true}
                placeholder='Select a category...'
                onSelect={handleCategorySelect}
                disabled={!createTicketForm.getFieldValue('service_id')}
                style={{ width: '100%' }}
                optionRender={(option) => {
                  return (
                    <div className='flex flex-col'>
                      <div className='overflow-hidden'>{option.data.label}</div>
                      {option.data.description && <div className='text-xs font-light opacity-60'>{option.data.description}</div>}
                    </div>
                  );
                }}
                options={categoryList}
                autoComplete='off'
              />
            </Form.Item>
            {true === notEmptyArray(categoryUrlList) && (
              <div className='mb-6'>
                <PropertySection>
                  <CategoryUrls urlList={categoryUrlList} />
                </PropertySection>
              </div>
            )}
            {/* Sub Category */}
            <Form.Item label='Sub Category' name='sub_category' rules={[{ required: true, message: 'This field is required' }]}>
              <Select
                value={selectedSubCategory}
                showSearch={true}
                placeholder='Select a sub category...'
                onSelect={handleSubCategorySelect}
                disabled={!createTicketForm.getFieldValue('category')}
                style={{ width: '100%' }}
                optionRender={(option) => {
                  return (
                    <div className='flex flex-col'>
                      <div className='overflow-hidden'>{option.data.label}</div>
                      {option.data.description && <div className='text-xs font-light opacity-60'>{option.data.description}</div>}
                    </div>
                  );
                }}
                options={subCategoryList}
              />
              {requiredFileForSubCategoryIsMissing && <Alert className='mt-2 pt-1 pb-1' style={{ width: '100%' }} message='Do not forget to either attach a file or specify the URL in the description.' type='warning' showIcon />}
            </Form.Item>

            {/* Priority */}
            <Form.Item label='Priority' name='priority' rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder='Select a priority...' disabled={!createTicketForm.getFieldValue('service_id')}>
                {priorityList?.map((item) => (
                  <Select.Option key={item.priority} value={item.priority}>
                    {item.priority}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* Title */}
            <Form.Item
              label='Title'
              name='title'
              rules={[
                { required: true, message: 'This field is required' },
                { min: 20, message: 'This should contain at least 20 characters' },
              ]}>
              <Input placeholder='Enter a title for this ticket (20 characters min.)...' maxLength={TITLE_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>
            {/* Content */}
            <Form.Item label='Problem description' name='content' rules={[{ required: true, message: 'This field is required' }]}>
              <Input.TextArea placeholder='What is your problem?' autoSize={{ minRows: 4, maxRows: 8 }} maxLength={CONTENT_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>
            {/* Completion Dates */}
            {showCompletionDates && <TicketCreateCompletionDates form={createTicketForm} />}
            {/* Sharepoint Files */}
            {showSharepointFiles && (
              <PropertySection>
                <div className='my-2'>
                  <TicketCreateSharepointFiles onChange={handleSharepointFilesOnChange} />
                </div>
              </PropertySection>
            )}

            <Form.Item name='owner_id' hidden={true} />
            <Form.Item name='dep_name' hidden={true} />
            <Form.Item name='acc_id' hidden={true} />
            <Form.Item name='acc_name' hidden={true} />
            <Form.Item name='opp_id' hidden={true} />
            <Form.Item name='opp_name' hidden={true} />
          </div>
        </div>
        <div className='mt-4'>
          <Form.Item>
            <Space>
              <Button htmlType='reset'>Reset</Button>
              <Button
                type='primary'
                htmlType='submit'
                onClick={(e) => {
                  const hasUrl = isThereUrl();
                  const hasFiles = fileList?.length > 0;
                  if (requiredFileForSubCategoryIsMissing && !hasFiles && !hasUrl) {
                    e.preventDefault();
                    setIsModalVisible(true);
                  }
                }}>
                Submit
              </Button>
            </Space>
            <Modal
              title='Confirmation'
              open={isModalVisible}
              onOk={() => {
                setIsModalVisible(false);
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
              icon={<FileExclamationOutlined />}
              onCancel={() => setIsModalVisible(false)}>
              <div className='flex flex-row gap-4 mt-4'>
                <div className='flex-initial'>
                  <FileExclamationOutlined style={{ fontSize: '36px', color: '#faad14' }} />
                </div>
                A required file or link is missing. Are you sure you want to Submit?
              </div>
            </Modal>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

TicketCreateControl.propTypes = {};

export default TicketCreateControl;
