//#region Imports

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Button, message, Select, Spin, Space } from 'antd';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';
import { SendOutlined } from '@ant-design/icons';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getDashboardsTickets, getDistinctRequestStatuses, getDistinctRequestCategories, getDistinctRequestSubCategoriesForCategories, getServiceUsers, getDistinctDepartments, getDistinctWrapUpCodes } from 'src/api/teacket';
import { getDistinctRegions, getDistinctQuarters, getDistinctPlatforms, getDistinctForecasts, getDistinctStages, getDistinctTypes, getDistinctMarketSegments, getDistinctAccountGroupings, getDistinctOpportunities, getDistinctDirectIndirects, getDistinctCustomerTypes, getAccountsByName } from 'src/api/snowflake';
import { isRequestCancelled, notEmptyArray } from 'src/misc/Misc';
import UserImage from 'src/components/layout/UserImage';
import { MdOutlineEmail } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import { FiHash } from 'react-icons/fi';
import { TbWorldPin } from 'react-icons/tb';
import { PiIdentificationBadgeFill } from 'react-icons/pi';
import TicketTypePicker from '../../../MasterAdmin/TeacketServices/TeacketServiceEditor/TeacketServiceEditorRouting/TicketTypePicker';

//#endregion

const { Option } = Select;

const DashboardFilters = ({
  onSubmit,
  includeServices = true,
  includeRegions = false,
  includeSubRegions = false,
  includeQuarters = false,
  includeACVs = false,
  includePlatforms = false,
  includeForecasts = false,
  includeStages = false,
  includeTypes = false,
  includeTicketTypes = false,
  includeRequestStatuses = false,
  includeRequestCategories = false,
  includeRequestSubCategories = false,
  includeMarketSegments = false,
  includeAccountGroupings = false,
  includeAccounts = false,
  includeOpportunities = false,
  includeRequesters = false,
  includeProjectManagers = false,
  includePrimaryTeamMembers = false,
  includeSubjectMatterExperts = false,
  includeTeamMembers = false,
  includeDepartments = false,
  includeWrapUpCodes = false,
  includeDirectIndirects = false,
  includeCustomerTypes = false,
  includeRegionalCareOffers = false,
}) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const {
    servicesForTenant,
    dashboardListServices,
    dashboardRegions,
    setDashboardRegions,
    setDashboardSubRegions,
    setDashboardQuarters,
    setDashboardACVs,
    setDashboardPlatforms,
    setDashboardForecasts,
    setDashboardStages,
    setDashboardAccountGroupings,
    setDashboardAccounts,
    setDashboardOpportunities,
    setDashboardMarketSegments,
    setDashboardTypes,
    setDashboardRequestTypes,
    setDashboardRequestStatuses,
    setDashboardRequestCategories,
    setDashboardRequestSubCategories,
    setDashboardRequesters,
    setDashboardProjectManagers,
    setDashboardPrimaryTeamMembers,
    setDashboardSubjectMatterExperts,
    setDashboardTeamMembers,
    setDashboardDepartments,
    setDashboardWrapUpCodes,
    setDashboardDirectIndirects,
    setDashboardCustomerTypes,
    setDashboardRegionalCareOffers,
    setDashboardListServices,
    dashboardStartDate,
    setDashboardStartDate,
    dashboardEndDate,
    setDashboardEndDate,
    setDashboardDataTickets,
  } = useContext(TeacketContext);

  //#region Use States

  const [initiated, setInitiated] = useState(false);

  const [regions, setRegions] = useState({});
  const [fetchingRegions, setFetchingRegions] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSubRegions, setSelectedSubRegions] = useState([]);

  const [quarters, setQuarters] = useState([]);
  const [fetchingQuarters, setFetchingQuarters] = useState(false);
  const [selectedQuarters, setSelectedQuarters] = useState([]);

  const [selectedACVs, setSelectedACVs] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [fetchingPlatforms, setFetchingPlatforms] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const [forecasts, setForecasts] = useState([]);
  const [fetchingForecasts, setFetchingForecasts] = useState(false);
  const [selectedForecasts, setSelectedForecasts] = useState([]);

  const [stages, setStages] = useState([]);
  const [fetchingStages, setFetchingStages] = useState(false);
  const [selectedStages, setSelectedStages] = useState([]);

  const [accountGroupings, setAccountGroupings] = useState([]);
  const [fetchingAccountGroupings, setFetchingAccountGroupings] = useState(false);
  const [selectedAccountGroupings, setSelectedAccountGroupings] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const [opportunities, setOpportunities] = useState([]);
  const [fetchingOpportunities, setFetchingOpportunities] = useState(false);
  const [selectedOpportunities, setSelectedOpportunities] = useState([]);
  const [opportunitiesPlaceholder, setOpportunitiesPlaceholder] = useState('[All Opportunities]');

  const [marketSegments, setMarketSegments] = useState([]);
  const [fetchingMarketSegments, setFetchingMarketSegments] = useState(false);
  const [selectedMarketSegments, setSelectedMarketSegments] = useState([]);

  const [types, setTypes] = useState([]);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [selectedTicketTypes, setSelectedTicketTypes] = useState([]);

  const [requestStatuses, setRequestStatuses] = useState([]);
  const [fetchingRequestStatuses, setFetchingRequestStatuses] = useState(false);
  const [selectedRequestStatuses, setSelectedRequestStatuses] = useState([]);

  const [requestCategories, setRequestCategories] = useState([]);
  const [fetchingRequestCategories, setFetchingRequestCategories] = useState(false);
  const [selectedRequestCategories, setSelectedRequestCategories] = useState([]);

  const [requestSubCategories, setRequestSubCategories] = useState([]);
  const [fetchingRequestSubCategories, setFetchingRequestSubCategories] = useState(false);
  const [selectedRequestSubCategories, setSelectedRequestSubCategories] = useState([]);

  const [requesters, setRequesters] = useState([]);
  const [fetchingRequesters, setFetchingRequesters] = useState(false);
  const [selectedRequesters, setSelectedRequesters] = useState([]);

  const [projectManagers, setProjectManagers] = useState([]);
  const [fetchingProjectManagers, setFetchingProjectManagers] = useState(false);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);

  const [primaryTeamMembers, setPrimaryTeamMembers] = useState([]);
  const [fetchingPrimaryTeamMembers, setFetchingPrimaryTeamMembers] = useState(false);
  const [selectedPrimaryTeamMembers, setSelectedPrimaryTeamMembers] = useState([]);

  const [subjectMatterExperts, setSubjectMatterExperts] = useState([]);
  const [fetchingSubjectMatterExperts, setFetchingSubjectMatterExperts] = useState(false);
  const [selectedSubjectMatterExperts, setSelectedSubjectMatterExperts] = useState([]);

  const [teamMembers, setTeamMembers] = useState([]);
  const [fetchingTeamMembers, setFetchingTeamMembers] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [fetchingDepartments, setFetchingDepartments] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [wrapUpCodes, setWrapUpCodes] = useState([]);
  const [fetchingWrapUpCodes, setFetchingWrapUpCodes] = useState(false);
  const [selectedWrapUpCodes, setSelectedWrapUpCodes] = useState([]);

  const [directIndirects, setDirectIndirects] = useState([]);
  const [fetchingDirectIndirects, setFetchingDirectIndirects] = useState(false);
  const [selectedDirectIndirects, setSelectedDirectIndirects] = useState([]);

  const [customerTypes, setCustomerTypes] = useState([]);
  const [fetchingCustomerTypes, setFetchingCustomerTypes] = useState(false);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([]);

  const [selectedRegionalCareOffers, setSelectedRegionalCareOffers] = useState([]);

  //#endregion

  const loadPreliminaryData = async () => {
    await Promise.all([
      includeRegions && loadRegions(),
      includeQuarters && loadQuarters(),
      includePlatforms && loadPlatforms(),
      includeForecasts && loadForecasts(),
      includeStages && loadStages(),
      includeTypes && loadTypes(),
      includeRequestStatuses && loadRequestStatuses(),
      includeRequestCategories && loadRequestCategories(),
      includeMarketSegments && loadMarketSegments(),
      includeAccountGroupings && loadAccountGroupings(),
      includeDirectIndirects && loadDirectIndirects(),
      includeCustomerTypes && loadCustomerTypes(),
    ]);
  };

  //#region Use Effects

  useEffect(() => {
    setDefaultRange();
    loadPreliminaryData();
  }, []);

  useEffect(() => {
    if (selectedRequestCategories.length === 0) {
      setRequestSubCategories([]);
      setDashboardRequestSubCategories([]);
      return;
    }

    if (includeRequestSubCategories) {
      loadRequestSubCategories();
    }
  }, [selectedRequestCategories]);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;
    setInitiated(true);
  }, [dashboardStartDate, dashboardEndDate]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setOpportunities([]);
      setSelectedOpportunities([]);
      setDashboardOpportunities([]);
      return;
    }

    if (includeOpportunities) {
      loadOpportunities(selectedAccounts);
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (dashboardListServices?.length > 0) {
      loadDepartments();
      loadWrapUpCodes();
    } else {
      setDepartments([]);
      setWrapUpCodes([]);
    }
  }, [dashboardListServices]);

  //#endregion

  //#region Load Filter Values

  const getSubRegionsOptions = () => {
    // When no regions selected, hide all subregions
    if (!selectedRegions || selectedRegions.length === 0) {
      return [];
    }

    // When specific regions are selected, show only their subregions
    return selectedRegions.map((region) => ({
      label: <span>{region}</span>,
      title: region,
      options: (regions[region] || []).map((subRegion) => ({
        value: subRegion,
        label: subRegion,
      })),
    }));
  };

  const acvs = [
    {
      label: '$100,000+',
      value: 100000,
    },
    {
      label: '$500,000+',
      value: 500000,
    },
  ];

  const regionalCareOffers = ['Australia Business Hours Only', 'EEA Business Hours Only', 'Standard Global', 'USA Business Hours Only'];

  const loadRegions = async () => {
    try {
      setFetchingRegions(true);
      const result = await getDistinctRegions();
      setRegions(result);
    } catch (error) {
      console.error('Error while loading regions:', error);
      message.error('Error while loading regions');
    } finally {
      setFetchingRegions(false);
    }
  };

  const loadQuarters = async () => {
    try {
      setFetchingQuarters(true);
      const result = await getDistinctQuarters();
      setQuarters(result);
    } catch (error) {
      console.error('Error while loading quarters:', error);
      message.error('Error while loading quarters');
    } finally {
      setFetchingQuarters(false);
    }
  };

  const loadPlatforms = async () => {
    try {
      setFetchingPlatforms(true);
      const result = await getDistinctPlatforms();
      setPlatforms(result);
    } catch (error) {
      console.error('Error while loading platforms:', error);
      message.error('Error while loading platforms');
    } finally {
      setFetchingPlatforms(false);
    }
  };

  const loadForecasts = async () => {
    try {
      setFetchingForecasts(true);
      const result = await getDistinctForecasts();
      setForecasts(result);
    } catch (error) {
      console.error('Error while loading forecasts:', error);
      message.error('Error while loading forecasts');
    } finally {
      setFetchingForecasts(false);
    }
  };

  const loadStages = async () => {
    try {
      setFetchingStages(true);
      const result = await getDistinctStages();
      setStages(result);
    } catch (error) {
      console.error('Error while loading stages:', error);
      message.error('Error while loading stages');
    } finally {
      setFetchingStages(false);
    }
  };

  const loadAccountGroupings = async () => {
    try {
      setFetchingAccountGroupings(true);
      const result = await getDistinctAccountGroupings();
      setAccountGroupings(result);
    } catch (error) {
      console.error('Error while loading account groupings:', error);
      message.error('Error while loading account groupings');
    } finally {
      setFetchingAccountGroupings(false);
    }
  };

  const loadOpportunities = async () => {
    try {
      setFetchingOpportunities(true);
      const result = await getDistinctOpportunities(selectedAccounts);
      setOpportunities(result);
      setOpportunitiesPlaceholder('[All Opportunities]');
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading opportunities:', error);
        message.error('Error while loading opportunities');
      } else {
        console.log('No opportunities found for selected accounts');
        setOpportunities([]);
        setOpportunitiesPlaceholder('No opportunities found');
      }
    } finally {
      setFetchingOpportunities(false);
    }
  };

  const loadMarketSegments = async () => {
    try {
      setFetchingMarketSegments(true);
      const result = await getDistinctMarketSegments();
      setMarketSegments(result);
    } catch (error) {
      console.error('Error while loading market segments:', error);
      message.error('Error while loading market segments');
    } finally {
      setFetchingMarketSegments(false);
    }
  };

  const loadTypes = async () => {
    try {
      setFetchingTypes(true);
      const result = await getDistinctTypes();
      setTypes(result);
    } catch (error) {
      console.error('Error while loading types:', error);
      message.error('Error while loading types');
    } finally {
      setFetchingTypes(false);
    }
  };

  const loadRequestStatuses = async () => {
    try {
      setFetchingRequestStatuses(true);
      const result = await getDistinctRequestStatuses();
      setRequestStatuses(result);
    } catch (error) {
      console.error('Error while loading request statuses:', error);
      message.error('Error while loading request statuses');
    } finally {
      setFetchingRequestStatuses(false);
    }
  };

  const loadRequestCategories = async () => {
    try {
      setFetchingRequestCategories(true);
      const result = await getDistinctRequestCategories();

      const groupedCategories = result.reduce((acc, item) => {
        const { service_name, category } = item;
        if (!acc[service_name]) {
          acc[service_name] = [];
        }
        acc[service_name].push({ label: category, value: `${service_name}-${category}` });
        return acc;
      }, {});

      const options = Object.keys(groupedCategories)?.map((service_name) => ({
        label: service_name,
        title: service_name,
        options: groupedCategories[service_name],
      }));

      setRequestCategories(options);
    } catch (error) {
      console.error('Error while loading request categories:', error);
      message.error('Error while loading request categories');
    } finally {
      setFetchingRequestCategories(false);
    }
  };

  const loadRequestSubCategories = async () => {
    try {
      setFetchingRequestSubCategories(true);
      const result = await getDistinctRequestSubCategoriesForCategories(selectedRequestCategories?.map((x) => x.label));
      setRequestSubCategories(result.sort((a, b) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error while loading request subcategories:', error);
      message.error('Error while loading request subcategories');
    } finally {
      setFetchingRequestSubCategories(false);
    }
  };

  const loadDepartments = async () => {
    try {
      setFetchingDepartments(true);
      const result = await getDistinctDepartments(dashboardListServices);
      setDepartments(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading departments:', error);
        message.error('Error while loading departments');
      } else {
        console.log('No departments found');
        setDepartments([]);
      }
    } finally {
      setFetchingDepartments(false);
    }
  };

  const loadWrapUpCodes = async () => {
    try {
      setFetchingWrapUpCodes(true);
      const result = await getDistinctWrapUpCodes(dashboardListServices);
      setWrapUpCodes(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading wrap up codes:', error);
        message.error('Error while loading wrap up codes');
      } else {
        console.log('No wrap up codes found');
        setWrapUpCodes([]);
      }
    } finally {
      setFetchingWrapUpCodes(false);
    }
  };

  const loadDirectIndirects = async () => {
    try {
      setFetchingDirectIndirects(true);
      const result = await getDistinctDirectIndirects();
      setDirectIndirects(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading direct/indirect:', error);
        message.error('Error while loading direct/indirect');
      } else {
        console.log('No direct/indirect found');
        setDirectIndirects([]);
      }
    } finally {
      setFetchingDirectIndirects(false);
    }
  };

  const loadCustomerTypes = async () => {
    try {
      setFetchingCustomerTypes(true);
      const result = await getDistinctCustomerTypes();
      setCustomerTypes(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading customer types:', error);
        message.error('Error while loading customer types');
      } else {
        console.log('No customer types found');
        setCustomerTypes([]);
      }
    } finally {
      setFetchingCustomerTypes(false);
    }
  };

  //#region Search

  const searchAccounts = async (query) => {
    try {
      setAccountList([]);

      if (query.length < 3) return;

      setFetchingAccounts(true);
      const result = await getAccountsByName(query);
      console.log('searchAccounts:', result);
      setAccountList(result);
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading accounts:', error);
      message.error('Error while loading accounts');
    } finally {
      setFetchingAccounts(false);
    }
  };

  const searchRequesters = async (query) => {
    try {
      setRequesters([]);

      if (query.length < 3) return;

      setFetchingRequesters(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];
        for (let index = 0; index < dashboardListServices.length; index++) {
          const service = dashboardListServices[index];
          const result = await getServiceUsers(service, query);
          allUsers.push(...result);
        }
        setRequesters(allUsers);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading requesters:', error);
      message.error('Error while loading requesters');
    } finally {
      setFetchingRequesters(false);
    }
  };

  const searchProjectManagers = async (query) => {
    try {
      setProjectManagers([]);

      if (query.length < 3) return;

      setFetchingProjectManagers(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];
        for (let index = 0; index < dashboardListServices.length; index++) {
          const service = dashboardListServices[index];
          const result = await getServiceUsers(service, query);
          allUsers.push(...result);
        }
        setProjectManagers(allUsers);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading project managers:', error);
      message.error('Error while loading project managers');
    } finally {
      setFetchingProjectManagers(false);
    }
  };

  const searchPrimaryTeamMembers = async (query) => {
    try {
      setPrimaryTeamMembers([]);

      if (query.length < 3) return;

      setFetchingPrimaryTeamMembers(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];
        for (let index = 0; index < dashboardListServices.length; index++) {
          const service = dashboardListServices[index];
          const result = await getServiceUsers(service, query);
          allUsers.push(...result);
        }
        setPrimaryTeamMembers(allUsers);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading primary team members:', error);
      message.error('Error while loading primary team members');
    } finally {
      setFetchingPrimaryTeamMembers(false);
    }
  };

  const searchSubjectMatterExperts = async (query) => {
    try {
      setSubjectMatterExperts([]);

      if (query.length < 3) return;

      setFetchingSubjectMatterExperts(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];
        for (let index = 0; index < dashboardListServices.length; index++) {
          const service = dashboardListServices[index];
          const result = await getServiceUsers(service, query);
          allUsers.push(...result);
        }
        setSubjectMatterExperts(allUsers);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading subject matter experts:', error);
      message.error('Error while loading subject matter experts');
    } finally {
      setFetchingSubjectMatterExperts(false);
    }
  };

  const searchTeamMembers = async (query) => {
    try {
      setTeamMembers([]);

      if (query.length < 3) return;

      setFetchingTeamMembers(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];
        for (let index = 0; index < dashboardListServices.length; index++) {
          const service = dashboardListServices[index];
          const result = await getServiceUsers(service, query);
          allUsers.push(...result);
        }
        setTeamMembers(allUsers);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading team members:', error);
      message.error('Error while loading team members');
    } finally {
      setFetchingTeamMembers(false);
    }
  };

  //#endregion

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    const start = dayjs().tz(timezone).subtract(6, 'days').startOf('day');
    const end = dayjs().tz(timezone).endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  //#endregion

  const handleOnSubmit = async () => {
    console.log('handleOnSubmit()');

    // console.log('typeof onSubmit:', typeof onSubmit);
    if (onSubmit) {
      onSubmit();
      return;
    }

    await executeAsyncProcess(async () => {
      try {
        const result = await getDashboardsTickets(
          dayjs(dashboardStartDate).format(),
          dayjs(dashboardEndDate).format(),
          notEmptyArray(dashboardListServices) ? dashboardListServices : null,
          notEmptyArray(dashboardRegions) ? dashboardRegions : null,
          selectedSubRegions || null,
          selectedQuarters || null,
          selectedACVs || null,
          selectedPlatforms || null,
          selectedForecasts || null,
          selectedStages || null,
          selectedTypes || null,
          selectedTicketTypes || null,
          selectedRequestStatuses || null,
          selectedRequestCategories || null,
          selectedRequestSubCategories || null,
          selectedMarketSegments || null,
          selectedAccountGroupings || null,
          selectedAccounts || null,
          selectedOpportunities || null,
          selectedRequesters || null,
          selectedProjectManagers || null,
          selectedPrimaryTeamMembers || null,
          selectedSubjectMatterExperts || null,
          selectedTeamMembers || null,
          selectedDepartments || null,
          selectedWrapUpCodes || null,
          selectedDirectIndirects || null,
          selectedCustomerTypes || null,
          selectedRegionalCareOffers || null
        );
        setDashboardDataTickets(result);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      <div>
        <RangePicker
          startDate={dashboardStartDate}
          endDate={dashboardEndDate}
          onChange={(v) => {
            setDashboardStartDate(v.startDate);
            setDashboardEndDate(v.endDate);
          }}
        />
      </div>
      {includeServices && (
        <div>
          <Select
            mode='multiple'
            value={dashboardListServices}
            loading={!servicesForTenant}
            onChange={(v) => setDashboardListServices(v)}
            className='w-60'
            options={servicesForTenant
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => ({
                value: x.id,
                label: x.name,
              }))}
            allowClear
            placeholder='[All Services]'
          />
        </div>
      )}
      {includeRegions && (
        <div>
          <Select
            mode='multiple'
            value={selectedRegions}
            loading={fetchingRegions}
            onChange={(v) => {
              setSelectedRegions(v);
              setDashboardRegions(v);

              // If no regions are selected, clear subregions
              if (v.length === 0) {
                setSelectedSubRegions([]);
                setDashboardSubRegions([]);
              }
            }}
            className='w-60'
            options={Object.keys(regions)?.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingRegions ? <Spin size='small' /> : null}
            placeholder='[All Regions]'
          />
        </div>
      )}
      {includeSubRegions && (
        <div>
          <Select
            mode='multiple'
            value={selectedSubRegions}
            loading={fetchingRegions}
            onChange={(v) => {
              setSelectedSubRegions(v);
              setDashboardSubRegions(v);
            }}
            className='w-60'
            options={getSubRegionsOptions()}
            allowClear
            placeholder='[All Subregions]'
            disabled={includeRegions && selectedRegions.length === 0}
          />
        </div>
      )}
      {includePlatforms && (
        <div>
          <Select
            mode='multiple'
            value={selectedPlatforms}
            loading={fetchingPlatforms}
            onChange={(v) => {
              setSelectedPlatforms(v);
              setDashboardPlatforms(v);
            }}
            className='w-60'
            options={platforms.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingPlatforms ? <Spin size='small' /> : null}
            placeholder='[All Platforms]'
          />
        </div>
      )}
      {includeDirectIndirects && (
        <div>
          <Select
            mode='multiple'
            value={selectedDirectIndirects}
            loading={fetchingDirectIndirects}
            onChange={(v) => {
              setSelectedDirectIndirects(v);
              setDashboardDirectIndirects(v);
            }}
            className='w-60'
            options={directIndirects.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingDirectIndirects ? <Spin size='small' /> : null}
            placeholder='[Direct/Indirect]'
          />
        </div>
      )}
      {includeCustomerTypes && (
        <div>
          <Select
            mode='multiple'
            value={selectedCustomerTypes}
            loading={fetchingCustomerTypes}
            onChange={(v) => {
              setSelectedCustomerTypes(v);
              setDashboardCustomerTypes(v);
            }}
            className='w-60'
            options={customerTypes.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingCustomerTypes ? <Spin size='small' /> : null}
            placeholder='[All Customer Types]'
          />
        </div>
      )}
      {includeQuarters && (
        <div>
          <Select
            mode='multiple'
            value={selectedQuarters}
            loading={fetchingQuarters}
            onChange={(v) => {
              setSelectedQuarters(v);
              setDashboardQuarters(v);
            }}
            className='w-60'
            options={quarters.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingQuarters ? <Spin size='small' /> : null}
            placeholder='[All Quarters]'
          />
        </div>
      )}
      {includeACVs && (
        <div>
          <Select
            mode='multiple'
            value={selectedACVs}
            onChange={(v) => {
              setSelectedACVs(v);
              setDashboardACVs(v);
            }}
            className='w-60'
            options={acvs.map((x) => ({
              value: x.value,
              label: x.label,
            }))}
            allowClear
            placeholder='[All ACVs]'
          />
        </div>
      )}
      {includeForecasts && (
        <div>
          <Select
            mode='multiple'
            value={selectedForecasts}
            loading={fetchingForecasts}
            onChange={(v) => {
              setSelectedForecasts(v);
              setDashboardForecasts(v);
            }}
            className='w-60'
            options={forecasts.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingForecasts ? <Spin size='small' /> : null}
            placeholder='[All Forecasts]'
          />
        </div>
      )}
      {includeStages && (
        <div>
          <Select
            mode='multiple'
            value={selectedStages}
            loading={fetchingStages}
            onChange={(v) => {
              setSelectedStages(v);
              setDashboardStages(v);
            }}
            className='w-60'
            options={stages.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingStages ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Stages]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
          />
        </div>
      )}
      {includeMarketSegments && (
        <div>
          <Select
            mode='multiple'
            value={selectedMarketSegments}
            loading={fetchingMarketSegments}
            onChange={(v) => {
              setSelectedMarketSegments(v);
              setDashboardMarketSegments(v);
            }}
            className='w-60'
            options={marketSegments.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingMarketSegments ? <Spin size='small' /> : null}
            placeholder='[All Market Segments]'
          />
        </div>
      )}
      {includeAccountGroupings && (
        <div>
          <Select
            mode='multiple'
            value={selectedAccountGroupings}
            loading={fetchingAccountGroupings}
            onChange={(v) => {
              setSelectedAccountGroupings(v);
              setDashboardAccountGroupings(v);
            }}
            className='w-60'
            options={accountGroupings.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingAccountGroupings ? <Spin size='small' /> : null}
            placeholder='[All Account Groupings]'
          />
        </div>
      )}
      {includeAccounts && (
        <div>
          <Select
            mode='multiple'
            value={selectedAccounts}
            loading={fetchingAccounts}
            onChange={(v) => {
              setSelectedAccounts(v);
              setDashboardAccounts(v);
            }}
            className='w-60'
            style={{
              width: 500,
            }}
            allowClear
            notFoundContent={fetchingAccounts ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={'[All Accounts]'}
            showSearch
            onSearch={searchAccounts}
            optionRender={(option) => {
              const item = accountList.find((x) => x.ACCOUNT_ID === option.key);
              if (!item) return null;

              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{item.ACCOUNT_NAME}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <FiHash />
                        {item.ACCOUNT_ID}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <PiIdentificationBadgeFill />
                        {item.OWNER_NAME || 'N/A'}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <TbWorldPin />
                        {item.ACCOUNT_REGION || 'N/A'}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {accountList.map((account) => (
              <Option key={account.ACCOUNT_ID} autoComplete='off'>
                {account.ACCOUNT_NAME} ({account.ACCOUNT_ID})
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includeOpportunities && (
        <div>
          <Select
            mode='multiple'
            value={selectedOpportunities}
            loading={fetchingOpportunities}
            disabled={selectedAccounts.length === 0 || opportunities.length === 0}
            onChange={(v) => {
              setSelectedOpportunities(v);
              setDashboardOpportunities(v);
            }}
            className='w-60'
            style={{
              width: 500,
            }}
            options={opportunities.map((x) => ({
              value: x.id,
              label: `${x.name} (${x.id})`,
            }))}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            notFoundContent={fetchingOpportunities ? <Spin size='small' /> : null}
            placeholder={opportunitiesPlaceholder || 'Select an account first'}
          />
        </div>
      )}
      {includeTypes && (
        <div>
          <Select
            mode='multiple'
            value={selectedTypes}
            loading={fetchingTypes}
            onChange={(v) => {
              setSelectedTypes(v);
              setDashboardTypes(v);
            }}
            className='w-60'
            options={types.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingTypes ? <Spin size='small' /> : null}
            placeholder='[All Types]'
          />
        </div>
      )}
      {includeTicketTypes && (
        <div>
          <TicketTypePicker
            disabled={!dashboardListServices?.length}
            mode='multiple'
            width='200px'
            placeholder={dashboardListServices?.length ? '[All Ticket Types]' : 'Select a service first'}
            serviceIds={dashboardListServices}
            onChange={(v) => {
              setSelectedTicketTypes(v);
              setDashboardRequestTypes(v);
            }}
          />
        </div>
      )}
      {includeRequestCategories && (
        <>
          <div>
            <Select
              mode='multiple'
              value={selectedRequestCategories}
              loading={fetchingRequestCategories}
              onChange={(v, selectedOptions) => {
                setSelectedRequestCategories(selectedOptions);
                setDashboardRequestCategories(selectedOptions);
              }}
              className='w-60'
              options={requestCategories}
              allowClear
              notFoundContent={fetchingRequestCategories ? <Spin size='small' /> : null}
              placeholder='[All Ticket Categories]'
            />
          </div>
          <div>
            <Select
              mode='multiple'
              disabled={selectedRequestCategories.length === 0}
              value={selectedRequestSubCategories}
              loading={fetchingRequestSubCategories}
              onChange={(v) => {
                setSelectedRequestSubCategories(v);
                setDashboardRequestSubCategories(v);
              }}
              className='w-60'
              options={requestSubCategories?.map((x) => ({
                value: x,
                label: x,
              }))}
              allowClear
              notFoundContent={fetchingRequestSubCategories ? <Spin size='small' /> : null}
              placeholder='[All Ticket Subcategories]'
            />
          </div>
        </>
      )}
      {includeRequestStatuses && (
        <div>
          <Select
            mode='multiple'
            value={selectedRequestStatuses}
            loading={fetchingRequestStatuses}
            onChange={(v) => {
              setSelectedRequestStatuses(v);
              setDashboardRequestStatuses(v);
            }}
            className='w-60'
            options={requestStatuses?.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingRequestStatuses ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Statuses]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
          />
        </div>
      )}
      {includeRequesters && (
        <div>
          <Select
            mode='multiple'
            value={selectedRequesters}
            loading={fetchingRequesters}
            onChange={(v) => {
              setSelectedRequesters(v);
              setDashboardRequesters(v);
            }}
            className='w-60'
            allowClear
            notFoundContent={fetchingRequesters ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Requesters]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
            onSearch={searchRequesters}
            optionRender={(option) => {
              const user = requesters.find((x) => x.user_id === option.key);
              // if (!user && value) return value;
              if (!user) return null;
              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div>
                    <UserImage image={user.user_pic} size={32} />
                  </div>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{user.user_name}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <MdOutlineEmail />
                        {user.user_email}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <LuBuilding />
                        {user.tenant_name}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {requesters.map((user) => (
              <Option key={user.user_id} autoComplete='off'>
                {user.user_name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includeProjectManagers && (
        <div>
          <Select
            mode='multiple'
            value={selectedProjectManagers}
            loading={fetchingProjectManagers}
            onChange={(v) => {
              setSelectedProjectManagers(v);
              setDashboardProjectManagers(v);
            }}
            className='w-60'
            allowClear
            notFoundContent={fetchingProjectManagers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Project Managers]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
            onSearch={searchProjectManagers}
            optionRender={(option) => {
              const user = projectManagers.find((x) => x.user_id === option.key);
              // if (!user && value) return value;
              if (!user) return null;
              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div>
                    <UserImage image={user.user_pic} size={32} />
                  </div>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{user.user_name}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <MdOutlineEmail />
                        {user.user_email}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <LuBuilding />
                        {user.tenant_name}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {projectManagers.map((user) => (
              <Option key={user.user_id} autoComplete='off'>
                {user.user_name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includePrimaryTeamMembers && (
        <div>
          <Select
            mode='multiple'
            value={selectedPrimaryTeamMembers}
            loading={fetchingPrimaryTeamMembers}
            onChange={(v) => {
              setSelectedPrimaryTeamMembers(v);
              setDashboardPrimaryTeamMembers(v);
            }}
            className='w-60'
            allowClear
            notFoundContent={fetchingPrimaryTeamMembers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Primary Team Members]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
            onSearch={searchPrimaryTeamMembers}
            optionRender={(option) => {
              const user = primaryTeamMembers.find((x) => x.user_id === option.key);
              // if (!user && value) return value;
              if (!user) return null;
              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div>
                    <UserImage image={user.user_pic} size={32} />
                  </div>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{user.user_name}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <MdOutlineEmail />
                        {user.user_email}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <LuBuilding />
                        {user.tenant_name}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {primaryTeamMembers.map((user) => (
              <Option key={user.user_id} autoComplete='off'>
                {user.user_name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includeSubjectMatterExperts && (
        <div>
          <Select
            mode='multiple'
            value={selectedSubjectMatterExperts}
            loading={fetchingSubjectMatterExperts}
            onChange={(v) => {
              setSelectedSubjectMatterExperts(v);
              setDashboardSubjectMatterExperts(v);
            }}
            className='w-60'
            allowClear
            notFoundContent={fetchingSubjectMatterExperts ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Subject Matter Experts]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
            onSearch={searchSubjectMatterExperts}
            optionRender={(option) => {
              const user = subjectMatterExperts.find((x) => x.user_id === option.key);
              // if (!user && value) return value;
              if (!user) return null;
              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div>
                    <UserImage image={user.user_pic} size={32} />
                  </div>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{user.user_name}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <MdOutlineEmail />
                        {user.user_email}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <LuBuilding />
                        {user.tenant_name}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {subjectMatterExperts.map((user) => (
              <Option key={user.user_id} autoComplete='off'>
                {user.user_name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includeTeamMembers && (
        <div>
          <Select
            mode='multiple'
            value={selectedTeamMembers}
            loading={fetchingTeamMembers}
            onChange={(v) => {
              setSelectedTeamMembers(v);
              setDashboardTeamMembers(v);
            }}
            className='w-60'
            allowClear
            notFoundContent={fetchingTeamMembers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Team Members]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
            onSearch={searchTeamMembers}
            optionRender={(option) => {
              const user = teamMembers.find((x) => x.user_id === option.key);
              // if (!user && value) return value;
              if (!user) return null;
              return (
                <div className='flex flex-row gap-2 my-1'>
                  <div>
                    <UserImage image={user.user_pic} size={32} />
                  </div>
                  <div className='flex flex-col'>
                    <div className='overflow-hidden'>{user.user_name}</div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <MdOutlineEmail />
                        {user.user_email}
                      </Space>
                    </div>
                    <div className='text-xs font-light opacity-60 overflow-hidden'>
                      <Space className='btn'>
                        <LuBuilding />
                        {user.tenant_name}
                      </Space>
                    </div>
                  </div>
                </div>
              );
            }}
            filterOption={false}>
            {teamMembers.map((user) => (
              <Option key={user.user_id} autoComplete='off'>
                {user.user_name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {includeDepartments && (
        <div>
          <Select
            mode='multiple'
            value={selectedDepartments}
            loading={fetchingDepartments}
            onChange={(v) => {
              setSelectedDepartments(v);
              setDashboardDepartments(v);
            }}
            className='w-60'
            allowClear
            options={departments?.map((x) => ({
              value: x,
              label: x,
            }))}
            notFoundContent={fetchingDepartments ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Departments]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
          />
        </div>
      )}
      {includeWrapUpCodes && (
        <div>
          <Select
            mode='multiple'
            value={selectedWrapUpCodes}
            loading={fetchingWrapUpCodes}
            onChange={(v) => {
              setSelectedWrapUpCodes(v);
              setDashboardWrapUpCodes(v);
            }}
            className='w-60'
            allowClear
            options={wrapUpCodes?.map((x) => ({
              value: x,
              label: x,
            }))}
            notFoundContent={fetchingWrapUpCodes ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
            placeholder={dashboardListServices?.length ? '[All Wrap Up Codes]' : 'Select a service first'}
            disabled={!dashboardListServices?.length}
            showSearch
          />
        </div>
      )}
      {includeRegionalCareOffers && (
        <div>
          <Select
            mode='multiple'
            value={selectedRegionalCareOffers}
            onChange={(v) => {
              setSelectedRegionalCareOffers(v);
              setDashboardRegionalCareOffers(v);
            }}
            className='w-60'
            allowClear
            options={regionalCareOffers?.map((x) => ({
              value: x,
              label: x,
            }))}
            placeholder={'[All Regional Care Offers]'}
            showSearch
          />
        </div>
      )}
      <div>
        <Button onClick={handleOnSubmit}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {
  onSubmit: PropTypes.func,
  includeServices: PropTypes.bool,
  includeRegions: PropTypes.bool,
  includeSubRegions: PropTypes.bool,
  includeQuarters: PropTypes.bool,
  includeACVs: PropTypes.bool,
  includePlatforms: PropTypes.bool,
  includeForecasts: PropTypes.bool,
  includeStages: PropTypes.bool,
  includeTypes: PropTypes.bool,
  includeTicketTypes: PropTypes.bool,
  includeRequestStatuses: PropTypes.bool,
  includeRequestCategories: PropTypes.bool,
  includeRequestSubCategories: PropTypes.bool,
  includeMarketSegments: PropTypes.bool,
  includeAccountGroupings: PropTypes.bool,
  includeAccounts: PropTypes.bool,
  includeOpportunities: PropTypes.bool,
  includeRequesters: PropTypes.bool,
  includeProjectManagers: PropTypes.bool,
  includePrimaryTeamMembers: PropTypes.bool,
  includeSubjectMatterExperts: PropTypes.bool,
  includeTeamMembers: PropTypes.bool,
  includeDepartments: PropTypes.bool,
  includeWrapUpCodes: PropTypes.bool,
  includeDirectIndirects: PropTypes.bool,
  includeCustomerTypes: PropTypes.bool,
  includeRegionalCareOffers: PropTypes.bool,
};

export default DashboardFilters;
