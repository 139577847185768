//#region Imports

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import MuiTheme from 'src/misc/MuiTheme';
import { ResponsiveChartContainer, LinePlot, ChartsXAxis, ChartsYAxis, ChartsLegend, ChartsTooltip, ChartsAxisHighlight, MarkPlot, LineHighlightPlot, BarPlot } from '@mui/x-charts';
import NoData from 'src/components/layout/NoData';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';
import { IconButton, Dialog, DialogContent } from '@mui/material';
import { AiOutlineFullscreen, AiOutlineClose } from 'react-icons/ai';

//#endregion

const BySubCategoryChart = () => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  //#region Use States

  const [data, setData] = useState(null);
  const [hiddenSeries, setHiddenSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //#endregion

  //#region Use Effects

  useEffect(() => {
    const { bySubCategoryChart } = dashboardDataTickets ?? {};
    console.log('bySubCategoryChart', bySubCategoryChart);
    if (!bySubCategoryChart) return;

    const subCategories = bySubCategoryChart.map((item) => ({
      id: item.subcategory || 'Unknown',
      label: item.subcategory || 'Unknown',
      value: item.count ?? 0,
    }));

    const hours = bySubCategoryChart.map((item) => ({
      id: item.subcategory || 'Unknown',
      label: item.subcategory || 'Unknown',
      value: item.hours,
    }));

    setData({
      subCategories,
      hours,
    });
  }, [dashboardDataTickets]);

  //#endregion

  //#region No Data Rendering

  if (!data) {
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );
  }

  //#endregion

  //#region Chart Data & Options

  const seriesDefinitions = [
    {
      label: 'Subcategories',
      getData: () => data?.subCategories.map((x) => x.value),
      type: 'bar', // 'line' by default. Make sure you add a <BarPlot /> component to the chart content if you set it to 'bar'
      opacity: 0.8, // Optional. A number between 0 and 1. Default is 1.
    },
    {
      label: 'Hours',
      getData: () => data?.hours.map((x) => x.value),
      yAxisId: 'hours', // Optional. Default is 'y-axis'. Use it if you want a secondary y-axis.
    },
  ];

  const chartColors = [isDarkMode ? colors.open.light : colors.open.dark, isDarkMode ? colors.closed.light : colors.closed.dark, isDarkMode ? colors.remaining.light : colors.remaining.dark];

  const series = seriesDefinitions.map((def) => ({
    type: def.type || 'line',
    data: hiddenSeries.includes(def.label) ? [] : def.getData(),
    label: def.label,
    xAxisKey: 'x-axis',
    yAxisKey: 'y-axis',
    valueFormatter: (value) => value || 'N/A',
    showMark: true,
    yAxisId: def.yAxisId || 'y-axis',
    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    highlightScope: { fade: 'global', highlight: 'item' },
    ...(def.type === 'bar' && {
      color: `${chartColors[seriesDefinitions.findIndex((d) => d.label === def.label)]}${def.opacity ? Math.max(def.opacity * 10, 1) + 'A' : ''}`,
    }),
  }));

  //#endregion

  //#region Chart Content

  const ChartContent = ({ height = 400 }) => (
    <ResponsiveChartContainer
      skipAnimation={true}
      colors={chartColors}
      margin={{ top: 50, right: 30, bottom: 120, left: 30 }}
      xAxis={[
        {
          id: 'x-axis',
          scaleType: 'band',
          data: data?.subCategories?.map((x) => x.label),
          tickLabelStyle: {
            angle: -45,
            textAnchor: 'end',
          },
        },
      ]}
      yAxis={[
        {
          id: 'y-axis',
          tickMinStep: 1,
        },
        {
          id: 'hours',
          tickMinStep: 1,
        },
      ]}
      rightAxis='hours'
      series={series}
      height={height}>
      <ChartsLegend position={{ vertical: 'top', horizontal: 'middle' }} onItemClick={handleLegendClick} />
      <LinePlot showMark={true} />
      <BarPlot borderRadius={10} />
      <ChartsXAxis />
      <ChartsYAxis />
      <ChartsYAxis position='right' axisId={'hours'} />
      <MarkPlot />
      <LineHighlightPlot />
      <ChartsAxisHighlight x='line' />
      <ChartsTooltip trigger='axis' />
    </ResponsiveChartContainer>
  );

  ChartContent.propTypes = {
    height: PropTypes.number,
  };

  //#endregion

  //#region Handle Clicks

  const handleLegendClick = (event, itemId) => {
    setHiddenSeries((prev) => (prev.includes(itemId.label) ? prev.filter((item) => item !== itemId.label) : [...prev, itemId.label]));
  };

  const handleFullscreenClick = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  //#endregion

  return (
    <MuiTheme>
      {/* Fullscreen Button */}
      <div style={{ position: 'relative' }}>
        <IconButton
          onClick={handleFullscreenClick}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
          }}>
          <AiOutlineFullscreen className='text-genesys-gray-500' />
        </IconButton>
        <ChartContent />
      </div>

      {/* Fullscreen Modal */}
      <Dialog fullWidth maxWidth='xl' open={isModalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}>
            <AiOutlineClose />
          </IconButton>
          <ChartContent height={700} />
        </DialogContent>
      </Dialog>
    </MuiTheme>
  );
};

export default BySubCategoryChart;
