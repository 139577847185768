import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { Alert, Button, Space, Tag, message } from 'antd';
import RolePicker from 'src/components/controls/RolePicker/RolePicker';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { FaCircleMinus } from 'react-icons/fa6';
import { postRoleAssignment, getRoleAssignments, deleteRoleAssignment } from 'src/api/accesscontrol';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';

const RolesEditor = ({ objectType, id }) => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Role',
      key: 'role',
      render: (record) => (
        <>
          {record.role} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
        </>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <div dangerouslySetInnerHTML={{ __html: record.role_description }}></div>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Unassign'
            body={<>Do you want to unassign this role?</>}
            onYesClick={() => {
              handlePernamentlyDelete(record.assignment_id);
            }}
            iconYes={<MdDelete />}
            showRed={true}
            labelYes='Yes, delete assignment'
            labelNo='Cancel'>
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaCircleMinus />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  const [pageSize] = useState(10);
  const [roleAssignments, setRoleAssignments] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [resetSelection, setResetSelection] = useState(false);

  useEffect(() => {
    if (resetSelection) setResetSelection(false);
  }, [resetSelection]);

  useEffect(() => {
    setRoleAssignments(null);
    if (objectType && id) loadData();
  }, [id]);

  const getLoadDataQuery = () => {
    switch (objectType) {
      case 'tenant':
        return { tenant_id: id };
      case 'user':
        return { user_id: id };
      default:
        throw new Error('Unknown objectType: ' + objectType);
    }
  };

  const getPostRoleAssignmentQuery = () => {
    switch (objectType) {
      case 'tenant':
        return { tenant_id: id, object_type: objectType, role: selectedValue?.role };
      case 'user':
        return { user_id: id, object_type: objectType, role: selectedValue?.role };
      default:
        throw new Error('Unknown objectType: ' + objectType);
    }
  };

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getRoleAssignments(getLoadDataQuery());
        console.log('resp', resp);
        setRoleAssignments(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleRoleOnPick = (v) => {
    console.log('handleRoleOnPick:', v);
    setSelectedValue(v);
  };

  const handleBtnAssing = () => {
    console.log('handleBtnAssing');
    executeAsyncProcess(async () => {
      try {
        const resp = await postRoleAssignment(getPostRoleAssignmentQuery());
        console.log('postRoleAssignment:', resp);
        const ra = [...roleAssignments];
        ra.unshift({ ...resp, role_description: selectedValue.description, justCreated: true });
        setRoleAssignments(ra);
        setResetSelection(true);
        setSelectedValue(null);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Role assigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = (assignmentId) => {
    console.log('handlePernamentlyDelete');
    executeAsyncProcess(async () => {
      try {
        await deleteRoleAssignment(assignmentId);
        const ra = roleAssignments.filter((x) => x.assignment_id !== assignmentId);
        setRoleAssignments(ra);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Role unassigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <div className='h-full max-h-ful w-full overflow-auto flex flex-col gap-2'>
      {contextHolder}
      <div className='px-2 h-14 flex-initial flex flex-row gap-2 overflow-hidden items-center' style={{ backgroundColor: theme.backgroundLight }}>
        {!resetSelection && <RolePicker onPick={handleRoleOnPick} />}
        <Button type='primary' disabled={!selectedValue || roleAssignments?.some((x) => x.role === selectedValue.role)} onClick={handleBtnAssing}>
          Assign
        </Button>
      </div>
      <Alert message='Please inform users that they must wait 5 minutes or log out and log back in for changes to take effect.' showIcon className='py-1 px-2' />
      <div className='flex-auto overflow-auto' style={{ marginTop: '6px', paddingBottom: '70px' }}>
        <CondensedTableLegacy style={{ marginTop: '1px' }} pagination={{ pageSize }} size='small' columns={columns} dataSource={roleAssignments} rowKey={'role'} />
      </div>
    </div>
  );
};

RolesEditor.propTypes = {
  objectType: PropTypes.string.isRequired, // tenant, user
  id: PropTypes.string.isRequired,
};

export default RolesEditor;
