import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Spin } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { getTicketGroupCounts, getTickets } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTable';
import Space2 from 'src/components/layout/Space2';
import useTheme from 'src/hooks/useTheme';

const TicketListWithGrouping = (props) => {
  const { theme } = useTheme();

  const { groupBy, dataSource, columns } = props;
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupedData, setGroupedData] = useState({});
  const [pageSize] = useState(parseInt(25));
  const [groupDataSource, setGroupDataSource] = useState({});
  const [loadingGroup, setLoadingGroup] = useState();
  const [groupCounts, setGroupCounts] = useState({});
  const [loadingCounts, setLoadingCounts] = useState(false); // Add this line

  const { ticketListUsers, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListQuery, ticketListStartDate, ticketListEndDate, ticketListCurrentPage, ticketListTypes } = useContext(TeacketContext);

  // Collapse all groups by default & load counts
  useEffect(() => {
    // Group the data by the specified group key
    const grouped = groupBy
      ? dataSource.reduce((acc, item) => {
          const groupKey = item[groupBy] || 'Ungrouped';
          if (!acc[groupKey]) {
            acc[groupKey] = [];
          }
          acc[groupKey].push(item);
          return acc;
        }, {})
      : { All: dataSource };

    setGroupedData(grouped);

    if (Object.keys(grouped).length > 1) {
      const initialCollapsedGroups = Object.keys(grouped).reduce((acc, group) => {
        acc[group] = true;
        return acc;
      }, {});
      setCollapsedGroups(initialCollapsedGroups);
    }

    // Fetch group counts
    const fetchGroupCounts = async () => {
      if (!groupBy) {
        setGroupCounts({});
        return;
      }

      try {
        setLoadingCounts(true); // Add this line
        const counts = await getTicketGroupCounts(ticketListCurrentPage, pageSize, ticketListQuery, ticketListPriorities, ticketListStatuses, ticketListUsers, ticketListStartDate, ticketListEndDate, groupBy);
        // Transform array of counts into an object with group as key
        const countsObj = counts.reduce((acc, item) => {
          acc[item[groupBy]] = item._count._all;
          return acc;
        }, {});
        setGroupCounts(countsObj);
      } catch (error) {
        console.error('Failed to fetch group counts:', error);
      } finally {
        setLoadingCounts(false); // Add this line
      }
    };

    fetchGroupCounts();
  }, [groupBy, dataSource]);

  // Sort the grouped items alphabetically
  const sortedGroupedData = Object.keys(groupedData)
    .sort()
    .reduce((acc, key) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});

  const toggleGroupCollapse = async (groupName) => {
    console.log(`toggleGroupCollapse: groupBy: ${groupBy} = ${groupName}`);
    console.log('toggleGroupCollapse: collapsedGroups:', collapsedGroups);

    // If collapsed, the group is getting expanded, load the group data for that group
    if (collapsedGroups[groupName]) {
      setLoadingGroup(groupName);
      const resp = await getTickets(ticketListCurrentPage, pageSize, ticketListQuery, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListUsers, ticketListStartDate, ticketListEndDate, ticketListTypes, groupBy, groupName);
      console.log('tickets for group:', resp);
      setGroupDataSource((prev) => ({
        ...prev,
        [groupName]: resp.entities,
      }));
      setLoadingGroup();
    }

    setCollapsedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <>
      {Object.keys(sortedGroupedData).map((group, index) => (
        <div style={{ backgroundColor: theme.backgroundBase }} key={index}>
          {group !== 'All' && (
            <div key={group} className='py-1 px-2 text-sm cursor-pointer hover:bg-genesys-navy-200 dark:hover:bg-genesys-navy-950' style={{ borderTop: `1px solid ${theme.borderLight}`, borderBottom: !collapsedGroups[group] ? `1px solid ${theme.borderLight}` : null }} onClick={() => toggleGroupCollapse(group)}>
              <>
                <Space2>
                  <div className='w-[18px]'>{Object.keys(sortedGroupedData).length > 1 && <>{loadingGroup === group ? <Spin size='small' /> : collapsedGroups[group] ? <RightOutlined className='opacity-40 text-xs' /> : <DownOutlined className='opacity-40 text-xs' />}</>}</div>
                  <div className='font-bold'>{group}</div>
                  <div className='font-light text-xs opa'>{loadingCounts ? <Spin size='small' /> : `${groupCounts[group] || 0}`}</div>
                </Space2>
              </>
            </div>
          )}
          {!collapsedGroups[group] && (
            <div className={group !== 'All' ? 'p-4' : null}>
              <CondensedTable {...props} name='TicketList' dataSource={groupDataSource[group] || sortedGroupedData[group]} columns={columns} />
            </div>
          )}
        </div>
      ))}
      <div style={{ borderTop: `1px solid ${theme.borderLight}` }}></div>
    </>
  );
};

TicketListWithGrouping.propTypes = {
  noDataMessage: PropTypes.string,
  groupBy: PropTypes.string,
  dataSource: PropTypes.array,
  columns: PropTypes.array.isRequired,
};

export default TicketListWithGrouping;
