import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { Space, Tooltip, message, Button } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { pageList } from '../Misc/misc';
import PageSwitch from 'src/components/layout/PageSwitch';
import { getMyTickets } from 'src/api/teacket';
import Items from './Components/Items';
import ItemDetails from './Components/ItemDetails';
import Filters from './Components/Filters';
import Pagination from './Components/Pagination';
import { MdOutlineExitToApp, MdContentCopy } from 'react-icons/md';
import { CloudDownloadOutlined } from '@ant-design/icons';
import useTheme from 'src/hooks/useTheme';
import { ReloadOutlined } from '@ant-design/icons';
import BarLoader from 'react-bar-loader';
//import PropTypes from 'prop-types';
import SubscribeForLiveEvents from '../Components/TicketSubscribeForNotifiactions';
import { uuid } from 'short-uuid';
import TicketShare from '../Components/TicketShare';
import TicketTypeTag from '../Components/TicketTypeTag';

var myTicketsCurrentPage1 = 1;

const MyTickets = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { myTicketsQuery, setMyTicketsCurrentPage, myTicketsPriorities, myTicketsStatuses, currentTicket, currentTicketMembers, setMyTicketsTotal, setMyTickets, myTickets, myTicketsPageSize, myTicketsCurrentPage, myTicketsLayoutItemsSelector, ticketAfterUpdateSync, setTicketAfterUpdateSync } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  // const navigate = useNavigate();

  const [forceRerender, setForceRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvDownloading, setCsvDownloading] = useState(false);

  //#region "USE EFFECTs"

  useEffect(() => {
    myTicketsCurrentPage1 = myTicketsCurrentPage;
  }, [myTicketsCurrentPage]);

  useEffect(() => {
    if (forceRerender) setForceRerender(false);
  }, [forceRerender]);

  // useEffect(() => {
  //   if (ticketAfterUpdateSync) ticketSyncAfterUpdate(ticketAfterUpdateSync);
  // }, [ticketAfterUpdateSync]);

  useEffect(() => {
    if (!currentTicket) return;
    if (ticketAfterUpdateSync) ticketSyncAfterUpdate();
  }, [currentTicket, currentTicketMembers]);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'My Tickets',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    executeAsyncProcess(async () => {
      loadData();
    });
  }, [myTicketsPageSize, myTicketsCurrentPage]);

  useEffect(() => {
    myTicketsCurrentPage1 = 1;
    setMyTicketsCurrentPage(1);
    loadData(true);
  }, [myTicketsQuery, myTicketsPriorities, myTicketsStatuses]);

  //#endregion

  //#region "data"

  const ticketSyncAfterUpdate = async () => {
    console.log('ticketSyncAfterUpdate()');
    try {
      const ticket = currentTicket;

      let rearrangedMembers;
      if (currentTicketMembers) {
        rearrangedMembers = [];
        currentTicketMembers.forEach((member) => {
          rearrangedMembers.push({
            live_notifications: member.live_notifications,
            role: member.role,
            ticket_id: member.ticket_id,
            user_id: member.user_id,
            user: {
              name: member.user_name,
              user_pic: member.user_pic,
            },
          });
        });
      }

      // Do not remove ticket from the list if it does not match the current filter. Just update it.

      let bShouldBeVisible = true;

      if (myTicketsPriorities && !ticket.ghostMode) {
        bShouldBeVisible = myTicketsPriorities === ticket.priority;
        if (!bShouldBeVisible) ticket.ghostMode = true; // Increase opacity, but do not remove from the list. Remove after selection changed.
      }

      if (myTicketsStatuses && !ticket.ghostMode) {
        const currentStatusFilter = myTicketsStatuses.split(',').map((x) => x.trim());
        bShouldBeVisible = currentStatusFilter.includes(ticket.status);
        if (!bShouldBeVisible) ticket.ghostMode = true; // Increase opacity, but do not remove from the list. Remove after selection changed.
      }

      if (rearrangedMembers) ticket.teacket_ticket_members = rearrangedMembers; // do not update if currentTicketMembers was null

      const index = myTickets.findIndex((x) => x.id === currentTicket.id);
      myTickets[index] = ticket;
      setMyTickets([...myTickets]);
    } catch (error) {
      console.error(error);
    } finally {
      setTicketAfterUpdateSync(null);
    }
  };

  const loadData = async (localLoader = false) => {
    try {
      localLoader && setLoading(true);
      const tickets = await getMyTickets(myTicketsCurrentPage1, myTicketsPageSize, myTicketsQuery, null, myTicketsPriorities, myTicketsStatuses, null);
      setMyTickets(tickets?.entities ?? []);
      setMyTicketsTotal(tickets?.total ?? 0);
      setForceRerender(true);
      localLoader && setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion

  //#region "HANDLERS"

  const handleCopyUrl = () => {
    console.log('handleCopyUrl()');
    navigator.clipboard.writeText(getTicketUrl());
    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Copied',
      duration: 3,
    });
  };

  const handleOpenInNewTab = () => {
    console.log('handleOpenInNewTab()');
    window.open(getTicketUrl(), '_blank');
  };

  // const handleCreateNew = () => {
  //   console.log('handleCreateNew()');
  //   navigate('/teacket/create-new');
  // };

  //#endregion

  //#region "OTHERS"

  const getTicketUrl = () => `${window.location.origin}/teacket/tickets/${currentTicket.id}`;

  //#endregion

  function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  const exportTickets = async (separator = ';') => {
    setCsvDownloading(true);
    console.log('exportTickets()');

    try {
      //const resp = await getTickets(1, 1000, ticketListQuery, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListUsers, ticketListStartDate, ticketListEndDate);
      const resp = await getMyTickets(1, 1000, myTicketsQuery, null, myTicketsPriorities, myTicketsStatuses, null);
      console.log(resp);

      let tickets = JSON.parse(JSON.stringify(resp?.entities));
      tickets.forEach((item) => {
        //if (!item.props) item.props = ''; //TODO: Later use it for custom fields
        if (!item.wrap_up_code) item.wrap_up_code = '';
        if (item.teacket_ticket_members && Array.isArray(item.teacket_ticket_members)) {
          item.members = item.teacket_ticket_members.map((x) => x?.user?.name).join('|');
        }
        if (item.owner_id) item.owner = item.teacket_ticket_members.find((x) => x.user_id === item.owner_id)?.user?.name;

        delete item.owner_id;
        delete item.service_id;
        delete item.assignee_id; //TODO: SHould it be delted ?
        delete item.teacket_ticket_members;
        delete item.props;
      });

      const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(tickets[0]);
      let csv = tickets.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(separator));
      csv.unshift(header.join(separator));
      csv = csv.join('\r\n');

      download(csv, 'export.csv');
    } catch (error) {
      console.error(error);
    } finally {
      setCsvDownloading(false);
    }
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create', 'apps.teacket.ticket.edit']}>
      {contextHolder}
      <LoaderServicesForTenant>
        <div className='h-[calc(100vh-64px)] flex flex-col'>
          <div className='flex-initial'>
            <TitleBar
              title={
                <Space className='btn'>
                  <BsCupHotFill />
                  <div>
                    <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
                  </div>
                </Space>
              }
              afterTitleExtras={
                <div className='flex flex-row items-center gap-4'>
                  <div>
                    <PageSwitch pageList={pageList} />
                  </div>
                  {/* <div>
                    <StyledButton color={theme.textBase} size='small' onClick={handleCreateNew}>
                      <Space className='btn'>
                        <MdAdd />
                        Create new
                      </Space>
                    </StyledButton>
                  </div> */}
                  {currentTicket && (
                    <>
                      {currentTicket?.type && <TicketTypeTag type={currentTicket.type} styling='mb-1 -mr-4' />}
                      <Tooltip title='Selected ticket ID' mouseEnterDelay={1}>
                        <div className='font-light'>{currentTicket.id}</div>
                      </Tooltip>
                      <Tooltip title='Copy ticket URL' mouseEnterDelay={1}>
                        <MdContentCopy className='hover:scale-110 cursor-pointer' onClick={handleCopyUrl} />
                      </Tooltip>
                      <Tooltip title='Open in a new tab' mouseEnterDelay={1}>
                        <MdOutlineExitToApp className='hover:scale-110 cursor-pointer' onClick={handleOpenInNewTab} />
                      </Tooltip>
                    </>
                  )}
                </div>
              }
            >
              <Space>
                <Tooltip title='CSV export limited to 1000 records.' mouseEnterDelay={0.7}>
                  <Button type='text' size='small' onClick={() => exportTickets(',')} loading={csvDownloading} icon={csvDownloading ? <ReloadOutlined className='scale-75' /> : null} className={`${csvDownloading ? 'disabled' : ''}`}>
                    Export (csv) <CloudDownloadOutlined />
                  </Button>
                </Tooltip>
              </Space>

              <SubscribeForLiveEvents />
              <TicketShare />
              {myTicketsLayoutItemsSelector}
            </TitleBar>
          </div>
          <div className='flex-auto p-2'>
            <div className='h-full flex flex-row'>
              <div
                style={{
                  minWidth: '240px',
                  maxWidth: '240px',
                  width: '240px',
                }}
                className='h-full flex flex-col gap-2'
              >
                <div className='ml-3 mr-2'>{loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px' }}></div>}</div>

                <div className='ml-3 mr-2'>
                  <Filters />
                </div>

                <div>{!forceRerender ? <Items /> : null}</div>
                <div>
                  <>
                    <Pagination />
                  </>
                </div>
              </div>
              <div className='h-full flex-auto '>
                <ItemDetails />
              </div>
            </div>
          </div>
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// MyTickets.propTypes = {};

export default MyTickets;
