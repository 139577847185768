import React from 'react';
import PropTypes from 'prop-types';
import EmailList from 'src/components/controls/EmailList/EmailList';
import * as dayjs from 'dayjs';
// import useTheme from 'src/hooks/useTheme';
import PropertySection from 'src/components/layout/PropertySection';

const TeacketServiceEditorRoutingSelectorItem = ({ record, toOnChange, ccOnChange }) => {
  // const { theme } = useTheme();
  return (
    <PropertySection>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col lg:flex-row gap-4'>
          <div className='flex-1'>
            TO email list <span className='font-light opacity-60'> (one per line)</span>:
            <EmailList defaultValue={record?.to_list?.split('|') ?? []} onChange={toOnChange} />
          </div>
          {/* <div className='flex-1'>
            CC email list <span className='font-light opacity-60'> (one per line)</span>:
            <EmailList defaultValue={record?.cc_list?.split('|') ?? []} onChange={ccOnChange} />
          </div> */}
        </div>
        <div className='flex flex-col lg:flex-row lg:justify-end lg:gap-4'>
          {record.id && (
            <div className='text-[0.6rem] font-light'>
              <span className='opacity-40'>Rule ID:</span> <span className='opacity-60'>{record.id}</span>
            </div>
          )}
          {record.created_at && (
            <div className='text-[0.6rem] font-light'>
              <span className='opacity-60'>Created {dayjs(record.created_at).fromNow()}</span> <span className='opacity-40'>(at {dayjs(record.created_at).format('LLL')})</span>
            </div>
          )}
          {record.updated_at && (
            <div className='text-[0.6rem] font-light'>
              <span className='opacity-60'>Updated {dayjs(record.updated_at).fromNow()}</span> <span className='opacity-40'>(at {dayjs(record.updated_at).format('LLL')})</span>
            </div>
          )}
        </div>
      </div>
    </PropertySection>
  );
};

TeacketServiceEditorRoutingSelectorItem.propTypes = {
  record: PropTypes.object,
  toOnChange: PropTypes.func,
  ccOnChange: PropTypes.func,
};

export default TeacketServiceEditorRoutingSelectorItem;
