import React, { useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import LangPicker from 'src/components/controls/LangPicker/LangPicker';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { patchProjectProps } from 'src/api/fsireport';
import { LANGS_MAX_NUMBER } from '../Misc/misc';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { Alert, Skeleton, message } from 'antd';
import { uuid } from 'short-uuid';
//import PropTypes from 'prop-types';

const LanguagesWidget = () => {
  const { currentProject, patchCurrentProjectProps } = useContext(FsiReportContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const handleOnChange = async (value) => {
    console.log('handleOnChange', value);
    executeAsyncProcess(async () => {
      try {
        const result = await patchProjectProps(currentProject?.id, { langs: value });
        const { langs } = result ?? {};
        patchCurrentProjectProps({ langs });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Languages updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  if (!currentProject) return <Skeleton.Button active block={true} />;

  return (
    <div className='flex flex-col gap-2'>
      {contextHolder}
      <LangPicker value={currentProject?.props?.langs} supportedLanguages={['en', 'fr', 'de']} onChange={handleOnChange} maxCount={LANGS_MAX_NUMBER} />
      {true === notArrayOrEmpty(currentProject?.props?.langs) && <Alert message='Language is not selected!' type='error' showIcon />}
    </div>
  );
};

LanguagesWidget.propTypes = {};

export default LanguagesWidget;
