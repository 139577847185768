import React, { useState, useEffect } from 'react';
import { Select, Spin, Space } from 'antd';
import { getOpportunititesByName, getOpportunityDSR, getOpportunititesForAccount } from 'src/api/snowflake';
import { FiHash } from 'react-icons/fi';
import { TbWorldPin } from 'react-icons/tb';
import { PiIdentificationBadgeFill } from 'react-icons/pi';
import { FaHandHoldingUsd } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { isRequestCancelled } from 'src/misc/Misc';

const { Option } = Select;

const SnowflakeOpportunityPicker = ({ onSelect, active, limit, accountId, setBusy }) => {
  const [loading, setLoading] = useState(false);
  const [loadingOppsForAccount, setLoadingOppsForAccount] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  useEffect(() => {
    if (setBusy) setBusy(true);
    handleLoadOpportunities();
  }, []);

  const handleLoadOpportunities = async () => {
    if (accountId) {
      try {
        setItemsList([]);
        setLoadingOppsForAccount(true);
        setStatusMessage('Loading opportunities...');
        const resp = await getOpportunititesForAccount(accountId, active);
        if (resp) {
          console.log('Found opportunities:', resp?.length);
          setItemsList(resp);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setStatusMessage(null);
        setLoadingOppsForAccount(false);
      }
    }
  };

  const handleSearch = async (query) => {
    setItemsList([]);
    if (query.length < 3) return;
    try {
      setLoading(true);
      let resp = await getOpportunititesByName(query, active, limit);
      if (resp) {
        console.log('Found opportunities:', resp?.length);
        setItemsList(resp);
      }

      setLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleClear = async () => {
    console.log('handleClear');
    setSelectedItem(null);
    setItemsList([]);
    if (onSelect) onSelect(null);
  };

  // const isOptionDisabled = (key) => {
  //   return true === notEmptyArray(rolePermissions?.filter((x) => x.permission === key));
  // };

  const handleAssign = async (itemId) => {
    console.log('handleAssign', itemId);
    const selectedItem = itemsList.find((x) => x.OPPORTUNITY_ID === itemId);

    // Custom logic & formatting

    selectedItem.OPPORTUNITY_DIRECT_INDIRECT_SALE = selectedItem?.OPPORTUNITY_DIRECT_INDIRECT_SALE && selectedItem?.OPPORTUNITY_DIRECT_INDIRECT_SALE.toLowerCase() === 'direct' ? 'Direct' : selectedItem?.OPPORTUNITY_DIRECT_INDIRECT_SALE ? 'Indirect' : undefined;
    selectedItem.OPPORTUNITY_DEAL_TYPE = selectedItem?.OPPORTUNITY_DEAL_TYPE && selectedItem?.OPPORTUNITY_DEAL_TYPE.toLowerCase() === 'new logo' ? 'New Logo' : selectedItem?.OPPORTUNITY_DEAL_TYPE ? 'Existing Customer' : undefined;
    selectedItem.OPPORTUNITY_COUNTRY_REGION = selectedItem?.OPPORTUNITY_COUNTRY_REGION && selectedItem?.OPPORTUNITY_COUNTRY_REGION.toLowerCase() === 'north america' ? 'NA' : selectedItem?.OPPORTUNITY_COUNTRY_REGION ? selectedItem?.OPPORTUNITY_COUNTRY_REGION : undefined;

    selectedItem.OPPORTUNITY_OWNER_EMAIL = selectedItem.OPPORTUNITY_OWNER_EMAIL ?? '';
    selectedItem.OPPORTUNITY_OWNER_NAME = selectedItem.OPPORTUNITY_OWNER_NAME ?? '';
    selectedItem.OPPORTUNITY_SC_NAME = selectedItem.OPPORTUNITY_SC_NAME ?? '';
    selectedItem.OPPORTUNITY_SC_EMAIL = selectedItem.OPPORTUNITY_SC_EMAIL ?? '';

    setSelectedItem(itemId);

    try {
      if (setBusy) {
        setBusy(true);
      }
      setLoading(true);
      setStatusMessage('Fetching DSR... Please wait...');
      let resp = await getOpportunityDSR(itemId);
      if (resp) {
        selectedItem.OPPORTUNITY_DSR_URL = resp.DSR_URL;
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (setBusy) setBusy(false);
      setLoading(false);
      setStatusMessage(null);
    }

    if (onSelect) {
      onSelect(selectedItem ?? { id: itemId });
    }
  };

  return (
    <>
      <Select
        value={selectedItem}
        autoFocus
        disabled={loadingOppsForAccount}
        open={!selectedItem && accountId && itemsList.length > 0}
        showSearch
        allowClear
        placeholder='Type to search'
        loading={loading || loadingOppsForAccount}
        notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
        filterOption={false}
        onSearch={handleSearch}
        onClear={handleClear}
        onSelect={handleAssign}
        style={{ width: '100%' }}
        size='large'
        optionRender={(option) => {
          const item = itemsList.find((x) => x.OPPORTUNITY_ID === option.key);
          if (!item) return null;

          return (
            <div className='flex flex-row gap-2 my-1'>
              <div className='flex flex-col'>
                <div className='overflow-hidden'>{item.OPPORTUNITY_NAME}</div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FiHash />
                    {item.OPPORTUNITY_ID}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FaHandHoldingUsd />
                    {item.OPPORTUNITY_GROSS_ACV_BOOKING_USD ? 'ACV ' + Math.round(item.OPPORTUNITY_GROSS_ACV_BOOKING_USD) + ' $' : 'ACV: N/A'}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <PiIdentificationBadgeFill />
                    {item.OWNER_NAME || 'N/A'}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <TbWorldPin />
                    {item.OPPORTUNITY_REGION || 'N/A'}
                  </Space>
                </div>
              </div>
            </div>
          );
        }}>
        {itemsList.map((item) => (
          <Option key={item.OPPORTUNITY_ID} autoComplete='off'>
            {item.OPPORTUNITY_NAME}
          </Option>
        ))}
      </Select>
      <div
        className='text-center mt-2 mb-1 text-xs font-light opacity-60'
        style={{
          animation: 'blink 2s linear infinite',
        }}>
        <div className='min-h-[1em]'>{statusMessage ?? ''}</div>
      </div>
      <style>
        {`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.1; }
        }
      `}
      </style>
    </>
  );
};

SnowflakeOpportunityPicker.propTypes = {
  onSelect: PropTypes.func,
  active: PropTypes.bool,
  limit: PropTypes.number,
  setBusy: PropTypes.func,
  accountId: PropTypes.string,
};

SnowflakeOpportunityPicker.defaultProps = {
  onSelect: () => {
    console.warn('Function onSelect not provided');
  },
  active: false,
  limit: 50,
};

export default SnowflakeOpportunityPicker;
