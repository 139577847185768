import React, { useState, useEffect, useContext } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, DatePicker, Dropdown, Button, Select, Alert, Typography, Tooltip, Switch } from 'antd';
import UserImage from 'src/components/layout/UserImage';
import { DownOutlined, CaretRightOutlined, SendOutlined } from '@ant-design/icons';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { AiOutlineAudit } from 'react-icons/ai';
import { MdOutlineManageSearch } from 'react-icons/md';
import useTheme from 'src/hooks/useTheme';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import useScreen from 'src/hooks/useScreen';
import ColumnsHeader from 'src/components/layout/ColumnsHeader';

import { getAuditsQueryServiceMapping, createAuditsQuery, getAuditsQueryStatus, getAuditsQueryResults } from 'src/api/auditviewer';
import { searchMultipleUsers } from 'src/api/users';

import { getBrowserTimeZone } from 'src/misc/Timezone';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import EventsButton from './Components/Events/EventsButton';
import EventsSetupChecker from './Components/Events/EventsSetupChecker';
import FilterButton from './Components/Filter/FilterButton';
import FilterPanel from './Components/Filter/FilterPanel';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { TbFilter } from 'react-icons/tb';
import FilterLoadSave from './Components/Filter/FilterLoadSave';
import EventFilterPresets from './Components/Filter/EventFilterPresets';
import WhenEmeabilling from 'src/components/layout/WhenEmeabilling';
import useUserInfo from 'src/hooks/useUserInfo';
import CondensedTable from 'src/components/layout/CondensedTable';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const AuditViewer = () => {
  const { serviceName, entityType, action, setServiceName, setEntityType, setAction } = useContext(AuditViewerContext);
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { tenant_id } = useUserInfo();

  const { theme } = useTheme();
  const { isDesktop } = useScreen();
  const [layoutHorizontal, setLayoutHorizontal] = useState(isDesktop);

  const { executeAsyncProcess } = useAsyncProcesses();

  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [browserTimeZone, setBrowserTimeZone] = useState('UTC');
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [customSelectedInterval, setCustomSelectedInterval] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [serviceMapping, setServiceMapping] = useState([]);
  const [entities, setEntities] = useState([]);
  const [actions, setActions] = useState([]);
  const [loaderMessage, setLoaderMessage] = useState('Getting audits data...');
  const [errorMessage, setErrorMessage] = useState(undefined);

  //#region useEffect(s)

  useEffect(() => {
    //#region Service Mapping

    const getServiceMapping = async () => {
      executeAsyncProcess(async () => {
        try {
          const serviceMappings = await getAuditsQueryServiceMapping();
          console.log('[AuditView] Service Mapping', serviceMappings);
          if (serviceMappings?.services) {
            const services = serviceMappings.services.sort((a, b) => a.name.localeCompare(b.name));
            console.log('[AuditView] Services', services);
            setServiceMapping(services);
          }
        } catch (error) {
          console.error(error);
          setErrorMessage(error);
        }
      });
    };

    getServiceMapping();

    //#endregion

    const tz = getBrowserTimeZone();
    setBrowserTimeZone(tz);
    const i = getDatesForPredefinedInterval('past-7-days', tz);
    setSelectedInterval(i);

    setBreadcrumb([
      {
        title: 'Audit Viewer',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  //#region Interval

  const predefinedIntervalListOnClick = (e) => {
    console.log('[AuditView] predefinedIntervalListOnClick()', e);
    if (!e || !e.key) {
      return;
    }
    setCustomSelectedInterval(false);
    setSelectedInterval(getDatesForPredefinedInterval(e.key, browserTimeZone));
    let cf = { ...currentFilter };
    setCurrentFilter(cf);
    getAuditLogs(cf);
  };

  const predefinedIntervalList = [
    { key: 'today', label: 'Today' },
    { key: 'yesterday', label: 'Yesterday' },
    { key: 'past-7-days', label: 'Past 7 days' },
    { key: 'week-to-date', label: 'This week to date' },
    { key: 'last-week', label: 'Last week' },
    { key: 'past-30-days', label: 'Past 30 days' },
    { key: 'month-to-date', label: 'This month to date' },
    { key: 'last-month', label: 'Last month' },
    { key: 'quarter-to-date', label: 'This quarter to date' },
    { key: 'last-quarter', label: 'Last quarter' },
  ];

  const isIntervalValid = (interval) => {
    return Array.isArray(interval) && interval.length === 2;
  };

  const getDatesForPredefinedInterval = (predefinedInterval, timezone) => {
    console.log(`[AuditView] getDatesForPredefinedIInterval(${predefinedInterval}, ${timezone});`);
    let interval = null;
    switch (predefinedInterval) {
      case 'today':
        interval = dayjs().tz(timezone).startOf('day').utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      case 'yesterday':
        interval = dayjs().tz(timezone).subtract(1, 'days').startOf('day').utc().format() + '/' + dayjs().tz(timezone).subtract(1, 'days').endOf('day').utc().format();
        break;
      case 'past-7-days': {
        interval = dayjs().tz(timezone).subtract(7, 'days').startOf('day').utc().format() + '/' + dayjs().tz(timezone).subtract(1, 'days').endOf('day').utc().format();
        break;
      }
      case 'week-to-date':
        interval = dayjs().tz(timezone).startOf('isoWeek').utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      case 'last-week':
        interval = dayjs().tz(timezone).subtract(1, 'weeks').startOf('isoWeek').utc().format() + '/' + dayjs().tz(timezone).subtract(1, 'weeks').endOf('isoWeek').utc().format();
        break;
      case 'past-30-days': {
        interval = dayjs().tz(timezone).subtract(30, 'days').startOf('day').utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      }
      case 'month-to-date':
        interval = dayjs().tz(timezone).startOf('month').utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      case 'last-month':
        interval = dayjs().tz(timezone).subtract(1, 'months').startOf('month').utc().format() + '/' + dayjs().tz(timezone).subtract(1, 'months').endOf('month').utc().format();
        break;
      case 'quarter-to-date':
        interval = dayjs().tz(timezone).startOf('quarter').utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      case 'last-quarter':
        interval = dayjs().tz(timezone).subtract(1, 'quarters').startOf('quarter').utc().format() + '/' + dayjs().tz(timezone).subtract(1, 'quarters').endOf('quarter').utc().format();
        break;
      case 'my-requests':
        interval = dayjs('2020-03-01').tz(timezone).utc().format() + '/' + dayjs().tz(timezone).endOf('day').utc().format();
        break;
      default:
        throw new Error(`Unknown interval: ${predefinedInterval}`);
    }
    console.log('[AuditView] interval', interval);
    return interval;
  };

  //#endregion

  //#region Filter Handlers

  const handleServiceNameChanged = (v) => {
    console.log('[AuditView] handleServiceNameChanged', v);
    setServiceName(v);

    // Set entity types
    if (v !== null) {
      setEntityType('<ALL>');
      const currentEntities = serviceMapping.find((sm) => sm.name === v)?.entities.sort((a, b) => a.name.localeCompare(b.name));
      setEntities(currentEntities);

      // Set actions
      setAction('<ALL>');
      setActions([]);
    }
  };

  const handleEntityTypeChanged = (v) => {
    console.log('[AuditView] handleEntityTypeChanged', v);
    setEntityType(v);

    // Set actions
    if (v !== null) {
      setAction('<ALL>');
      const currentActions = serviceMapping
        .find((sm) => sm.name === serviceName)
        ?.entities?.find((e) => e.name === v)
        ?.actions?.sort((a, b) => a.localeCompare(b));
      console.log('[AuditView] currentActions', currentActions);
      if (currentActions) {
        setActions(currentActions);
      }
    }
  };

  const handleActionChanged = (v) => {
    console.log('[AuditView] handleActionChanged', v);
    setAction(v);
  };

  const onApply = (e) => {
    e.preventDefault();
    getAuditLogs();
  };

  //#endregion

  //#region Data Fetching
  async function getAuditLogs() {
    console.log('[AuditView] getAuditLogs');
    console.log('====================== interval:', selectedInterval);
    console.log('====================== serviceName:', serviceName);
    console.log('====================== entityType:', entityType);
    console.log('====================== action:', action);
    if (!serviceName || !selectedInterval) return;

    setLoading(true);
    executeAsyncProcess(async () => {
      try {
        setAuditLogs([]);
        setLoading(true);
        setLoaderMessage('Fetching audit logs...');
        const resp = await createAuditsQuery(selectedInterval, serviceName, entityType, action);
        console.log('[AuditView] resp: ', resp);
        // setAuditLogs(resp.entities);
        setLoaderMessage('Fetching audit logs... ' + resp.state);
        await getAuditResults(resp.id);
      } catch (error) {
        console.error('error.message:', error.message);
        console.error(error);
        if (error.message === 'Payload Too Large') {
          alert('The request list is too large to display. Please narrow your search criteria.');
        } else {
          alert(error);
        }
        setAuditLogs([]);
      } finally {
        setLoading(false);
      }
    });
  }

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const getAuditResults = async (queryId) => {
    console.log('[AuditView] getAuditResults', queryId);
    if (!queryId) {
      setAuditLogs([]);
      setLoading(false);
      return;
    }

    // Wait for succeeded state
    let auditStatus = null;
    let counter = 0;
    while (auditStatus !== 'Succeeded' && counter < 30) {
      counter++;
      const queryStatusResponse = await getAuditsQueryStatus(queryId);
      // console.log('[AuditView] queryStatusResponse: ', queryStatusResponse);
      setLoaderMessage('Fetching audit logs... ' + queryStatusResponse.state);
      auditStatus = queryStatusResponse.state;
      if (auditStatus !== 'Succeeded') await sleep(1000);
    }

    // Get audit results
    if (auditStatus === 'Succeeded') {
      setLoaderMessage('Getting results...');
      const auditResults = [];
      const auditResultsResponse = await getAuditsQueryResults(queryId);
      // console.log('[AuditView] auditResultsResponse: ', auditResultsResponse);
      if (auditResultsResponse.entities) {
        auditResults.push(...auditResultsResponse.entities);
        setLoaderMessage(`Getting results... ${auditResults.length}/ (limit 10k)`);

        // Get more audit results using cursor
        let cursor = auditResultsResponse.cursor;
        while (auditResultsResponse.entities && cursor && auditResults.length < 10000) {
          // console.log('Get more audit results...', cursor);
          const moreAuditResultsResponse = await getAuditsQueryResults(queryId, encodeURIComponent(cursor));
          // console.log('[AuditView] moreAuditResultsResponse: ', moreAuditResultsResponse);
          if (Object.keys(moreAuditResultsResponse).includes('entities')) {
            auditResults.push(...moreAuditResultsResponse.entities);
            setLoaderMessage('Getting results... ' + auditResults.length);
            cursor = moreAuditResultsResponse.cursor;
          } else {
            cursor = null;
          }
        }
      }

      // We got it all!
      console.log('[AuditView] auditResults: ', auditResults?.length);

      // Get user names/images

      const userIds = [...new Set(auditResults.filter((r) => r?.user?.id).map((r) => r.user?.id))];

      if (userIds.length > 0) {
        console.log(`[AuditView] Getting user info for ${userIds?.length} user ids`);
        const users = await searchMultipleUsers(userIds);
        console.log('[AuditView] users: ', users);

        auditResults.forEach((r) => {
          if (r.user?.id) {
            // console.log('r.user.id:', r.user.id);
            const user = users.find((u) => u.id === r.user.id);
            // console.log('user:', user);
            if (user) {
              r.user = user;
            }
          }
        });
      }

      setAuditLogs(auditResults);
    } else {
      console.error('[AuditView] Audit query failed');
      setAuditLogs([]);
    }
    setLoading(false);
  };

  //#endregion

  //#region Switches

  const handleBtnApplyCustomInterval = async () => {
    console.log('[AuditView] handleBtnApplyCustomInterval()');
    setCustomSelectedInterval(false);
    await getAuditLogs();
  };

  //#endregion

  const getLocationBasedOnIp = async (row) => {
    try {
      setLoading(true);
      setLoaderMessage('Tranlsate location...');
      const response = await fetch(`https://ipapi.co/${row.remoteIp[0]}/json/`);
      const data = await response.json();

      const rows = [...auditLogs];

      rows.forEach((r) => {
        if (r.remoteIp && r.remoteIp.length > 0 && r.remoteIp[0] === row.remoteIp[0]) {
          r.custom_remoteIpLocation = `${data.city}, ${data.region}`;
          r.custom_remoteIpLocationCountry = data.country_name;
        }
      });

      setAuditLogs(rows);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //#region Table Configuration

  const columnsDefinition = [
    {
      key: 'entityType',
      cellStyle: { paddingLeft: '0' },
      headerStyle: { paddingLeft: '0' },
      title: 'Type',
      render: (rowData) => rowData?.entityType,
    },
    {
      key: 'entity',
      title: 'Entity',
      render: (rowData) => rowData?.entity?.name || rowData?.entity?.id,
    },
    {
      key: 'userName',
      title: 'User',
      render: (record) => {
        return record?.user?.name ? (
          <Tooltip title={record?.user?.name}>
            <Space>
              <UserImage image={record?.user?.image} size='26px' />
              {record?.user?.name}
            </Space>
          </Tooltip>
        ) : (
          record?.user?.name || record?.user?.id
        );
      },
    },
    {
      key: 'action',
      title: 'Action',
      render: (rowData) => rowData.action,
    },
    {
      key: 'eventDate',
      title: 'Date',
      dataIndex: 'eventDate',
      render: (rowData) => `${dayjs(rowData.eventDate || rowData).fromNow()}`,
      sorter: (a, b) => dayjs(a.eventDate).unix() - dayjs(b.eventDate).unix(),
      defaultSortOrder: 'descend',
    },
    {
      key: 'remoteIp',
      title: 'Remote IP',
      render: (rowData) => (
        <>
          {rowData?.custom_remoteIpLocation ? (
            <Tooltip title={rowData?.remoteIp[0]}>
              <div className='text-xs'>
                {rowData?.custom_remoteIpLocation}
                <br />
                {rowData?.custom_remoteIpLocationCountry}
              </div>
            </Tooltip>
          ) : (
            <>
              <Space>
                <span className='mr-2'>{rowData?.remoteIp[0]}</span>
                {rowData?.remoteIp[0] && (
                  <Tooltip title='Get location'>
                    <MdOutlineManageSearch className='cursor-pointer' onClick={() => getLocationBasedOnIp(rowData)} />
                  </Tooltip>
                )}
              </Space>
            </>
          )}
        </>
      ),
    },
  ];

  //#endregion

  //#region "UI"

  const getLayout = (leftPanel, rightPanel) => {
    if (tenant_id === '3b03b67a-2349-4a03-8b28-c8ac5c26c49a') {
      return (
        <Splitter initialSizes={[75, 25]} direction={layoutHorizontal ? SplitDirection.Horizontal : SplitDirection.Vertical}>
          {leftPanel}
          {rightPanel}
        </Splitter>
      );
    }
    return <>{leftPanel}</>;
  };

  //#endregion

  return (
    <FilterLoadSave>
      <TitleBar
        title={
          <Space className='btn'>
            <AiOutlineAudit />
            Audit Viewer
          </Space>
        }
        afterTitleExtras={
          <>
            {selectedInterval && (
              <RangePicker
                value={[dayjs(new Date(selectedInterval.split('/')[0].split('T')[0])), dayjs(new Date(selectedInterval.split('/')[1].split('T')[0]))]}
                onChange={(e) => {
                  console.log(e);
                  // <handle clearing the range>
                  if (!Array.isArray(e) || e.length !== 2) {
                    setSelectedInterval(null);
                    return;
                  }
                  // </handle clearing the range>
                  setCustomSelectedInterval(true);

                  setSelectedInterval(`${e[0].endOf('day').utc().format()}/${e[1].endOf('day').utc().format()}`);
                }}
              />
            )}
            <Dropdown menu={{ items: predefinedIntervalList, onClick: predefinedIntervalListOnClick }} trigger={['click']}>
              <Button type='link' className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                <DownOutlined />
              </Button>
            </Dropdown>
            {customSelectedInterval && isIntervalValid(selectedInterval) === true && (
              <Button onClick={handleBtnApplyCustomInterval}>
                <CaretRightOutlined />
              </Button>
            )}
            {/* Services */}
            <Select style={{ width: '200px' }} disabled={serviceMapping?.length === 0} value={serviceName} onChange={handleServiceNameChanged} placeholder='Select a service'>
              {serviceMapping.map((service) => (
                <Option key={service.name}>{service.name}</Option>
              ))}
            </Select>
            {/* Entities */}
            <Select disabled={entities?.length === 0} style={{ width: '200px' }} value={entityType || '<ALL>'} onChange={handleEntityTypeChanged}>
              <Option key='<ALL>'>{'<ALL>'}</Option>
              {entities.map((entity) => (
                <Option key={entity.name}>{entity.name}</Option>
              ))}
            </Select>
            {/* Actions */}
            <Select disabled={actions?.length === 0} style={{ width: '200px' }} value={action || '<ALL>'} onChange={handleActionChanged}>
              <Option key='<ALL>'>{'<ALL>'}</Option>
              {actions.map((action) => (
                <Option key={action}>{action}</Option>
              ))}
            </Select>
            <Button onClick={onApply} disabled={!serviceName || !selectedInterval} type='primary' style={{ marginLeft: '10px' }}>
              <SendOutlined />
            </Button>
            <WhenEmeabilling>
              <FilterButton />
            </WhenEmeabilling>
          </>
        }>
        <WhenEmeabilling>
          <Space>
            <EventsSetupChecker>
              <EventsButton />
            </EventsSetupChecker>
            <Switch
              unCheckedChildren={<>vertical</>}
              checkedChildren={<>horizontal</>}
              checked={layoutHorizontal}
              onChange={() => {
                setLayoutHorizontal(!layoutHorizontal);
              }}
            />
          </Space>
        </WhenEmeabilling>
      </TitleBar>
      <div className='w-full p-2 flex flex-col gap-4'>
        <Alert message='The Audit Viewer enables you to filter and see various events that Genesys Cloud generates based on service actions for administrative functions.' type='info' showIcon closable={true} />
        <Alert message='To get started, select a "Service" above and use the other 2 dropdowns to narrow down the audit events that are of interest to you.' type='info' showIcon closable={true} />
      </div>
      {getLayout(
        <>
          <div className={'overflow-scroll h-full w-full p-4'} style={{ backgroundColor: theme.backgroundBase }}>
            {errorMessage && <Alert style={{ fontSize: '20px' }} message={<Text strong>{errorMessage}</Text>} type='error' showIcon />}
            {/* Table */}
            <CondensedTable
              rowKey={(record) => record.id}
              size='small'
              columns={columnsDefinition}
              dataSource={auditLogs}
              expandable={{
                expandedRowRender: (record) => (
                  <div className='ml-14 mt-2 '>
                    {record &&
                      Object.entries(record)
                        .sort((a, b) => -b[0].localeCompare(a[0]))
                        .map((entry) => {
                          if (entry[0].startsWith('custom_')) return null;
                          return (
                            <div key={`${entry[0]}-${entry[1]}`}>
                              <Text strong>{entry[0]}:</Text> <Text>{JSON.stringify(entry[1], null, 2)}</Text>
                            </div>
                          );
                        })}
                  </div>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
              loading={{
                indicator: <FullScreenSpin title='Please wait' subtitle={loaderMessage} />,
                spinning: loading,
              }}
            />
          </div>
          <div className={'overflow-scroll h-full w-full p-4'} style={{ backgroundColor: theme.backgroundBase }}>
            <ColumnsHeader
              title={
                <Space className='btn text-lg font-bold'>
                  <TbFilter />
                  Filters / Subscriptions
                </Space>
              }
              extras={<EventFilterPresets />}
            />

            <div className='mt-4 flex flex-col gap-2'>
              <FilterPanel />
            </div>
          </div>
        </>
      )}
    </FilterLoadSave>
  );
};

export default AuditViewer;
