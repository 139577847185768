import React, { useState, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropTypes from 'prop-types';
import { getAdminFeaturesAccessListForTenant, patchAdminFeaturesAccess, postAdminFeaturesAccess } from 'src/api/accesscontrol';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useTheme from 'src/hooks/useTheme';
import dayjs from 'dayjs';
import { Space, Button, Tooltip, message, Modal, Input } from 'antd';
import { TbLockAccess, TbLockAccessOff } from 'react-icons/tb';
import useUserInfo from 'src/hooks/useUserInfo';
import { uuid } from 'short-uuid';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import FeaturePicker from 'src/components/controls/FeaturePicker/FeaturePicker';
import Na from 'src/components/layout/Na';
import { describeFeaturesAccess } from 'src/components/controls/FeatruesAccess/Misc';
import { notEmptyArray } from 'src/misc/Misc';

const FeaturesAccessEditor = ({ tenantId, tenantName }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { id: userId } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [featuresAccess, setFeaturesAccess] = useState(null);
  const [featuresAccessModalOpenForFeature, setFeaturesAccessModalOpenForFeature] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const columns = [
    {
      title: 'Feature',
      key: 'feature',
      render: (record) => <>{record.feature}</>,
    },
    {
      title: 'Free trial',
      key: 'freeTrial',
      align: 'center',
      width: '30%',
      render: (record) => (
        <div>
          {/* dates */}
          <div>
            {record.free_trial_from ? <>{dayjs(record.free_trial_from).format('ll')}</> : <Na />} - {record.free_trial_to ? <>{dayjs(record.free_trial_to).format('ll')}</> : <Na />}
          </div>
          {/* description */}
          <div className={`font-light text-xs opacity-80 ${record.freeTrialError ? 'text-red-500' : record.freeTrialHasAccess ? 'text-green-500' : 'text-orange-500'}`}>{record.freeTrialDesc}</div>
        </div>
      ),
    },
    {
      title: 'Paid access',
      key: 'paidAccess',
      align: 'center',
      width: '30%',
      render: (record) => (
        <div>
          {/* dates */}
          <div>
            {record.paid_access_from ? <>{dayjs(record.paid_access_from).format('ll')}</> : <Na />} - {record.paid_access_to ? <>{dayjs(record.paid_access_to).format('ll')}</> : <Na />}
          </div>
          {/* description */}
          <div className={`font-light text-xs opacity-80 ${record.paidAccessError ? 'text-red-500' : record.paidAccessHasAccess ? 'text-green-500' : 'text-orange-500'}`}>{record.paidAccessDesc}</div>
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 80,
      render: (record) => (
        <Space>
          <Tooltip title='Grant paid access'>
            <Button type='text' size='small' className='p-1' onClick={() => setFeaturesAccessModalOpenForFeature(record.feature)}>
              <TbLockAccess className='text-green-500 text-xl' />
            </Button>
          </Tooltip>

          <YesNoDialog
            title={<>Revoke paid access</>}
            body={
              <>
                Do you want to revoke the paid access to <strong>{record.feature}</strong>
                <br />
                for <strong>{tenantName}</strong>?
              </>
            }
            onYesClick={() => {
              handleRevokePaidAccess(record.feature);
            }}
            showRed={true}
            labelYes='Yes, revoke paid access'
          >
            <Tooltip title='Revoke paid access'>
              <Button type='text' size='small' className='p-1' disabled={!record.paid_access_from || !record.paid_access_to}>
                <TbLockAccessOff className={`${!record.paid_access_from || !record.paid_access_to ? 'text-grey-500' : 'text-red-500'} text-xl`} />
              </Button>
            </Tooltip>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!tenantId) return;
    setFeaturesAccess(null);
    loadData();
  }, [tenantId]);

  useEffect(() => {
    if (!featuresAccessModalOpenForFeature) return;
    setDateFrom(dayjs().startOf('day').format());
    setDateTo(dayjs().add(1, 'year').endOf('day').format());
  }, [featuresAccessModalOpenForFeature]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminFeaturesAccessListForTenant(tenantId);
        console.log('resp', resp);
        setFeaturesAccess(true === notEmptyArray(resp) ? resp.map((x) => describeFeaturesAccess(x)) : null);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleGrantPaidAccess = () => {
    console.log('grant paid access');
    const feature = featuresAccessModalOpenForFeature;
    setFeaturesAccessModalOpenForFeature(null);
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving...',
          duration: 0,
        });
        const resp = await patchAdminFeaturesAccess(tenantId, feature, { paid_access_from: dateFrom, paid_access_to: dateTo, paid_access_updated_by: userId });
        const updated = featuresAccess.map((item) => (item.feature === feature ? describeFeaturesAccess(resp) : item));
        setFeaturesAccess(updated);
        messageApi.open({
          key,
          type: 'success',
          content: 'Saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleRevokePaidAccess = (feature) => {
    console.log('revoke paid access', feature);
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving...',
          duration: 0,
        });
        const resp = await patchAdminFeaturesAccess(tenantId, feature, { paid_access_from: null, paid_access_to: null, paid_access_updated_by: userId });
        const updated = featuresAccess.map((item) => (item.feature === feature ? resp : item));
        setFeaturesAccess(updated);
        messageApi.open({
          key,
          type: 'success',
          content: 'Saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.destroy();
      }
    });
  };

  const handleOnFeaturePick = (v) => {
    setSelectedFeature(v);
  };

  const handleInserFeature = () => {
    if (!selectedFeature) return;
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving...',
          duration: 0,
        });
        const resp = await postAdminFeaturesAccess({ tenant_id: tenantId, feature: selectedFeature });
        const updated = [...featuresAccess, resp];
        setFeaturesAccess(updated);
        messageApi.open({
          key,
          type: 'success',
          content: 'Saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.destroy();
      }
    });
  };

  const featureCanBeAdded = () => {
    if (!selectedFeature) return false;
    return !featuresAccess.find((item) => item.feature === selectedFeature);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={<>Grant paid access</>}
        open={featuresAccessModalOpenForFeature !== null}
        closable={false}
        footer={
          <Space>
            <Button type='primary' onClick={handleGrantPaidAccess}>
              Submit
            </Button>
            <Button onClick={() => setFeaturesAccessModalOpenForFeature(null)}>Cancel</Button>
          </Space>
        }
      >
        <div>
          <div>Tenant name:</div>
          <Input value={tenantName} disabled />
          <div className='mt-2'>Feature:</div>
          <Input value={featuresAccessModalOpenForFeature} disabled />
          <div className='mt-2'>Period:</div>
          <RangePicker
            startDate={dateFrom}
            endDate={dateTo}
            onChange={(v) => {
              setDateFrom(v.startDate);
              setDateTo(v.endDate);
            }}
            showDropdown={false}
          />
        </div>
      </Modal>

      <div className='px-2 h-14 flex-initial flex flex-row gap-2 overflow-hidden items-center' style={{ backgroundColor: theme.backgroundLight }}>
        <FeaturePicker width='300px' onPick={handleOnFeaturePick} />
        <Button type='primary' disabled={false === featureCanBeAdded()} onClick={handleInserFeature}>
          Add to the list
        </Button>
      </div>
      <div className='mt-2'>
        <CondensedTableLegacy columns={columns} dataSource={featuresAccess} />
      </div>
    </>
  );
};

FeaturesAccessEditor.propTypes = {
  tenantId: PropTypes.string,
  tenantName: PropTypes.string,
};

export default FeaturesAccessEditor;
