import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropertyItem from 'src/components/layout/PropertyItem';
import { Button, Modal, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { putTicketWidgetsValues } from 'src/api/teacket';
import PropTypes from 'prop-types';

const CustomFieldDateTime = ({ ticketId, field, onUpdate, alwaysReadOnly, widgetId = 'Additional Properties', showTime = true }) => {
  const { determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(null);

  useEffect(() => {
    setNewValue(field.value ? dayjs(field.value) : null);
  }, [isEditing, field.value]);

  //#region "EVENT HANDLERS"

  const handlePropertyItemOnClick = () => {
    console.log('CustomFieldDateTime:', field);
    setIsEditing(true);
  };

  const handleModalOnSave = async () => {
    setIsEditing(false);
    await executeAsyncProcess(async () => {
      try {
        const result = await putTicketWidgetsValues(ticketId, widgetId, field.field, newValue.toISOString());
        if (onUpdate) onUpdate({ ticketId, field, result });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleModalOnCancel = () => {
    setIsEditing(false);
  };

  //#endregion

  //#region "MISC"

  const isSaveDisabled = () => {
    return newValue && newValue.isSame(dayjs(field.value));
  };

  const isCancelDisabled = () => {
    return false;
  };

  const getLabelExtras = () => {
    if (true === field.isRequired && !field.value) {
      return <span className='text-red-500 animate-pulse'>is required!</span>;
    }
    return null;
  };

  //#endregion

  return (
    <>
      <Modal
        closable={false}
        open={isEditing}
        title={field.field}
        footer={[
          <Button key='Save' onClick={handleModalOnSave} type='primary' disabled={isSaveDisabled()}>
            Save
          </Button>,
          <Button key='Cancel' onClick={handleModalOnCancel} disabled={isCancelDisabled()}>
            Cancel
          </Button>,
        ]}
      >
        <DatePicker
          showTime={showTime}
          value={newValue}
          onChange={(value) => {
            setNewValue(showTime ? value : dayjs(dayjs(value).format('YYYY-MM-DD')).utc(true).startOf('day'));
          }}
          style={{ width: '100%' }}
        />
      </Modal>
      <PropertyItem label={field.field} value={field.value ? dayjs(field.value).format(showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD') : null} onClick={true !== alwaysReadOnly && true !== determineIsWidgetReadOnly(widgetId) ? handlePropertyItemOnClick : null} labelExtras={getLabelExtras()} isHighlighted={null !== getLabelExtras()} />
    </>
  );
};

CustomFieldDateTime.propTypes = {
  ticketId: PropTypes.string,
  widgetId: PropTypes.string,
  field: PropTypes.object,
  onUpdate: PropTypes.func,
  showTime: PropTypes.bool,
  alwaysReadOnly: PropTypes.bool,
};

export default CustomFieldDateTime;
