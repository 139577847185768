import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import useDictionary from 'src/hooks/useDictionary';
import PropTypes from 'prop-types';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';

const RegionPicker = ({ defaultValue, onChange, allowClear, mode, size, width = '100px', placeholder = 'Select a region...' }) => {
  const regionListRaw = useDictionary('teacket-region');
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!regionListRaw) return;
    if (options) return;
    getSelectOptions(regionListRaw);
  }, [regionListRaw]);

  useEffect(() => {
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [options]);

  const getSelectOptions = (list) => {
    if (true === notArrayOrEmpty(list)) return [];
    let o = list.map((item) => {
      return {
        value: item.key,
        label: item.valueStr,
      };
    });
    setOptions(o);
  };

  const handleOnChange = (value) => {
    console.log('handleOnChange()', value);
    setSelected(value);
    if (!onChange) return;

    //#region "handle clear"
    if (!value) {
      onChange(null);
      return;
    }
    //#endregion

    //#region "handle the multiple mode"
    if (Array.isArray(value)) {
      onChange(
        value.map((v) => {
          const item = regionListRaw.find((x) => x.key === v);
          return {
            key: item.key,
            value: item.valueStr,
            options: item.valueJson,
          };
        })
      );
      return;
    }
    //#endregion

    //#region "handle the single mode"
    const item = regionListRaw.find((x) => x.key === value);
    onChange({
      key: item.key,
      value: item.valueStr,
      options: item.valueJson,
    });
    //#endregion
  };

  return (
    <Select
      placeholder={placeholder}
      options={options}
      value={selected}
      size={size}
      style={{ width }}
      onChange={handleOnChange}
      loading={!options}
      allowClear={allowClear}
      mode={mode}
      showSearch
      optionRender={({ value }) => {
        const item = regionListRaw.find((x) => x.key === value);
        if (!item) return null;
        return (
          <div className='flex flex-col'>
            <div className='overflow-hidden font-bold'>{value}</div>
            {true === notEmptyArray(item?.valueJson) && (
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                {item?.valueJson?.map((x) => (
                  <div>{x.toString()}</div>
                ))}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

RegionPicker.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  mode: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
};

export default RegionPicker;
