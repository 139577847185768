import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, DatePicker, Form, Input } from 'antd';
import * as dayjs from 'dayjs';

const TicketCreateCompletionDates = ({ form }) => {
  const NEED_COMPLETED_BY = 'values.Completion Dates.Need completed by.DateTime';
  const JUSTIFICATION = 'values.Completion Dates.Justification.String';

  const [showJustification, setShowJustification] = useState(false);

  const handleNeedCompletedByOnChange = (date) => {
    //console.log('handleNeedCompletedByOnChange', date);
    if (!date || dayjs(date).isAfter(dayjs().add(2, 'days'))) {
      setShowJustification(false);
      form.setFieldsValue({ [JUSTIFICATION]: undefined });
      return;
    }
    setShowJustification(true);
  };

  return (
    <div className='w-full flex flex-row gap-4'>
      <div className='flex-initial'>
        <Form.Item label='Need completed by' name={NEED_COMPLETED_BY} rules={[{ required: true, message: 'This field is required' }]}>
          <DatePicker className='w-48' onChange={handleNeedCompletedByOnChange} minDate={dayjs()} />
        </Form.Item>
      </div>

      {showJustification && (
        <div className='flex-auto flex flex-col gap-2'>
          <Alert message='The CoE SPC team have a minimum turnaround time of 2 business days.' description='Shorter timeframes are not always possible to meet and are dependent on the type of request and current staffing levels. Please enter a reason as to why you need this in less than 2 days:' type='warning' showIcon />
          <Form.Item label='Justification' name={JUSTIFICATION} rules={[{ required: true, message: 'This field is required' }]}>
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} maxLength={250} showCount />
          </Form.Item>
        </div>
      )}
    </div>
  );
};

TicketCreateCompletionDates.propTypes = {
  form: PropTypes.any,
};

export default TicketCreateCompletionDates;
