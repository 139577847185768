import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, message, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Droppable from 'src/components/controls/Droppable';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import { bytesToSize, numberSorter, textSorter } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';
import { notEmptyArray, notArrayOrEmpty } from 'src/misc/Misc';

var fileList1 = null;
const TicketCreateSharepointFiles = ({ onChange }) => {
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Name',
      key: 'Name',
      render: (record) => {
        return (
          <div>
            <div
              className='cursor-pointer'
              style={{ maxWidth: '400px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'left', color: theme.primary }}
              onClick={() => {
                window.open(record.webUrl, '_blank');
              }}
            >
              <strong>{record.name}</strong>
            </div>
          </div>
        );
      },
      sorter: (a, b) => textSorter(a, b, 'name'),
    },
    {
      title: 'Size',
      key: 'Size',
      align: 'right',
      width: 100,
      render: (record) => <>{bytesToSize(record.size ?? 0)}</>,
      sorter: (a, b) => numberSorter(a, b, 'size'),
    },
    {
      title: '',
      key: 'Btns',
      align: 'right',
      width: 40,
      render: (record) => (
        <>
          <Tooltip title='Remove from the list'>
            <Button
              type='link'
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setFileList(fileList1.filter((x) => x.name !== record.name));
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const [fileList, setFileList] = useState(null);

  useEffect(() => {
    fileList1 = fileList;
    if (onChange) onChange(fileList);
  }, [fileList]);

  const handleOnDrop = (e) => {
    try {
      if (e.length !== 1) throw new Error('You can drop only one file at a time!');
      const fl = true === notArrayOrEmpty(fileList1) ? [] : [...fileList1];
      if (notEmptyArray(fl) && fl.some((x) => x.name.toLowerCase() === e[0].name.toLowerCase())) throw new Error('File with this name already exists!');
      fl.push(e[0]);
      setFileList(fl);
    } catch (error) {
      console.error(error);
      messageApi.open({ type: 'error', content: error.toString() });
    }
  };

  //if (loading) return <SkeletonTable label={loading} />;

  return (
    <>
      {contextHolder}
      <Droppable onDrop={handleOnDrop}>
        <div className='flex flex-col gap-2'>
          <Alert message='Drop a file here if you want to attach it to your request.' type='info' showIcon className='px-2 py-1' />
          <CondensedTableLegacy columns={columns} dataSource={fileList} pagination={{ size: 'small' }} noDataMessage='No Files' />
        </div>
      </Droppable>
    </>
  );
};

TicketCreateSharepointFiles.propTypes = {
  onChange: PropTypes.func,
};

export default TicketCreateSharepointFiles;
