import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';

const StylesWrapper = styled.div`
  .droppable-wrapper-no-dropping {
    position: relative;
    opacity: 1;
    border: none;
    border: 1px solid transparent;
  }

  .droppable-wrapper-dropping {
    position: relative;
    opacity: 0.5;
    border: 1px dashed ${(props) => props.theme.primary};
    border-radius: 0.5rem;
    overflow: hidden;
  }
`;

const Droppable = ({ onDrop, children, droppable = true }) => {
  const { theme } = useTheme();
  const dropRef = useRef(null);
  const [drag, setDrag] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    if (!droppable) return;
    let div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, [droppable]);

  if (!droppable) {
    return <>{children}</>;
  }

  return (
    <StylesWrapper theme={theme}>
      <div ref={dropRef} className={drag ? 'droppable-wrapper-dropping' : 'droppable-wrapper-no-dropping'}>
        {children}
      </div>
    </StylesWrapper>
  );
};

Droppable.propTypes = {
  children: PropTypes.any.isRequired,
  onDrop: PropTypes.func.isRequired,
  droppable: PropTypes.bool,
};

export default Droppable;
