import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useScreen from 'src/hooks/useScreen';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Space, Table, Tooltip, message, Modal, InputNumber } from 'antd';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { getQuestions, postQuestion, patchQuestion } from 'src/api/fsireport';
import QuestionEditor from './Components/QuestionEditor/QuestionEditor';
import { TbReportMoney } from 'react-icons/tb';
import queryString from 'query-string';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import QuestionCreate from './Components/QuestionCreate';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
import { uuid } from 'short-uuid';

const FSIReport = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { isDesktop } = useScreen();
  const { questionId } = queryString.parse(window.location.search);
  const { executeAsyncProcess } = useAsyncProcesses();
  const { addNewCreatedQuestion } = useMasterAdmin();
  const [messageApi, contextHolder] = message.useMessage();

  const truncateString = (str, num) => {
    if (!str) return null;
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + '...';
  };

  const columns = [
    {
      title: 'Id',
      key: 'question_id',
      width: 320,
      hidden: true,
      render: (record) => <>{record.question_id}</>,
    },
    {
      title: 'Order',
      key: 'order',
      width: 60,
      align: 'center',
      render: (record) => (
        <Tooltip title='Click to edit' mouseEnterDelay={1}>
          <div
            className='cursor-pointer hover:font-bold'
            onClick={() => {
              setEditOrder(record);
            }}>
            {record.order ?? 'n/a'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Question',
      key: 'questions',
      render: (record) => {
        const englishQuestion = record.questions.find((q) => q.language === 'en');
        if (!englishQuestion) return null;
        return truncateString(englishQuestion.question, 75);
      },
    },
  ];

  const [layoutHorizontal] = useState(isDesktop);
  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newQuestionOpen, setNewQuestionOpen] = useState(false);
  const [editOrder, setEditOrder] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'FSI Report',
      },
    ]);

    loadData();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = (await getQuestions())?.sort((a, b) => {
          const orderA = a.order ?? 0;
          const orderB = b.order ?? 0;
          return orderA < orderB ? -1 : orderA > orderB ? 1 : 0;
        });
        setQuestions(resp);
      } catch (error) {
        if (error?.toString().includes('The user aborted the request')) return;
        console.log(error);
      }
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedQuestion(selectedRowKeys[0] ?? null);
    },
    selectedRowKeys: [selectedQuestion],
    type: 'radio',
  };

  const handleOnRow = (record) => {
    return {
      onClick: () => {
        setSelectedQuestion(record.id);
      },
    };
  };

  const handleQuestionCreateOnSubmit = (values) => {
    console.log('handleQuestionCreateOnSubmit', values);
    setNewQuestionOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postQuestion(values);
        console.log('resp', resp);
        addNewCreatedQuestion(resp.question);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data saved',
          duration: 3,
        });
        loadData();
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      {contextHolder}
      {editOrder && (
        <Modal
          title={'Update the order'}
          open={true}
          onCancel={() => {
            setEditOrder(null);
          }}
          onOk={async () => {
            await executeAsyncProcess(async () => {
              try {
                setEditOrder(null);
                const resp = await patchQuestion(editOrder.question_id, { order: editOrder.order });
                console.log('resp', resp);
                messageApi.open({
                  key: uuid(),
                  type: 'success',
                  content: 'Order updated',
                  duration: 3,
                });
                loadData();
              } catch (error) {
                console.log(error);
              }
            });
          }}>
          <InputNumber
            value={editOrder.order}
            min={1}
            max={questions.length}
            onChange={(v) => {
              setEditOrder((prev) => ({ ...prev, order: v }));
            }}
          />
        </Modal>
      )}
      <QuestionCreate open={newQuestionOpen} onClose={() => setNewQuestionOpen(false)} onSubmit={handleQuestionCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <TbReportMoney />
            FSI
            <span className='font-extralight'>Report</span>
          </Space>
        }
        afterTitleExtras={
          <>
            {!questionId && (
              <StyledButton color={theme.textBase} onClick={() => setNewQuestionOpen(true)} size='small'>
                <Space className='btn'>
                  <FaPlus />
                  <WhenDesktop>Create Question</WhenDesktop>
                </Space>
              </StyledButton>
            )}
          </>
        }
      />
      <div className='p-4 h-full w-full'>
        <Splitter direction={layoutHorizontal ? SplitDirection.Horizontal : SplitDirection.Vertical}>
          <div className={'overflow-scroll h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            <Table
              size='small'
              pagination={{
                pageSize: 20,
              }}
              columns={columns}
              dataSource={questions}
              rowKey={'question_id'}
              rowSelection={rowSelection}
              onRow={handleOnRow}
            />
          </div>
          <div className={'overflow-scroll h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            <QuestionEditor questionId={selectedQuestion} onSubmit={loadData} />
          </div>
        </Splitter>
      </div>
    </PermissionsProvider>
  );
};

// Teacket.propTypes = {};

export default FSIReport;
