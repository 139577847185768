import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Space, Input, Radio, message, Table, Select, Button, Alert, Checkbox } from 'antd';
import { getSubjects, postRequest } from 'src/api/gdpr';
import { uuid } from 'short-uuid';
import { FaFileDownload } from 'react-icons/fa';

//import SectionCreate from './Components/SectionCreate';

const { Option } = Select;

const GDPR = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { isDarkMode } = useTheme();
  const searchTypeList = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
  ];

  const requestTypeList = [
    { value: 'GDPR_EXPORT', label: 'Export', icon: 'ion-android-download' },
    { value: 'GDPR_UPDATE', label: 'Update', icon: 'ion-edit' },
    { value: 'GDPR_DELETE', label: 'Delete', icon: 'ion-trash-a' },
  ];

  const replacementTypeList = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
  ];

  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState(null);
  const [selectedSearchType, setSelectedSearchType] = useState(searchTypeList[0].value);
  const [searchPhrase, setSearchPrase] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [subject, setSubject] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [requestType, setRequestType] = useState('GDPR_EXPORT');
  const [replacementType, setReplacementType] = useState(replacementTypeList[0].value);
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'User Id',
      dataIndex: 'userid',
      key: 'userid',
      ellipsis: true,
    },
  ];

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'GDPR Requests',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const replacementSelect = (
    <Select
      style={{ width: '120px' }}
      value={replacementType}
      onSelect={(v) => {
        if (v === 'name') {
          setOldValue(subject?.name ?? '');
          setNewValue('');
        } else {
          setOldValue(subject?.email ?? '');
          setNewValue('');
        }
        setReplacementType(v);
      }}>
      {replacementTypeList.map((x) => (
        <Option key={x.value} value={x.value}>
          {x.label}
        </Option>
      ))}
    </Select>
  );

  const handleOnSearch = async (v) => {
    console.log('handleOnSearch()', v);
    setSubjects(null);
    setSubject(null);
    setRequestType('GDPR_EXPORT');

    const searchPhraseTrimmed = v?.trim() ?? '';

    console.log('searchPhraseTrimmed:', searchPhraseTrimmed);
    if (searchPhraseTrimmed.length < 3) return;
    setLoading(true);
    try {
      const resp = await getSubjects(selectedSearchType, searchPhraseTrimmed);

      if (resp && resp.length === 0) {
        messageApi.open({
          type: 'info',
          content: 'No Subjects found.',
          duration: 5,
        });
      }

      // Ensure each entity has a unique 'id'
      const entitiesWithId = resp.map((x) => ({
        ...x,
        id: x.id || uuid(), // Assign UUID only if `id` is not already present
      }));

      setSubjects(entitiesWithId);
    } catch (error) {
      console.error(error);
      message.error(error.toString());
    }
    setLoading(false);
  };

  const submitRequest = async () => {
    console.log('submitRequest()');
    const key = uuid();
    console.log('key:', key);

    await executeAsyncProcess(async () => {
      try {
        const body = {
          requestType,
          subject: {
            userId: subject?.userid,
          },
        };

        if (requestType === 'GDPR_UPDATE') {
          body.replacementTerms = [
            {
              type: replacementType,
              updatedValue: newValue,
            },
          ];
        }
        console.log('body:', body);

        messageApi.open({
          key,
          type: 'loading',
          content: 'Processing GDPR request...',
          duration: 0,
        });

        const resp = await postRequest(body);
        console.log('resp:', resp);

        messageApi.open({
          key,
          type: 'success',
          content: 'GDPR request submitted',
          duration: 5,
        });

        if (resp && requestType === 'GDPR_EXPORT') {
          // Convert the response to a JSON string
          const jsonString = JSON.stringify(resp);

          // Create a Blob from the JSON string
          const blob = new Blob([jsonString], { type: 'application/json' });

          // Get the current date and time
          const now = new Date();
          const timestamp = now.toISOString().replace(/[:.]/g, '-'); // Format: YYYY-MM-DDTHH-MM-SS

          // Create a temporary anchor element and use it to download the file
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `${subject?.userid}-export-${timestamp}.json`; // Set the file name
          document.body.appendChild(a);
          a.click(); // Trigger the download
          document.body.removeChild(a); // Clean up
          window.URL.revokeObjectURL(url); // Release memory
        }
      } catch (error) {
        console.error(error);

        messageApi.open({
          key,
          type: 'error',
          content: 'Failed to submit GDPR request.',
          duration: 3,
        });
      }
    });
  };

  const deleteMsgDetails = (
    <div>
      <Space className='w-full justify-between items-center'>
        <div>Please be aware that a deleted user will no longer have the ability to log in.</div>

        <Checkbox onChange={(e) => setDeleteConfirmed(!deleteConfirmed)} checked={deleteConfirmed}>
          Yes, I'm sure
        </Checkbox>
      </Space>
    </div>
  );

  return (
    <PermissionsProvider requiredPermissions={['features.gdpr']}>
      {contextHolder}
      <TitleBar
        title={
          <Space>
            <FaFileDownload />
            GDPR Requests
          </Space>
        }
      />

      <div className='flex p-4'>
        {/* <!-- Left section: AntD Input and Table --> */}
        <div className={`w-1/2 border p-4 rounded-lg shadow-sm ${isDarkMode ? ' bg-gray-800' : 'bg-gray-100'}`}>
          <div className='font-bold text-xl'>1. Find your Subject</div>
          <div className='font-extralight mb-4'>Search will be performed only against your current organization.</div>

          <Radio.Group
            value={selectedSearchType}
            size='small'
            style={{ marginBottom: 16 }}
            onChange={(e) => {
              setSelectedSearchType(e.target.value);
            }}>
            {searchTypeList.map((x) => (
              <Radio.Button key={x.value} value={x.value}>
                {x.label}
              </Radio.Button>
            ))}
          </Radio.Group>

          <Input.Search
            autoFocus
            loading={loading}
            placeholder='Type a searching phrase...'
            onSearch={handleOnSearch}
            value={searchPhrase}
            onChange={(e) => {
              setSearchPrase(e.target.value);
            }}
            enterButton
            className='pb-2'
          />

          <Table
            className='mt-4'
            size='small'
            rowSelection={{
              type: 'radio',
              selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                console.log('onChange():', selectedRows);
                if (selectedRows.length > 0) {
                  setSubject(selectedRows[0]);
                  setSelectedRowKeys([selectedRows[0].id]);
                } else {
                  setSubject(null);
                  setSelectedRowKeys([]);
                }
              },
            }}
            onRow={(record) => ({
              onClick: () => {
                console.log('onRow.onClick():', record);
                setSubject(record);
                setSelectedRowKeys([record.id]);
              },
            })}
            columns={columns}
            rowKey={(record) => record.id} // Ensure the rowKey is unique and based on `record.id`
            dataSource={subjects}
          />

          {/* Request Type */}

          <div className={`mt-2 ${subject ? '' : 'disabled'}    `}>
            <div className='font-bold text-xl'>2. Select your request type</div>
            <div className='font-extralight'>
              <div className='mt-3'>
                <>
                  <div>
                    <Radio.Group
                      value={requestType}
                      size='small'
                      style={{ marginBottom: 12 }}
                      onChange={(e) => {
                        setRequestType(e.target.value);
                        if (e.target.value === 'GDPR_UPDATE') {
                          setOldValue(subject?.name ?? '');
                          setNewValue('');
                        }
                        if (e.target.value === 'GDPR_DELETE') {
                          setDeleteConfirmed(false);
                        }
                      }}>
                      {requestTypeList.map((x) => (
                        <Radio.Button key={x.value} value={x.value}>
                          <i className={`${x.icon} btn-margin-right`} />
                          {x.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>

                  {/* <GDPR_EXPORT> */}
                  {requestType === 'GDPR_EXPORT' && (
                    <div style={{ marginBottom: 12 }}>
                      <div style={{ marginBottom: 12 }}>
                        <div>The request will export all the data related to the selected subject.</div>
                      </div>
                    </div>
                  )}
                  {/* </GDPR_EXPORT> */}

                  {/* <GDPR_UPDATE> */}
                  {requestType === 'GDPR_UPDATE' && (
                    <div style={{ marginBottom: 12 }}>
                      <div style={{ marginBottom: 12 }}>
                        <div>The request will update the subject data. Please enter the update details below:</div>
                      </div>
                      <div style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          addonAfter={replacementSelect}
                          autoComplete='off'
                          style={{ maxWidth: '400px' }}
                          placeholder='Enter old value...'
                          value={oldValue}
                          onChange={(e) => {
                            setOldValue(e.target.value);
                          }}
                          onBlur={() => {
                            if (oldValue) setOldValue(oldValue.trim());
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: 12 }}>
                        <Input
                          autoComplete='off'
                          style={{ maxWidth: '400px' }}
                          placeholder='Enter new value...'
                          value={newValue}
                          onChange={(e) => {
                            setNewValue(e.target.value);
                          }}
                          onBlur={() => {
                            if (newValue) setNewValue(newValue.trim());
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* </GDPR_UPDATE> */}

                  {/* <GDPR_DELETE> */}
                  {requestType === 'GDPR_DELETE' && (
                    <div style={{ marginBottom: 12 }}>
                      <div style={{ marginBottom: 12 }}>
                        <div>The request will delete (or anonymize) the subject data.</div>
                      </div>
                    </div>
                  )}
                  {/* </GDPR_DELETE> */}
                </>
              </div>
            </div>
          </div>
          <Alert message={deleteMsgDetails} type='warning' showIcon className={`mt-2 mb-4 ${requestType === 'GDPR_DELETE' && subject ? '' : 'hidden'}`}></Alert>

          <div className='text-right'>
            <Button type='primary' disabled={!subject || (subject && requestType === 'GDPR_UPDATE' && !newValue) || (requestType === 'GDPR_DELETE' && !deleteConfirmed)} onClick={submitRequest}>
              Submit Request
            </Button>
          </div>
        </div>
        {/* <!-- Right section: SectionCreate --> */}
        <div className='w-1/2 pl-4'>
          <div className={`border border-gray-300 p-4 rounded-lg shadow-sm ${isDarkMode ? ' bg-gray-800' : 'bg-slate-100'}`}>
            <h3 className='text-lg font-semibold'>About</h3>
            <p className='mt-2 font-extralight'>
              This section allows users to easily manage GDPR (General Data Protection Regulation) requests, such as exporting, deleting, or updating personal data. The purpose of this Section is to simplify the process of handling data subject requests by providing an intuitive interface where users can submit and manage requests in compliance with GDPR. This ensures that organizations can meet the regulatory requirements of protecting user privacy and responding to requests for data access,
              correction, or removal in a streamlined manner.
            </p>
          </div>
        </div>
      </div>
    </PermissionsProvider>
  );
};

export default GDPR;
