import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const SubCategoryPicker = ({ options, defaultValue, onChange, allowClear = true, width = '100%' }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    handleOnChange(null);
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [options]);

  const handleOnChange = (value) => {
    console.log('handleOnChange()', value, options);
    setSelected(value);
    if (!onChange) return;

    if (!value) {
      onChange(null);
      return;
    }

    onChange(value);
  };

  return <Select placeholder='[ALL]' options={options} value={selected} style={{ width }} onChange={handleOnChange} allowClear={allowClear} showSearch />;
};

SubCategoryPicker.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  width: PropTypes.string,
};

export default SubCategoryPicker;
