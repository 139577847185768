import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const PropertySection = ({ children, color, bgColor }) => {
  const { theme } = useTheme();
  return (
    <div className='rounded-xl py-1 px-3' style={{ border: `1px solid ${color ?? theme.borderLight}`, backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

PropertySection.propTypes = {
  children: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

export default PropertySection;
