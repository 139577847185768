import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType1 from './BoxType1';
import { Button, Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import GraphMyTicketsActive from '../../Teacket/Components/GraphMyTicketsActive';

import PermissionsProvider from 'src/providers/PermissionsProvider';

const SectionSpeedTickets = () => {
  const navigate = useNavigate();

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create']} isControl>
      <BoxType1>
        <div className='flex flex-row gap-8'>
          <div className='flex flex-col justify-center'>
            <div>
              <div className='flex flex-row text-2xl'>
                <Space className='btn mr-4'>
                  <BsCupHotFill />
                  Tickets
                </Space>
                <div className='flex text-right mt-1.5 gap-3 ml-auto'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                      navigate('/teacket');
                    }}
                  >
                    <Space className='btn'>
                      Home<span className='font-bold'></span>
                      <BsCupHotFill />
                    </Space>
                  </Button>

                  <Button
                    size='small'
                    type='primary'
                    //className='bg-green-600'
                    onClick={() => {
                      navigate('/teacket/create-new');
                    }}
                  >
                    + Create new
                  </Button>
                </div>
              </div>
              <div className='mt-4'>
                <GraphMyTicketsActive hideFooter={false} />
              </div>
            </div>
          </div>
        </div>
      </BoxType1>
    </PermissionsProvider>
  );
};

SectionSpeedTickets.propTypes = {};

export default SectionSpeedTickets;
