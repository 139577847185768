import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Space, Result, message } from 'antd';
import Wizard from 'src/components/controls/Wizard/Wizard';
import { MdSecurity } from 'react-icons/md';
import DSRCustomWelcomeStep from '../WizardCustomSteps/DSRCustomWelcomeStep';
import DSRModeRealOrFakeStep from '../WizardCustomSteps/DSRModeRealOrFakeStep';
import DSRNameStep from '../WizardCustomSteps/DSRNameStep';
import DSRSearchSubjectStep from '../WizardCustomSteps/DSRSearchSubjectStep';
import DSRRequestTypeStep from '../WizardCustomSteps/DSRRequestTypeStep';
import DSRSummaryStep from '../WizardCustomSteps/DSRSummaryStep';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
//import useUserInfo from 'src/hooks/useUserInfo';
import { uuid } from 'short-uuid';
import { useNavigate } from 'react-router-dom';

var wizardCurrentStepGlobal = null;

const SetupRequestsWizard = ({ children }) => {
  const { ack, jobName, subject, requestType, wizard_UpdateOldValue, wizard_ReplacementValue, setJob, submitRequest } = useContext(DataSubjectRequestsContext);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { executeAsyncProcess } = useAsyncProcesses();

  //const userInfo = useUserInfo();

  const [wizardSteps, setWizardSteps] = useState(null);
  const [wizardCurrentStep, setWizardCurrentStep] = useState(0);

  useEffect(() => {
    setWizardSteps(defaultWizardSteps);
    setJob(null);
  }, []);

  useEffect(() => {
    wizardCurrentStepGlobal = wizardCurrentStep;
  }, [wizardCurrentStep]);

  useEffect(() => {
    // Update wizard steps when items that are required by the enabled property in steps change
    setWizardSteps(defaultWizardSteps);
    console.log('acknowledge status:', ack);
    console.log('jobName:', jobName);
    console.log('subject:', subject);
    console.log('requestType:', requestType);
  }, [ack, jobName, subject, requestType]);

  const defaultWizardSteps = [
    {
      title: 'Presentation',
      header: 'Presentation',
      content: <DSRCustomWelcomeStep />,
      allowBack: false,
      allowNext: ack,
    },
    {
      title: 'Job Name',
      header: 'Job Name',
      allowBack: true,
      allowNext: jobName?.length > 0,
      content: <DSRNameStep />,
    },
    {
      title: 'Real or Fake?',
      header: 'Real or Fake?',
      allowBack: true,
      content: <DSRModeRealOrFakeStep />,
    },
    {
      title: 'Search Subject',
      header: 'Search Subject',
      allowBack: true,
      allowNext: subject !== null,
      content: <DSRSearchSubjectStep />,
    },
    {
      title: 'Request Type',
      header: 'Request Type',
      allowBack: true,
      allowNext: requestType !== null && requestType === 'GDPR_UPDATE' ? wizard_UpdateOldValue && wizard_ReplacementValue : true,
      content: <DSRRequestTypeStep />,
    },
    {
      title: 'Summary',
      header: 'Summary',
      allowBack: true,
      allowNext: true,
      content: <DSRSummaryStep />,
      onNext: async () => {
        await executeAsyncProcess(async () => {
          try {
            await submitRequest();
            messageApi.open({
              key: uuid(),
              type: 'success',
              content: 'Job created',
              duration: 3,
            });
          } catch (error) {
            console.log(error);
            messageApi.open({
              key: uuid(),
              type: 'error',
              content: 'An error occurred while creating the GDPR Job',
              duration: 3,
            });
          }
        });
      },
    },
    {
      title: 'Done',
      allowBack: true,
      header: (
        <Space className='btn'>
          <MdSecurity />
          Data Subject Requests
        </Space>
      ),
      content: <div className='w-full h-full flex flex-col justify-center items-center gap-2'>{<Result status='success' title='Setup is now complete.' subTitle='Click on Finish to create your job.' />}</div>,
    },
  ];

  //#region "Handle Wizard"

  const handleWizardOnNext = async () => {
    const step = wizardSteps[wizardCurrentStepGlobal];
    if (step.onNext) {
      await step.onNext();
    }

    setWizardCurrentStep(wizardCurrentStepGlobal + 1);
  };

  const handleWizardOnPrev = () => {
    setWizardCurrentStep(wizardCurrentStepGlobal - 1);
  };

  const handleWizardOnFinish = () => {
    navigate('/data-subject-requests');
  };

  const handleWizardOnAbort = () => {
    navigate('/data-subject-requests');
  };

  //#endregion

  if (wizardSteps) {
    return (
      <>
        {contextHolder}
        <Wizard steps={wizardSteps} currentStep={wizardCurrentStep} onNext={handleWizardOnNext} onPrev={handleWizardOnPrev} onFinish={handleWizardOnFinish} onAbort={handleWizardOnAbort} />
      </>
    );
  }

  return children;
};

SetupRequestsWizard.propTypes = {
  children: PropTypes.node,
  appId: PropTypes.string,
  welcomeStep: PropTypes.object,
  setupStepList: PropTypes.array,
  showSetupEvenIfClientExists: PropTypes.bool,
};

export default SetupRequestsWizard;
