import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DashboardTickets from './DashboardTickets';
import ClosedByWrapUpCode from './ClosedByWrapUpCode';
import OpenedClosedRemaining from './OpenedClosedRemaining';
import ByRegion from './ByRegion';
import ByProduct from './ByProduct';
import BySegment from './BySegment';
import ByCustomerType from './ByCustomerType';
import ByDirectIndirectSale from './ByDirectIndirectSale';
import ByCategory from './ByCategory';
import ByRequesterDepartment from './ByRequesterDepartment';
import ByCategoryChart from './ByCategoryChart';
import BySubCategoryChart from './BySubCategoryChart';
import ByCategoryByMonthChart from './ByCategoryByMonthChart';
import Overdue from './Overdue';
//import PropTypes from 'prop-types';

const DashboardData = () => {
  const { setDashboardLayoutItemsSelector } = useContext(TeacketContext);

  const componentsGallery = [
    {
      id: 'By Region',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Region
              <div className='text-sm font-normal text-gray-500'>Distribution of tickets across regions</div>
            </div>
          }
          centered>
          <ByRegion />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Product',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Product
              <div className='text-sm font-normal text-gray-500'>Ticket breakdown by product line</div>
            </div>
          }
          centered>
          <ByProduct />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Segment',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Segment
              <div className='text-sm font-normal text-gray-500'>Distribution across market segments</div>
            </div>
          }
          centered>
          <BySegment />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Number of tickets',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Number of tickets
              <div className='text-sm font-normal text-gray-500'>Total count of tickets in the system</div>
            </div>
          }
          centered>
          <DashboardTickets />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opened / Closed / Remaining',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Opened / Closed / Remaining
              <div className='text-sm font-normal text-gray-500'>Opened, closed and remaining tickets</div>
            </div>
          }
          centered>
          <OpenedClosedRemaining />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Overdue',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Overdue
              <div className='text-sm font-normal text-gray-500'>Tickets with a closed date past the forecasted date</div>
            </div>
          }
          centered>
          <Overdue />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Categories / Month',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Categories / Month
              <div className='text-sm font-normal text-gray-500'>Tickets split by categories per month</div>
            </div>
          }
          centered>
          <ByCategoryByMonthChart />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Split By Categories',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Split By Categories
              <div className='text-sm font-normal text-gray-500'>Tickets split by categories</div>
            </div>
          }
          centered>
          <ByCategoryChart />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Split By Subcategories',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Split By Subcategories
              <div className='text-sm font-normal text-gray-500'>Tickets split by subcategories</div>
            </div>
          }
          centered>
          <BySubCategoryChart />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Customer Type',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Customer Type
              <div className='text-sm font-normal text-gray-500'>Tickets by customer type</div>
            </div>
          }
          centered>
          <ByCustomerType />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Direct/Indirect Sale',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Direct/Indirect Sale
              <div className='text-sm font-normal text-gray-500'>Analysis of sales channel distribution</div>
            </div>
          }
          centered>
          <ByDirectIndirectSale />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Category',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Category
              <div className='text-sm font-normal text-gray-500'>Ticket distribution across categories</div>
            </div>
          }
          centered>
          <ByCategory />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'By Requester Department',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              By Requester Department
              <div className='text-sm font-normal text-gray-500'>Breakdown by requester's department</div>
            </div>
          }
          centered>
          <ByRequesterDepartment />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Closed By Wrap Up Code',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <div>
              Closed By Wrap Up Code
              <div className='text-sm font-normal text-gray-500'>Analysis of ticket closure reasons</div>
            </div>
          }
          centered>
          <ClosedByWrapUpCode />
        </DraggableLayoutItem>
      ),
    },
  ];

  return (
    <div className='p-2'>
      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setDashboardLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketDashboard} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketDashboardHidden} />
    </div>
  );
};

DashboardData.propTypes = {};

export default DashboardData;
