import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { ChartsLegend, ResponsiveChartContainer, PiePlot, ChartsTooltip } from '@mui/x-charts';
import NoData from 'src/components/layout/NoData';
import MuiTheme from 'src/misc/MuiTheme';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';
import { IconButton, Dialog, DialogContent } from '@mui/material';
import { AiOutlineFullscreen, AiOutlineClose } from 'react-icons/ai';

const ByCategory = ({ height = 320 }) => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  const [data, setData] = useState(null);
  const [disabledItems, setDisabledItems] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const { byCategory } = dashboardDataTickets ?? {};
    console.log('byCategory', byCategory);
    if (!byCategory) return;

    const d = byCategory.map((item) => ({
      id: item.category,
      label: item.category,
      value: parseInt(item.count ?? 0),
    }));

    // If less than 3 categories, show all of them
    if (d.length < 3) {
      setData(d);
      return;
    }

    // Sort by value and split into main and other categories
    const sortedData = [...d].sort((a, b) => b.value - a.value);
    const mainCategories = sortedData.slice(0, 2);
    const smallCategories = sortedData.slice(2);

    // Calculate total for "Others"
    const othersTotal = smallCategories.reduce((sum, item) => sum + item.value, 0);

    // Create final data array
    const finalData = [
      ...mainCategories,
      {
        id: 'Others',
        label: 'Others',
        value: othersTotal,
      },
    ];

    setData(finalData);
  }, [dashboardDataTickets]);

  if (!data || data.length === 0) {
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );
  }

  const ChartContent = () => (
    <ResponsiveChartContainer
      skipAnimation={true}
      colors={chartColors}
      height={height}
      margin={{ top: 30, bottom: 0, left: 0, right: 0 }}
      series={[
        {
          type: 'pie',
          data: data.map((item) => ({
            ...item,
            value: disabledItems.has(item.label) ? 0 : item.value,
          })),
          innerRadius: 10,
          outerRadius: 100,
          highlightScope: {
            faded: 'global',
            highlighted: 'item',
          },
          faded: {
            innerRadius: 20,
            additionalRadius: -20,
            color: 'gray',
          },
          arcLabel: (item) => `${item.value}`,
        },
      ]}>
      <PiePlot />
      <ChartsTooltip trigger='item' labelFormatter={(params) => params.label} valueFormatter={(value) => `Count: ${value}`} />
      <ChartsLegend position={{ vertical: 'top', horizontal: 'middle' }} onItemClick={handleLegendClick} />
    </ResponsiveChartContainer>
  );

  ChartContent.propTypes = {
    height: PropTypes.number,
  };

  const handleLegendClick = (event, itemId) => {
    setDisabledItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemId.label)) {
        newSet.delete(itemId.label);
      } else {
        newSet.add(itemId.label);
      }
      return newSet;
    });
  };

  const chartColors = colors.categories.map((c) => {
    return isDarkMode ? c.light : c.dark;
  });

  const handleFullscreenClick = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <MuiTheme>
      <div style={{ position: 'relative', width: '100%', height: height, minHeight: '300px' }}>
        {/* Fullscreen Button */}
        <IconButton
          onClick={handleFullscreenClick}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
          }}>
          <AiOutlineFullscreen className='text-genesys-gray-500' />
        </IconButton>
        <ChartContent />
      </div>
      {/* Fullscreen Modal */}
      <Dialog fullWidth maxWidth='xl' open={isModalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}>
            <AiOutlineClose />
          </IconButton>
          <ChartContent />
        </DialogContent>
      </Dialog>
    </MuiTheme>
  );
};

ByCategory.propTypes = {
  height: PropTypes.number,
};

export default ByCategory;
