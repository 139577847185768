import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import { getAuthUserInfo } from 'src/api/auth';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from 'src/misc/Config';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import useUserInfo from 'src/hooks/useUserInfo';
import { objToQueryStringParams } from 'src/misc/Misc';
import { logout } from 'src/misc/Session';

const AuthProvider = ({ children }) => {
  const { setUserInfo } = useContext(GlobalContext);
  const userInfo = useUserInfo();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) return;

    if (window.location.pathname === '/' || window.location.pathname.startsWith('/security-qa-widget/') || window.location.pathname.startsWith('/reports/') || window.location.pathname.startsWith('/products/') || window.location.pathname.startsWith('/org-setup') || window.location.pathname.startsWith('/landing') || window.location.pathname.startsWith('/404') || window.location.pathname.startsWith('/403')) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.authTargetPath);
      setChecked(true);
      return;
    }

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checked) return;
    if (!userInfo) return;
  }, [userInfo, checked]);

  const checkAuth = async () => {
    try {
      console.debug('User is logged in');
      const userInfo = await getAuthUserInfo();
      setUserInfo(userInfo);
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.authTargetPath);
    } catch (error) {
      console.warn('User is not logged in:', error);

      if (error?.status === 403 && error?.error) {
        const query = { error: error.error, error_description: error?.error_description ?? undefined };
        logout(null, `${'/signinerror'}${objToQueryStringParams(query)}`);
        return;
      }

      localStorage.removeItem(LOCAL_STORAGE_KEYS.authAccessToken);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.authRefreshToken);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.authExpiresAt);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.authProvider);

      if (window.location.pathname !== '/signin') {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.authTargetPath, window.location.pathname);
      }

      setUserInfo(null);
    }

    setChecked(true);
  };

  if (!checked) return <FullScreenSpin title='Please wait' subtitle='Getting your information...' />;
  return <>{children}</>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
