import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType2 from './BoxType2';
import BoxType3 from './BoxType3';
import { Space, Button } from 'antd';
import { FaTools, FaCheckCircle } from 'react-icons/fa';
import { AiOutlineAudit } from 'react-icons/ai';
import { MdSecurity } from 'react-icons/md';
import { BsPersonRaisedHand } from 'react-icons/bs';
import { hasUserPermission } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';

const SectionSpcTools = () => {
  const navigate = useNavigate();
  const { permissions: userPermissions } = useUserInfo();

  const title = (
    <Space className='btn'>
      <FaTools />
      Security Tools
    </Space>
  );

  return (
    <BoxType2 title={title}>
      <div className='flex flex-row flex-wrap gap-6 justify-center'>
        {/* SECURITY ADVISOR */}
        <BoxType3
          title={
            <Space className='btn'>
              <MdSecurity />
              Security Advisor
            </Space>
          }
          footer={
            <div className='text-center'>
              <Button type='primary' onClick={() => navigate(hasUserPermission('apps.securityadvisor.view', userPermissions) ? '/security-advisor' : '/products/security-advisor')}>
                {hasUserPermission('apps.securityadvisor.view', userPermissions) ? 'Try now' : 'Learn More'}
              </Button>
            </div>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Genesys Cloud security optimization
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Security risk scheduled reports
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Real-time security alerts
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* DATA SUBJECT REQUESTS */}
        <BoxType3
          title={
            <Space className='btn'>
              <BsPersonRaisedHand />
              Data Subject Requests
            </Space>
          }
          footer={
            <div className='text-center'>
              <Button type='primary' onClick={() => navigate(hasUserPermission('apps.datasubjectrequests.view', userPermissions) ? '/data-subject-requests' : '/products/data-subject-requests')}>
                {hasUserPermission('apps.datasubjectrequests.view', userPermissions) ? 'Try now' : 'Learn More'}
              </Button>
            </div>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Undestand GDPR compliance with Genesys Cloud
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Manage GDPR requests
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                User-friendly interface
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* AUDIT VIEWER */}
        <BoxType3
          title={
            <Space className='btn'>
              <AiOutlineAudit />
              Audit viewer
            </Space>
          }
          footer={
            <div className='text-center'>
              <Button type='primary' onClick={() => navigate(hasUserPermission('apps.auditviewer.view', userPermissions) ? '/audit-viewer' : '/products/audit-viewer')}>
                {hasUserPermission('apps.auditviewer.view', userPermissions) ? 'Try now' : 'Learn More'}
              </Button>
            </div>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Insights into your Genesys Cloud organization activities
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* RECORDING CONSENT */}
        {/* <BoxType3
          title={
            <Space className='btn'>
              <IoRecording />
              Recording Consent (available soon)
            </Space>
          }
          footer={
            <div className='text-center'>
              <Button disabled type='primary' onClick={() => navigate(hasUserPermission('apps.recordingconsent.view', userPermissions) ? '/recording-consent' : '/products/recording-consent')}>
                {hasUserPermission('apps.recordingconsent.view', userPermissions) ? 'Try now' : 'Learn More'}
              </Button>
            </div>
          }>
          <div className=' overflow-hidden'>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Unsderstand how to implement recording consents in Genesys Cloud
              </Space>
            </div>
          </div>
        </BoxType3> */}
      </div>
    </BoxType2>
  );
};

SectionSpcTools.propTypes = {};

export default SectionSpcTools;
