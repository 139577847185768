import React, { useState, useEffect } from 'react';
import Wizard from 'src/components/controls/Wizard/Wizard';
import { Alert, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import DeploymentPicker from 'src/components/controls/DeploymentPicker/DeploymentPicker';
import { FaAws, FaHashtag, FaRegAddressCard } from 'react-icons/fa6';
import { TiDelete } from 'react-icons/ti';
import { getDistinct1, notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import RolePicker from 'src/components/controls/RolePicker/RolePicker';
import useUserInfo from 'src/hooks/useUserInfo';
import { gcRegionList } from 'src/misc/Config';

const InvitationLinkCreateNew = ({ onAbort, onFinish }) => {
  const [wizardCurrentStep, setWizardCurrentStep] = useState(0);
  const [selectedDeployment, setSelectedDeployment] = useState(null);
  const [selectedUserEmailsString, setSelectedUserEmailsString] = useState(null);
  const [selectedUserEmails, setSelectedUserEmails] = useState(null);
  const [selectedUserEmailsEditing, setSelectedUserEmailsEditing] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [resetRoleSelection, setResetRoleSelection] = useState(false);

  const userInfo = useUserInfo();

  useEffect(() => {
    if (resetRoleSelection) setResetRoleSelection(false);
  }, [resetRoleSelection]);

  useEffect(() => {
    console.log('selectedDeployment', selectedDeployment);
  }, [selectedDeployment]);

  useEffect(() => {
    if (!userInfo?.permissions?.includes('admin.master')) {
      // Set deployment to current org if user is not an admin user
      setSelectedDeployment({
        ACCOUNT_ID: null,
        ACCOUNT_NAME: null,
        ORG_ID: userInfo?.tenant_id,
        ORG_NAME: userInfo.tenant_name,
        ORG_REGION: gcRegionList.find((x) => x.baseUrl === userInfo.third_party_env).region,
      });
    }
  }, [userInfo]);

  //#region "EVENT HANDLERS"

  const handleDeploymentOnSelect = (e) => {
    console.log('handleDeploymentOnSelect', e);
    setSelectedDeployment(e);
  };

  const handleUserEmailsOnBlur = (e) => {
    console.log('handleUserEmailsOnBlur()', e.target.value);
    const valueStr = e.target.value.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gm, '\r\n').trim();
    if (valueStr.length < 1) {
      setSelectedUserEmails(null);
      setSelectedUserEmailsEditing(false);
      return;
    }
    let result = [];
    result = valueStr
      .split(/\r\n|\n|\r/)
      .map((x) => x.trim())
      .filter((x) => x.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(x));
    result = getDistinct1(result);
    setSelectedUserEmailsString(result.join('\r\n'));
    setSelectedUserEmails(result);
    setSelectedUserEmailsEditing(false);
  };

  const handleRolesOnPick = (e) => {
    console.log('handleRolesOnSelect', e);
    if (!e) return;
    let r = selectedRoles ? selectedRoles : [];
    if (!r.some((x) => x.role === e.role)) r.push(e);
    setSelectedRoles(r);
    setResetRoleSelection(true);
  };

  const handleRolesOnDelete = (e) => {
    console.log('handleRolesOnDelete', e);
    if (!e) return;
    if (true === notArrayOrEmpty(selectedRoles)) return;
    const r = selectedRoles.filter((x) => x.role !== e.role);
    setSelectedRoles(r);
  };

  const handleWizardOnFinish = () => {
    console.log('handleWizardOnFinish');
    if (!onFinish) return;
    onFinish({
      deployment: selectedDeployment,
      emails: selectedUserEmails,
      roles: selectedRoles,
    });
  };

  const handleWizardOnNext = () => setWizardCurrentStep(wizardCurrentStep + 1);
  const handleWizardOnPrev = () => setWizardCurrentStep(wizardCurrentStep - 1);

  //#endregion

  //#region "WIZARD STEPS"

  const deploymentStepContent = (
    <div className='min-h-[400px] flex flex-col gap-4'>
      <div>Choose a Genesys Cloud organization of users you want to invite.</div>
      <DeploymentPicker onSelect={handleDeploymentOnSelect} initialValue={selectedDeployment} />
      {selectedDeployment && (
        <div className='flex flex-col gap-4'>
          <div>
            <Space className='btn'>
              <FaHashtag />
              {selectedDeployment.ORG_ID}
            </Space>
          </div>
          <div>
            <Space className='btn'>
              <FaAws />
              {selectedDeployment.ORG_REGION}
            </Space>
          </div>
          <div>
            <Space className='btn'>
              <FaRegAddressCard />
              {selectedDeployment.ACCOUNT_NAME}
            </Space>
          </div>
        </div>
      )}
    </div>
  );

  const userEmailsStepContent = (
    <div className='min-h-[300px] flex flex-col gap-4'>
      <div>
        Provide <strong>email addresses</strong> of users you want to invite (one email per line).
      </div>
      <div>
        Make sure these email addresses belong to <strong>existing users</strong> in your Genesys Cloud organization.
      </div>
      {selectedUserEmailsEditing && <Alert type='info' message='Click outside the text-field to finish editing' showIcon />}
      <Input.TextArea placeholder='Enter user emails' value={selectedUserEmailsString} onChange={(x) => setSelectedUserEmailsString(x.target.value)} onFocus={() => setSelectedUserEmailsEditing(true)} onBlur={handleUserEmailsOnBlur} autoComplete='off' autoSize={{ minRows: 1, maxRows: 8 }} />
      {!selectedUserEmailsEditing && (
        <div>
          {selectedUserEmails?.length ?? 0} <span className='font-light opacity-60'>email addresses</span>
        </div>
      )}
    </div>
  );

  const rolesStepContent = (
    <div className='min-h-[300px] flex flex-col gap-4'>
      <div>Choose SPC roles you want to assign to invited users.</div>
      {!resetRoleSelection && <RolePicker onPick={handleRolesOnPick} width='100%' />}
      <div>
        <div className='mb-4'>
          {selectedRoles?.length ?? 0} <span className='font-light opacity-60'>{selectedRoles?.length === 1 ? 'role' : 'roles'}</span>
        </div>
        {true === notEmptyArray(selectedRoles) && (
          <div className='flex flex-col gap-2'>
            {selectedRoles.map((x) => (
              <div key={x.role}>
                <div>
                  <Space className='btn'>
                    {x.role}
                    <TiDelete
                      className='transition-all cursor-pointer opacity-60 hover:opacity-100 hover:scale-110'
                      onClick={() => {
                        handleRolesOnDelete(x);
                      }}
                    />
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60'>{x.description}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const summaryStepContent = (
    <div className='min-h-[300px] flex flex-col gap-4'>
      <div>
        <Alert
          type='info'
          message={
            <>
              Click the <strong>Finish</strong> button to create invitation links and send them out to provided email addresses.
            </>
          }
          showIcon
        />
      </div>
      <div>
        <span className='font-light opacity-60'>Deployment:</span> {selectedDeployment?.ORG_NAME} @ {selectedDeployment?.ORG_REGION}
      </div>
      <div>
        <span className='font-light opacity-60'>Emails count:</span> {selectedUserEmails?.length}
      </div>
      <div>
        <span className='font-light opacity-60'>Roles count:</span> {selectedRoles?.length}
      </div>
    </div>
  );

  let wizardSteps = [];

  // Do not allow non-master admins to select an org
  if (userInfo?.permissions?.includes('admin.master')) {
    wizardSteps.push({
      title: 'GC Organization',
      header: <Space className='btn'>GC Organization</Space>,
      content: deploymentStepContent,
      allowNext: selectedDeployment !== null,
    });
  }

  wizardSteps.push(
    {
      title: 'User emails',
      header: <Space className='btn'>User emails</Space>,
      content: userEmailsStepContent,
      allowNext: !selectedUserEmailsEditing && true === notEmptyArray(selectedUserEmails),
    },
    {
      title: 'Roles',
      header: <Space className='btn'>Roles</Space>,
      content: rolesStepContent,
      allowNext: true === notEmptyArray(selectedRoles),
    },
    {
      title: 'Summary',
      header: <Space className='btn'>Summary</Space>,
      content: summaryStepContent,
    }
  );

  //#endregion

  return <Wizard steps={wizardSteps} currentStep={wizardCurrentStep} onAbort={() => onAbort && onAbort()} onNext={handleWizardOnNext} onPrev={handleWizardOnPrev} onFinish={handleWizardOnFinish} />;
};

InvitationLinkCreateNew.propTypes = {
  onAbort: PropTypes.func,
  onFinish: PropTypes.func,
};

export default InvitationLinkCreateNew;
