import React, { useContext /*useEffect*/ } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import StakeholderDetails from './StakeholderDetails';
import { patchTicket, patchTicketProperties } from 'src/api/teacket';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

const Stakeholder = () => {
  const { currentTicket, setCurrentTicket } = useContext(TeacketContext);

  // useEffect(() => {
  //   if (currentTicket) {
  //     console.log('[Stakeholder] Current ticket', currentTicket);
  //   }
  // }, [currentTicket]);

  const handleTicketChange = async (changes) => {
    console.log('[Stakeholder] Ticket changes', JSON.stringify(changes, null, 2));

    if (currentTicket) {
      await patchTicket(currentTicket.id, changes);
      // console.log('Setting current ticket to', JSON.stringify({ ...currentTicket, ...changes }, null, 2));
      setCurrentTicket({ ...currentTicket, ...changes });
    }
  };

  const handleTicketPropertiesChange = async (properties) => {
    console.log('[Stakeholder] Updated properties', JSON.stringify(properties, null, 2));

    if (currentTicket) {
      await patchTicketProperties(currentTicket.id, properties);
      // console.log('=== Current ticket props:', JSON.stringify(currentTicket.props, null, 2));
      // console.log('=== With merged properties', JSON.stringify({ ...currentTicket, props: { ...currentTicket.props, ...properties } }, null, 2));
      setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...properties } });
    }
  };

  const handleOnWsMessageReceived = async (message) => {
    try {
      console.log('[Stakeholder] Message', message);
      if (!message?.data?.details) return;

      //handleTicketPropertiesChange(message.data.details);
      if (currentTicket) {
        setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...message?.data?.details } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED_PROPS']} />
      <StakeholderDetails ticket={currentTicket} onTicketChange={handleTicketChange} onPropertiesChange={handleTicketPropertiesChange} />
    </>
  );
};

export default Stakeholder;
