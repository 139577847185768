import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Alert } from 'antd';
import { getDistinct1, notArrayOrEmpty, areStringArraysSimilar } from 'src/misc/Misc';

const EmailList = ({ defaultValue, onChange }) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedUserEmailsString, setSelectedUserEmailsString] = useState(null);
  const [selectedUserEmails, setSelectedUserEmails] = useState(null);
  const [selectedUserEmailsOldVal, setSelectedUserEmailsOldVal] = useState(null);
  const [selectedUserEmailsEditing, setSelectedUserEmailsEditing] = useState(false);

  useEffect(() => {
    // console.log('defaultValue', defaultValue);
    if (initialized) return;
    if (true === notArrayOrEmpty(defaultValue)) defaultValue = [];
    let result = [];
    result = defaultValue.map((x) => x.trim()).filter((x) => x.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(x));
    result = getDistinct1(result);
    setSelectedUserEmailsString(result.join('\r\n'));
    setSelectedUserEmails(result);
    setSelectedUserEmailsOldVal(result);
    setSelectedUserEmailsEditing(false);
    setInitialized(true);
  }, [defaultValue]);

  useEffect(() => {
    // console.log('useEffect selectedUserEmails', selectedUserEmails, selectedUserEmailsOldVal, areStringArraysSimilar(selectedUserEmails, selectedUserEmailsOldVal));
    if (!initialized) return;
    if (!onChange) return;

    if (true === areStringArraysSimilar(selectedUserEmails, selectedUserEmailsOldVal)) {
      console.log('No changes in emails');
      return;
    }
    setSelectedUserEmailsOldVal(selectedUserEmails);
    onChange(selectedUserEmails);
  }, [selectedUserEmails]);

  const handleUserEmailsOnBlur = (e) => {
    // console.log('handleUserEmailsOnBlur()', e.target.value);
    const valueStr = e.target.value.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gm, '\r\n').trim();
    if (valueStr.length < 1) {
      setSelectedUserEmails(null);
      setSelectedUserEmailsEditing(false);
      return;
    }
    let result = [];
    result = valueStr
      .split(/\r\n|\n|\r/)
      .map((x) => x.trim())
      .filter((x) => x.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(x));
    result = getDistinct1(result);

    setSelectedUserEmailsString(result.join('\r\n'));
    setSelectedUserEmails(result);
    setSelectedUserEmailsEditing(false);
  };
  return (
    <div>
      {selectedUserEmailsEditing && <Alert className='py-0 px-1' type='info' message='Click outside the text-field to finish editing' showIcon />}
      <Input.TextArea placeholder='Enter emails (one per line)' value={selectedUserEmailsString} onChange={(x) => setSelectedUserEmailsString(x.target.value)} onFocus={() => setSelectedUserEmailsEditing(true)} onBlur={handleUserEmailsOnBlur} autoComplete='off' autoSize={{ minRows: 1, maxRows: 8 }} />
    </div>
  );
};

EmailList.propTypes = {
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
};

export default EmailList;
