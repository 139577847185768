import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Progress } from 'antd';

const getDistinctStageNames = (service) => {
  const stages = new Set();
  service?.props?.statusList?.forEach((status) => {
    if (status?.stage) {
      stages.add(status.stage);
    }
  });

  return stages.size;
};

const stageForStatus = (context, serviceId, status) => {
  const service = context?.servicesForTenant?.find((service) => service.id === serviceId);
  const defStatus = service?.props?.statusList?.find((s) => s.status === status);

  if (defStatus && defStatus?.stage && defStatus?.stageOrder) {
    const name = defStatus?.stage;
    const steps = getDistinctStageNames(service);
    const percentage = (defStatus?.stageOrder / steps) * 100;

    return { name, percentage, steps };
  } else return;
};

const TicketStage = ({ serviceId, status, scale = 75 }) => {
  const context = useContext(TeacketContext);
  const stageDetails = stageForStatus(context, serviceId, status);

  return (
    <>
      {stageDetails && (
        <div className={`scale-${scale} flex flex-row gap-2 items-center justify-center`}>
          <Progress percent={stageDetails.percentage} steps={stageDetails.steps} size={[10, 6]} showInfo={false} />
          <div className='text-xs font-light opacity-60'>{stageDetails.name}</div>
        </div>
      )}
    </>
  );
};

TicketStage.propTypes = {
  serviceId: PropTypes.string,
  status: PropTypes.string,
  scale: PropTypes.number,
};

export default TicketStage;
