import React from 'react';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { FaLink } from 'react-icons/fa6';

const CategoryUrls = ({ urlList, vertical = false }) => {
  if (true === notArrayOrEmpty(urlList)) return null;
  return (
    <div className={`flex ${vertical ? 'flex-col items-end' : 'flex-row gap-4 flex-wrap'}`}>
      {urlList.map((url, index) => (
        <div key={index}>
          <a href={url.url} target='_blank' rel='noreferrer' className='flex flex-row gap-1 items-center'>
            <FaLink />
            {url.displayName}
          </a>
        </div>
      ))}
    </div>
  );
};

CategoryUrls.propTypes = {
  urlList: PropTypes.array,
  vertical: PropTypes.bool,
};

export default CategoryUrls;
