import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { TfiFiles } from 'react-icons/tfi';
import { FaBars } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import { Button, Popover, Checkbox, Select } from 'antd';
//import PropTypes from 'prop-types';

const TicketSharepointFilesMenu = () => {
  const { currentTicketSharepointFilesFiltered, currentTicketSharepointFilesRequestIds, setCurrentTicketSharepointFilesRequestIds, currentTicketSharepointFilesFilterOptions, currentTicketSharepointFilesFilter, setCurrentTicketSharepointFilesFilter } = useContext(TeacketContext);

  return (
    <div className='text-sm'>
      <Space2>
        {currentTicketSharepointFilesFiltered?.length || currentTicketSharepointFilesFiltered?.length === 0 ? (
          <Space2 className='opacity-40'>
            <TfiFiles />
            <span>{currentTicketSharepointFilesFiltered?.length}</span>
            {/* <span className='opacity-40'>of</span>
            <span className='opacity-40'>{currentTicketSharepointFiles?.length}</span> */}
          </Space2>
        ) : null}
        <Popover
          trigger={['click']}
          placement='bottomRight'
          title='Advanced Options'
          content={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '300px' }}>
              <div>
                <Checkbox checked={currentTicketSharepointFilesRequestIds} onChange={() => setCurrentTicketSharepointFilesRequestIds(!currentTicketSharepointFilesRequestIds)}>
                  Display ticket IDs
                </Checkbox>
              </div>
              <div>
                <div className='smaller'>Show files from the folder:</div>
                <Select style={{ width: '100%' }} size='small' value={currentTicketSharepointFilesFilter} onChange={(e) => setCurrentTicketSharepointFilesFilter(e)}>
                  <Select.Option value={currentTicketSharepointFilesFilterOptions.this}>Assigned to this ticket only</Select.Option>
                  <Select.Option value={currentTicketSharepointFilesFilterOptions.thisAndUnassigned}>Assigned to this ticket and unassigned</Select.Option>
                  <Select.Option value={currentTicketSharepointFilesFilterOptions.all}>All files</Select.Option>
                </Select>
              </div>
            </div>
          }
        >
          <Button type='default' size='small'>
            <FaBars />
          </Button>
        </Popover>
      </Space2>
    </div>
  );
};

TicketSharepointFilesMenu.propTypes = {};

export default TicketSharepointFilesMenu;
