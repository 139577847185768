import React from 'react';
import styled from 'styled-components';
import { Skeleton, Spin } from 'antd';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const StyledSkeleton = styled.div`
  .ant-skeleton.ant-skeleton-element {
    width: 100% !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
  }
`;

const SkeletonTable = ({ rows = 6, label = null }) => {
  const { theme } = useTheme();
  return (
    <StyledSkeleton className='relative'>
      {label && (
        <div className='absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col gap-2 justify-center items-center' style={{ backgroundColor: theme.backgroundBase, opacity: 0.6 }}>
          <Spin />
          <div className='text-xs animate-pulse'>{label}</div>
        </div>
      )}
      <div className='relative flex flex-col gap-1 mt-1 items-center'>
        {[...Array(rows)].map((_, i) => (
          <Skeleton.Input key={i} active size={20} />
        ))}
      </div>
    </StyledSkeleton>
  );
};

SkeletonTable.propTypes = {
  rows: PropTypes.number,
  label: PropTypes.string,
};

export default SkeletonTable;
