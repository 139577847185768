import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { AiOutlineLogin } from 'react-icons/ai';
import { Alert, Space } from 'antd';
import styled from 'styled-components';
import { FcInvite } from 'react-icons/fc';
import { IoDocumentAttach } from 'react-icons/io5';

const StyledDiv = styled.div`
  li {
    margin-top: 0.5em;
  }
  li li {
    text-indent: 2.5em;
    margin-top: 0.5em;
  }
  li li li {
    text-indent: 5em;
    margin-top: 0.5em;
  }
  li li li li {
    text-indent: 7.5em;
    margin-top: 0.5em;
  }
`;

const GettingStarted = () => {
  return (
    <StyledDiv>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl p-4'>
          <div className='my-8 font-bold text-2xl text-blue-600 dark:text-blue-400'>
            <Space>
              <AiOutlineLogin />
              Getting Started with the SPC Portal
            </Space>
          </div>
          <div className='my-8 text-lg text-gray-800 dark:text-gray-200'>
            <p>
              Welcome to the SPC portal. To get started, you need to be <FcInvite /> <strong>invited</strong>.
            </p>
            <p>
              To get invited, please do the following
              <ul className='list-disc list-inside'>
                <li>
                  Download the <IoDocumentAttach />{' '}
                  <strong>
                    <a href='/documents/Trial Agreement.docx'>SPC Trial Agreement</a>
                  </strong>{' '}
                  document and get it signed by your signatory authority. <u>Access to this portal will not be granted without a signed copy of this agreement</u>.
                </li>
                <li>
                  Prepare the following information from your Genesys Cloud CX organization
                  <ul>
                    <li>
                      Your Organization Id (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx). <i>Get it from Admin/Organization Settings/Advanced.</i>
                    </li>
                    <li>Your Organization Name</li>
                    <li>
                      Your Organization environment/region (mypurecloud.com, mypurecloud.ie, etc.).{' '}
                      <a href='https://developer.genesys.cloud/platform/api/' target='_blank' rel='noreferrer'>
                        View all regions here
                      </a>
                    </li>
                    <li>Email address of one of your administrators</li>
                    <p>
                      <div className='bg-yellow-200 text-yellow-800 p-2 rounded mt-4'>
                        <p>
                          <strong>Important</strong>: The first user logging in must have an administrator role in Genesys Cloud <strong>in all divisions</strong>. If you are not an admin, ask an admin from your organization to log in first. The admin or master admin roles must have at least the following permission:
                        </p>
                        <div className='ml-2'>
                          <ul className='list-disc list-inside'>
                            <li>General/Admin (All Divisions)</li>
                          </ul>
                        </div>
                        <br />
                        <strong>Why?</strong> The SPC portal needs to create an OAuth client in your Genesys Cloud organization to be able to access its data and provide the services you need. The SPC Portal application will never write or change anything into your organization but will only read data to provide you with the services you need.
                      </div>
                    </p>
                  </ul>
                </li>
                <li>
                  Send the signed document and related organization information by email to
                  <ul>
                    <li>Your CSM if you are an existing customer</li>
                    <li>Your Account Executive or Solutions Consultant if you are a prospect</li>
                    <li>Your Partner Manager if you are a partner</li>
                  </ul>
                </li>
                <li>
                  Once the agreement is received and processed, <strong>the administrator will receive an email with an invitation link</strong> to our portal. Only this user will be allowed to add other users from your Genesys Cloud organization to our SPC portal.
                </li>
                <li>
                  When your administrator logs in for the first time, the <strong>SPC</strong> application will need to be approved. Follow these steps to approve the application
                  <div className='ml-2'>
                    <ul className='list-disc list-inside'>
                      <li>
                        In your Genesys Cloud instance, open <strong>Admin/Authorized Applications</strong> (in the Integrations section)
                      </li>
                      <li>
                        Open the <strong>SPC</strong> application and approve it. The permissions requested are read-only and will not change anything in your organization.
                      </li>
                      <div className='mt-2 mb-2'>
                        <Alert message='Some specific items do not have a read-only option however they will only be used by the SPC portal to read data.' type='info' showIcon />
                      </div>
                      <li>
                        It should look like this after being approved:
                        <div className='my-8'>
                          <div className='flex justify-center'>
                            <img src='/images/products/GettingStarted/SPCApplicationAuthorization.png' alt='Admin Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  Once the application is approved, the admin should be able to successfully login and follow these steps to invite other users from your Genesys Cloud organization (limited to 5 during the trial period):
                  <li>
                    Click on the <strong>Admin</strong> item in the left sidebar
                    <div className='my-8'>
                      <div className='flex justify-center'>
                        <img src='/images/products/GettingStarted/Admin.png' alt='Admin Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                      </div>
                    </div>
                  </li>
                  <li>
                    Click on the <strong>Invitation Links</strong> button
                    <div className='my-8'>
                      <div className='flex justify-center'>
                        <img src='/images/products/GettingStarted/InvitationLinksButton.png' alt='Invitation Links Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                      </div>
                    </div>
                  </li>
                  <li>
                    Click on the <strong>Create Invitation</strong> button on the top bar
                    <div className='my-8'>
                      <div className='flex justify-center'>
                        <img src='/images/products/GettingStarted/CreateInvitationButton.png' alt='Create Invitation Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                      </div>
                    </div>
                  </li>
                  <li>
                    Enter the email addresses that will be able to access SPC. <strong>Trials are limited to 5 users.</strong>
                    <div className='my-8'>
                      <div className='flex justify-center'>
                        <img src='/images/products/GettingStarted/UserEmails.png' alt='Enter at least one email' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                      </div>
                    </div>
                  </li>
                  <li>
                    Click on <strong>Next</strong>. This is where roles will be assigned to all of the email addresses listed in the previous step. If you want to give users the ability to add more users, select the <strong>Tenant Admin</strong> role. Otherwise, simply select the appropriate roles. There is a role for each feature.
                    <div className='my-8'>
                      <div className='flex justify-center'>
                        <img src='/images/products/GettingStarted/SelectRole.png' alt='Enter at least one email' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                      </div>
                    </div>
                  </li>
                </li>
              </ul>
            </p>
          </div>
          <div className='my-8'>
            <p>If you encounter an error message due to insufficient permissions, make sure you have the required permissions listed at the beginning of this page or ask an admin from your Genesys Cloud organization to log in and grant you the necessary permissions.</p>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

// Copilot.propTypes = {};

export default GettingStarted;
