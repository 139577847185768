import React, { useContext, useState, useEffect } from 'react';
import UserSelector from 'src/components/controls/UserSelector/UserSelector';
import PropertyItem from 'src/components/layout/PropertyItem';
import TeacketContext from 'src/context/TeacketContext';
import { notEmptyArray, sleepMs } from 'src/misc/Misc';
import useUserInfo from 'src/hooks/useUserInfo';
import { Button, message } from 'antd';
import TicketTimeTrackingHoursSelector from './TicketTimeTrackingHoursSelector';
import Space2 from 'src/components/layout/Space2';
import { FaRegClock } from 'react-icons/fa6';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postTicketTimeTracking, getTicketTimeTrackingDate } from 'src/api/teacket';
import { uuid } from 'short-uuid';
import Smile from 'src/components/layout/Smile';
import dayjs from 'dayjs';

//import PropTypes from 'prop-types';

const TicketTimeTrackingSubmit = () => {
  const { currentTicket, currentTicketMembers, getCurrentTicketService, setCurrentTicketTimeTrackingForceReload, determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [timeTrackingUsers, setTimeTrackingUsers] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedHours, setSelectedHours] = useState(1);
  const [selectedDay, setSelectedDay] = useState(dayjs().utc(true).startOf('day'));
  const [showSmileIcon, setShowSmileIcon] = useState(false);
  const [loadingReportedDate, setLoadingReportedDate] = useState(false);
  const [reportedDate, setReportDate] = useState(null);

  useEffect(() => {
    getTimeTrackingUsers();
  }, [currentTicketMembers]);

  useEffect(() => {
    if (!selectedDay) return;
    if (!selectedUserId) return;
    if (!currentTicket?.id) return;
    loadReportedDate();
  }, [selectedDay, selectedUserId, currentTicket]);

  const getTimeTrackingUsers = () => {
    const { props: props1 } = getCurrentTicketService();
    const roleList = props1?.roleList ?? [];
    const result = notEmptyArray(currentTicketMembers)
      ? currentTicketMembers
          .filter((member) =>
            roleList
              .filter((role) => !role.excludeFromTimeTracking)
              .map((role) => role.role)
              .includes(member.role)
          )
          .map((member) => {
            return { id: member.user_id, name: member.user_name, image: member.user_pic };
          })
      : null;
    setTimeTrackingUsers(result);
    setSelectedUserId(notEmptyArray(result) && result.some((u) => u.id === userId) ? userId : null);
  };

  const handleUserSelectorOnSelect = (id) => {
    //console.log('handleUserSelectorOnSelect:', id);
    setSelectedUserId(id);
  };

  const handleHoursSelectorHoursOnSelect = (hours) => {
    //console.log('handleHoursSelectorHoursOnSelect:', hours);
    setSelectedHours(hours);
  };

  const handleHoursSelectorDayOnSelect = (day) => {
    console.log('handleHoursSelectorDayOnSelect:', day);
    setSelectedDay(day);
  };

  const loadReportedDate = async () => {
    console.log('loadReportedDate:', currentTicket?.id, selectedUserId, selectedDay);
    setLoadingReportedDate(true);
    setReportDate(null);
    try {
      const resp = await getTicketTimeTrackingDate(currentTicket.id, selectedUserId, selectedDay);
      setReportDate(resp);
    } catch (error) {
      console.log(error);
    }
    setLoadingReportedDate(false);
  };

  const handleSubmit = () => {
    console.log('handleSubmit:', selectedUserId, selectedHours);

    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting hours...',
          duration: 0,
        });
        await postTicketTimeTracking(currentTicket.id, selectedUserId, selectedHours, selectedDay.format());
        setSelectedHours(1);
        setSelectedDay(null);
        messageApi.open({
          key,
          type: 'success',
          content: 'Hours submitted',
          duration: 3,
        });
        triggetShowingSmileIcon();
        setCurrentTicketTimeTrackingForceReload(true);
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });
  };

  const triggetShowingSmileIcon = async () => {
    setShowSmileIcon(true);
    await sleepMs(1500);
    setShowSmileIcon(false);
  };

  return (
    <div className={`w-full flex flex-col gap-2 ${true === determineIsWidgetReadOnly('Time Tracking') ? 'disabled' : null}`}>
      {contextHolder}
      <PropertyItem
        label='Who?'
        value={
          <div className='my-2'>
            <UserSelector userList={timeTrackingUsers} onSelect={handleUserSelectorOnSelect} value={selectedUserId} />
          </div>
        }
      />

      <div className='my-2'>
        <TicketTimeTrackingHoursSelector onHoursSelect={handleHoursSelectorHoursOnSelect} selectedHours={selectedHours} onDaySelect={handleHoursSelectorDayOnSelect} selectedDay={selectedDay} />
      </div>

      <div className='relative'>
        <div className='absolute z-50 flex flex-row justify-center mt-[-37px] w-full'>
          <Smile show={showSmileIcon} label='Thank you!' />
        </div>

        <Button type={reportedDate ? 'text' : 'primary'} size='large' className={'w-full'} onClick={handleSubmit} disabled={!selectedUserId || !selectedHours || !selectedDay || loadingReportedDate || reportedDate}>
          <Space2 className={` ${reportedDate ? 'text-green-900 dark:text-green-100 opacity-60' : null}`}>
            <div>
              <FaRegClock className={loadingReportedDate ? 'animate-pulse' : null} />
            </div>
            {selectedHours && selectedUserId && selectedDay ? (
              <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                {reportedDate ? (
                  <div>
                    <span className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                      Already reported
                    </span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {reportedDate.hours} {reportedDate.hours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                ) : (
                  <div>
                    <span className='font-light opacity-80'>Submit</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                )}
                <div>
                  <span className='font-light opacity-80'>for</span>{' '}
                  <span className='font-bold' style={{ fontStretch: 'condensed', overflow: 'hidden' }}>
                    {timeTrackingUsers.find((x) => x.id === selectedUserId).name}
                  </span>
                </div>
              </div>
            ) : (
              <span className='text-sm font-light opacity-80'>Submit</span>
            )}
          </Space2>
        </Button>
      </div>
    </div>
  );
};

TicketTimeTrackingSubmit.propTypes = {};

export default TicketTimeTrackingSubmit;
