import React, { useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import PropTypes from 'prop-types';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import { notEmptyString, textSorter } from 'src/misc/Misc';
import { Button, Tag, Input } from 'antd';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { MdDelete } from 'react-icons/md';
import { FaRegTrashCan, FaArrowsRotate } from 'react-icons/fa6';
import useTheme from 'src/hooks/useTheme';
import dayjs from 'dayjs';
import Space2 from 'src/components/layout/Space2';
import TeacketServiceEditorRoutingSelectorItem from './TeacketServiceEditorRoutingSelectorItem';
import { SearchOutlined } from '@ant-design/icons';

const TeacketServiceEditorRoutingSelector = ({ routing, onDelete, toOnChange, ccOnChange, onRefresh }) => {
  const { theme } = useTheme();
  const { greenTeacketServiceRoutingQuery, setGreenTeacketServiceRoutingQuery } = useContext(MasterAdminContext);

  const columns = [
    {
      title: 'Ticket Type',
      key: 'ticketType',
      render: (record) => {
        const NEW_ROW_PERIOD = 60 * 1000; // 1 minute in milliseconds
        const diff = dayjs().diff(dayjs(record.created_at));
        const newRow = diff <= NEW_ROW_PERIOD;
        return (
          <Space2>
            {record.ticket_type ?? <span className='opacity-40'>[ALL]</span>}
            {newRow && (
              <Tag color='blue' size='small'>
                new
              </Tag>
            )}
          </Space2>
        );
      },
      sorter: (a, b) => textSorter(a, b, 'ticket_type'),
    },
    {
      title: 'Region',
      key: 'region',
      render: (record) => record.region ?? <span className='opacity-40'>[ALL]</span>,
      sorter: (a, b) => textSorter(a, b, 'region'),
    },
    {
      title: 'Product',
      key: 'product',
      render: (record) => record.product ?? <span className='opacity-40'>[ALL]</span>,
      sorter: (a, b) => textSorter(a, b, 'product'),
    },
    {
      title: 'Category',
      key: 'category',
      render: (record) => record.category ?? <span className='opacity-40'>[ALL]</span>,
      sorter: (a, b) => textSorter(a, b, 'category'),
    },
    {
      title: 'SubCategory',
      key: 'subcategory',
      render: (record) => record.sub_category ?? <span className='opacity-40'>[ALL]</span>,
      sorter: (a, b) => textSorter(a, b, 'sub_category'),
    },
    {
      title: '',
      key: 'action',
      width: 36,
      align: 'center',
      render: (record) => (
        <YesNoDialog
          title='Remove'
          body={<>Do you want to remove this rule?</>}
          onYesClick={() => {
            if (!onDelete) return;
            onDelete(record.id);
          }}
          iconYes={<MdDelete />}
          showRed={true}
          labelYes='Yes, remove rule'
          labelNo='Cancel'
        >
          <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
            <FaRegTrashCan />
          </Button>
        </YesNoDialog>
      ),
    },
  ];

  const getFilteredData = () => {
    if (false === notEmptyString(greenTeacketServiceRoutingQuery)) return routing;
    return routing?.filter((record) => {
      return (
        record?.id?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.ticket_type?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.region?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.product?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.category?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.sub_category?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.to_list?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase()) ||
        record?.cc_list?.toLowerCase().includes(greenTeacketServiceRoutingQuery.toLowerCase())
      );
    });
  };

  return (
    <div className='flex flex-col gap-1'>
      <Space2>
        <Input
          loading
          // style={{ color: theme.textLight, borderColor: theme.textLight }}
          size='small'
          className='w-44 lg:w-96'
          placeholder='Search...'
          prefix={<SearchOutlined className='mr-2' />}
          allowClear
          value={greenTeacketServiceRoutingQuery}
          onChange={(e) => {
            setGreenTeacketServiceRoutingQuery(e.target.value);
          }}
        />
        <Button
          type='text'
          style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}
          onClick={() => {
            if (!onRefresh) return;
            onRefresh();
          }}
        >
          <FaArrowsRotate />
        </Button>
      </Space2>
      <CondensedTableLegacy
        columns={columns}
        dataSource={getFilteredData()}
        pagination={{ pageSize: 100, size: 'small' }}
        rowKey={(record) => record.id}
        expandable={{
          expandedRowRender: (record) => (
            <div className='ml-12 my-2'>
              <TeacketServiceEditorRoutingSelectorItem
                record={record}
                toOnChange={(emailList) => {
                  if (!toOnChange) return;
                  toOnChange(record.id, emailList);
                }}
                ccOnChange={(emailList) => {
                  if (!ccOnChange) return;
                  ccOnChange(record.id, emailList);
                }}
              />
            </div>
          ),
          rowExpandable: (/*record*/) => true,
        }}
        row
      />
    </div>
  );
};

TeacketServiceEditorRoutingSelector.propTypes = {
  routing: PropTypes.array,
  onDelete: PropTypes.func,
  toOnChange: PropTypes.func,
  ccOnChange: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default TeacketServiceEditorRoutingSelector;
