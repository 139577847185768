import React, { useState, useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { Space, Input, Spin, Button, message, Typography, Tag, Switch, Popover } from 'antd';
import { searchRoles, postRole, patchRole, deleteRole } from 'src/api/accesscontrol';
import TitleBar from 'src/components/layout/TitleBar';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus, FaMagnifyingGlass } from 'react-icons/fa6';
import RoleCreate from './RoleCreate';
import { SearchOutlined } from '@ant-design/icons';
import { isRequestCancelled, notEmptyArray } from 'src/misc/Misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { FaRegTrashCan } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import RolePermissions from './RolePermissions';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { MdDelete } from 'react-icons/md';
import { DEFAULT_ROLE } from 'src/misc/Config';
import { uuid } from 'short-uuid';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import Space2 from 'src/components/layout/Space2';

const { Paragraph } = Typography;

const Roles = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const pageSizeOptions = [20, 50, 100];

  const columns = [
    {
      title: 'Role',
      key: 'role',
      width: 320,
      render: (record) => (
        <>
          {record.role} {record.justCreated && <Tag color='blue'>just created</Tag>}
        </>
      ),
    },
    {
      title: (
        <>
          Description <span className='font-light opacity-60'>(supports HTML tags)</span>
        </>
      ),
      key: 'description',
      render: (record) => (
        <Space2>
          <Paragraph
            className='font-extralight'
            style={{ padding: 0, margin: 0 }}
            editable={
              record.role !== DEFAULT_ROLE && {
                onChange: async (description) => {
                  if (description === record.description) return;
                  if (description.length < 1) {
                    messageApi.open({
                      key: uuid(),
                      type: 'error',
                      content: 'Description is required',
                      duration: 3,
                    });

                    return;
                  }
                  executeAsyncProcess(async () => {
                    try {
                      const resp = await patchRole(record.role, { description });
                      const p = [...roles];
                      const i = p.findIndex((x) => x.role === record.role);
                      p[i] = { ...resp, permission_count: p[i]?.permission_count ?? 0 };
                      setRoles(p);
                      messageApi.open({
                        key: uuid(),
                        type: 'success',
                        content: 'Role updated',
                        duration: 3,
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  });
                },
              }
            }>
            {record.description}
          </Paragraph>
          <Popover trigger={['click']} title='HTML Preview' content={<div className='text-xs font-light opacity-60' style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: record.description }} />}>
            <FaMagnifyingGlass style={{ color: theme.primary }} className='cursor-pointer hover:opacity-60' />
          </Popover>
        </Space2>
      ),
    },
    {
      title: 'For tenant admins?',
      key: 'for_tenant_admins',
      width: 130,
      align: 'center',
      render: (record) => (
        <>
          <Switch
            size='small'
            checked={record.for_tenant_admins}
            checkedChildren='yes'
            unCheckedChildren='no'
            onChange={async (description) => {
              if (description === record.description) return;
              if (description.length < 1) {
                messageApi.open({
                  key: uuid(),
                  type: 'error',
                  content: 'Description is required',
                  duration: 3,
                });

                return;
              }
              executeAsyncProcess(async () => {
                try {
                  const resp = await patchRole(record.role, { for_tenant_admins: !record.for_tenant_admins });
                  const r = [...roles];
                  const i = r.findIndex((x) => x.role === record.role);
                  r[i] = { ...resp, permission_count: r[i]?.permission_count ?? 0 };
                  setRoles(r);
                  messageApi.open({
                    key: uuid(),
                    type: 'success',
                    content: 'Role updated',
                    duration: 3,
                  });
                } catch (error) {
                  console.log(error);
                }
              });
            }}
          />
        </>
      ),
    },
    {
      title: 'Permissions #',
      key: 'permissionCount',
      width: 130,
      align: 'center',
      render: (record) => <>{record.permission_count}</>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) =>
        record.role !== DEFAULT_ROLE && (
          <Space className='btn'>
            <YesNoDialog
              title='Delete'
              body={
                <>
                  Do you want to delete role <strong>{record.role}</strong>?
                </>
              }
              onYesClick={() => {
                handlePernamentlyDelete(record.role);
              }}
              iconYes={<MdDelete />}
              showRed={true}
              labelYes='Yes, delete role'
              labelNo='Cancel'>
              <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
                <FaRegTrashCan />
              </Button>
            </YesNoDialog>
          </Space>
        ),
    },
  ];

  const [roles, setRoles] = useState(null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [newOpen, setNewOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   loadData();
  // }, []);

  useEffect(() => {
    setPage(1);
    setTotal(0);
    setRoles(null);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    loadData();
  }, [page]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchRoles(q?.length > 0 ? q : null, page, pageSize);
      setTotal(resp?.total ?? 0);
      setRoles(resp.entities);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
    }
    setLoading(false);
  };

  const handleRoleCreateOnSubmit = async (values) => {
    console.log('handleTenantCreateOnSubmit', values);
    setNewOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postRole(values);
        const r = true === notEmptyArray(roles) ? [...roles] : [];
        r.unshift({ ...resp, justCreated: true });
        setRoles(r);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'New role created',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = async (role) => {
    console.log('handlePernamentlyDelete', role);
    executeAsyncProcess(async () => {
      try {
        await deleteRole(role);
        const o = roles.filter((x) => x.role !== role);
        setRoles(o);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Role deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnRolePermissionsChanged = ({ role, length }) => {
    const p = [...roles];
    const i = p.findIndex((x) => x.role === role);
    p[i].permission_count = length;
    setRoles(p);
  };

  return (
    <>
      <RoleCreate open={newOpen} onClose={() => setNewOpen(false)} onSubmit={handleRoleCreateOnSubmit} />
      {contextHolder}
      <TitleBar
        isSticky={false}
        busyIndicator={false}
        isLoading={loading}
        colorBackground={theme.backgroundBase}
        title={
          <Space>
            <StyledButton
              color={theme.textLight}
              onClick={() => {
                setPage(1);
                setNewOpen(true);
              }}
              size='small'>
              <Space className='btn'>
                <FaPlus />
                <WhenDesktop>Create role</WhenDesktop>
              </Space>
            </StyledButton>
          </Space>
        }>
        <Input
          loading
          //style={{ color: theme.textLight, borderColor: theme.textLight }}
          size='small'
          className='w-44 lg:w-60'
          placeholder='Search...'
          prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
          allowClear
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </TitleBar>

      <CondensedTableLegacy
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        columns={columns}
        dataSource={roles}
        rowKey={'role'}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              <RolePermissions role={record.role} onRolePermissionsChanged={handleOnRolePermissionsChanged} />
            </p>
          ),
          rowExpandable: () => true,
        }}
      />
    </>
  );
};

Roles.propTypes = {};

export default Roles;
