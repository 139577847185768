import React, { useState, useContext, useEffect } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import { Button, Table, Typography, Form, InputNumber, Input, Space, message } from 'antd';
import PropTypes from 'prop-types';
import { uuid } from 'short-uuid';
import { FaRegTrashCan, FaXmark, FaCheck } from 'react-icons/fa6';
import { FaRegEdit } from 'react-icons/fa';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { patchProjectProps } from 'src/api/fsireport';
import { MdLibraryAdd } from 'react-icons/md';

const CustomHeaders = () => {
  const { currentProject, patchCurrentProjectProps } = useContext(FsiReportContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (currentProject?.props?.customHeaders) {
      setData(currentProject.props.customHeaders);
      setOriginalData(currentProject.props.customHeaders);
    }
  }, [currentProject]);

  useEffect(() => {
    // compare data with originalData, if changed set isChanged to true
    if (JSON.stringify(data) !== JSON.stringify(originalData)) setIsChanged(true);
    else setIsChanged(false);
  }, [data]);

  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    EditableCell.propTypes = {
      editing: PropTypes.func,
      dataIndex: PropTypes.string,
      title: PropTypes.string,
      inputType: PropTypes.string,
      record: PropTypes.object,
      index: PropTypes.number,
      children: PropTypes.node,
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleAdd = () => {
    const newData = {
      key: uuid(),
      label: '',
      value: '',
    };
    setData([...data, newData]);
  };

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const columns = [
    {
      title: 'key',
      dataIndex: 'key',
      width: '1%',
      editable: false,
      hidden: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '50%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record?.key)}
              style={{
                marginInlineEnd: 8,
              }}>
              <FaCheck />
            </Typography.Link>

            <Typography.Link onClick={cancel}>
              <FaXmark />
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <FaRegEdit />
            </Typography.Link>

            <Typography.Link className='ml-2' type='text' onClick={() => handleDelete(record.key)}>
              <FaRegTrashCan />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record?.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.key,
        editing: isEditing(record),
      }),
    };
  });

  const handleSave = () => {
    console.log('data:', data);
    executeAsyncProcess(async () => {
      try {
        const resp = await patchProjectProps(currentProject.id, { customHeaders: data });
        patchCurrentProjectProps({ customHeaders: resp.customHeaders });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Headers saved',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <div className='text-xs text-center text-genesys-gray-500 mb-2'>Headers placed before question list in a final report.</div>
      {contextHolder}
      <Form name='headersForm' form={form} component={false} autoComplete='off'>
        <Table
          size='small'
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName='editable-row'
          pagination={false}
        />
      </Form>
      <div className='flex flex-auto p-4'>
        <div className='flex flex-auto text-left'>
          <Typography.Link onClick={handleAdd}>
            <Space>
              <MdLibraryAdd />
              <div className='mb-1'>Add a new row</div>
            </Space>
          </Typography.Link>
        </div>
        <div className='flex text-right'>
          {isChanged && <span className='text-xs text-orange-400 mr-2 mt-1'>changes not saved</span>}
          <Button type='primary' size='small' onClick={handleSave} disabled={!isChanged}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

CustomHeaders.propTypes = {};

CustomHeaders.defaultProps = {};

export default CustomHeaders;
