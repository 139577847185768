import React from 'react';
import styled from 'styled-components';
import { Table } from 'antd';

const CondensedTable1 = styled.div`
  .ant-table-cell {
    padding: 1px 4px !important;
  }
`;
const CondensedTableLegacy = (props) => {
  return (
    <CondensedTable1>
      <Table {...props} />
    </CondensedTable1>
  );
};

CondensedTableLegacy.propTypes = {};

export default CondensedTableLegacy;
