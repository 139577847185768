import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import BarLoader from 'react-bar-loader';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import GlobalContext from 'src/context/GlobalContext';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { Tooltip } from 'antd';

const TitleBar = ({ isSticky, title, afterTitleExtras, children, busyIndicator, colorBackground, colorForeground, colorLoaderBackground, colorLoaderForeground, isLoading, bottomExtras, defaultExpanded }) => {
  const { isBusy } = useAsyncProcesses();
  const { theme } = useTheme();
  const { tokenExpiring } = useContext(GlobalContext);

  if (!colorBackground) colorBackground = theme.backgroundMedium;
  if (!colorForeground) colorForeground = theme.textBase;
  if (!colorLoaderBackground) colorLoaderBackground = theme.light;
  if (!colorLoaderForeground) colorLoaderForeground = theme.primary;

  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className={`${isSticky && 'sticky'} ${true === tokenExpiring ? 'top-[50px]' : 'top-0'} z-50 ${isLoading ? 'disabled' : null}`}>
      <div style={{ backgroundColor: colorBackground }} className='drop-shadow-md z-20'>
        <div className='flex flex-row py-1 px-4 items-center'>
          <div className='h-10 flex-initial flex flex-row justify-start items-center text-xl font-bold' style={{ color: colorForeground, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {title}
            <div className='ml-4 flex flex-row gap-1 overflow-hidden'>{afterTitleExtras}</div>
          </div>
          <div className='h-10 flex-auto flex flex-row gap-1 justify-end items-center overflow-hidden'>{children}</div>
        </div>
        {(busyIndicator && isBusy) || isLoading ? <BarLoader color={colorLoaderForeground} backgroundColor={colorLoaderBackground} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: colorLoaderForeground }}></div>}
      </div>
      {bottomExtras && (
        <>
          {!isBusy && !expanded && (
            <div className='absolute cursor-pointer rounded-full animate-appear3 drop-shadow-md z-10 flex flex-col justify-center items-center hover:scale-110 transition-all' style={{ marginLeft: 'calc(50% - 10px)', marginTop: '-10px', backgroundColor: colorBackground, border: `1px solid ${colorLoaderForeground}`, width: '20px', height: '20px' }} onClick={() => setExpanded(!expanded)}>
              <Tooltip title='Click to expand'>
                <FaAngleDown />
              </Tooltip>
            </div>
          )}
          <div className={`${expanded ? 'h-auto' : 'h-0 overflow-hidden'}`}>
            <div className={'p-4 top-0 mx-4 shadow-md'} style={{ backgroundColor: theme.backgroundBase, borderLeft: `1px solid ${theme.borderLight}`, borderRight: `1px solid ${theme.borderLight}`, borderBottom: `1px solid ${theme.borderLight}`, borderRadius: '0 0 0.5rem 0.5rem' }}>
              {bottomExtras}
              {expanded && (
                <div className='absolute cursor-pointer rounded-full animate-appear3 drop-shadow-md z-10 flex flex-col justify-center items-center hover:scale-110 transition-all' style={{ marginLeft: 'calc(50% - 30px)', marginTop: '10px', backgroundColor: colorBackground, border: `1px solid ${colorLoaderForeground}`, width: '20px', height: '20px' }} onClick={() => setExpanded(!expanded)}>
                  <Tooltip title='Click to collapse'>
                    <FaAngleUp />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.any,
  afterTitleExtras: PropTypes.any,
  bottomExtras: PropTypes.any,
  children: PropTypes.node,
  busyIndicator: PropTypes.bool,
  colorBackground: PropTypes.string,
  colorForeground: PropTypes.string,
  colorLoaderBackground: PropTypes.string,
  colorLoaderForeground: PropTypes.string,
  isLoading: PropTypes.bool,
  isSticky: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
};

TitleBar.defaultProps = {
  title: 'No title',
  busyIndicator: true,
  isLoading: false,
  isSticky: true,
  defaultExpanded: false,
};

export default TitleBar;
