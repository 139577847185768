import React, { useContext, useState } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Alert, Card, Button, Modal, Row, Col, message } from 'antd';
import SubjectPicker from './SubjectPicker';
import RequestsList from './RequestList';
import { patchJob } from 'src/api/dataSubjectRequests';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import DSRRequestTypeStep from './WizardCustomSteps/DSRRequestTypeStep';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import SubjectItem from './SubjectItem';
import { uuid } from 'short-uuid';

const JobType1 = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { job, subject, submitRequest } = useContext(DataSubjectRequestsContext);
  const [subjectPickerVisible, setSubjectPickerVisible] = useState(false);

  const searchSubjectHandleOk = async () => {
    console.log('searchSubjectHandleOk');
    if (job) job.subject = subject;

    await executeAsyncProcess(async () => {
      try {
        await patchJob(job.id, { subject: subject });
      } catch (error) {
        console.error(error);
      }
    });

    setSubjectPickerVisible(false);
  };

  return (
    <>
      {contextHolder}
      {subjectPickerVisible && (
        <Modal
          forceRender
          width={800}
          title={'Search for a GDPR subject'}
          open={subjectPickerVisible}
          onCancel={() => {
            setSubjectPickerVisible(false);
          }}
          onOk={() => searchSubjectHandleOk()}>
          <SubjectPicker />
        </Modal>
      )}
      {job && (
        <PermissionsProvider requiredPermissions={['apps.datasubjectrequests.view']}>
          {job.use_real_api === true && <Alert message='Real API' type='info' showIcon />}
          {job.use_real_api !== true && <Alert message='API Simulation. No real queries against Genesy Cloud organization.' type='warning' showIcon />}
          <Card title='GDPR Subject' size='small' bordered={false} className='mb-2'>
            <div style={{ maxWidth: '800px' }}>{job.subject && <SubjectItem item={job.subject} />}</div>
          </Card>
          {/* <Button
            className='ml-2 mb-2'
            disabled={Array.isArray(job?.requests) && job.requests.length > 0}
            icon={<SearchOutlined />}
            onClick={() => {
              setSubjectPickerVisible(true);
            }}
          >
            Search
          </Button> */}

          <Row gutter={24} style={{ margin: 0 }}>
            <Col sm={24} md={24} lg={24} xl={24}>
              {job?.subject && (
                <Card title='Submit a request' size='small' bordered={false}>
                  <div style={{ maxWidth: '800px' }}>
                    <DSRRequestTypeStep />
                  </div>
                </Card>
              )}

              <Button
                className='m-4 text-right'
                type='primary'
                // disabled={job.requests && job.requests.length > 0 && requestType === 'GDPR_DELETE' && job.requests.some((x) => x.type === 'GDPR_DELETE')}
                onClick={async () => {
                  console.log('Create new Request');
                  await executeAsyncProcess(async () => {
                    const key = uuid();
                    try {
                      await submitRequest();

                      messageApi.open({
                        key,
                        type: 'success',
                        content: 'Job created',
                        duration: 3,
                      });
                    } catch (error) {
                      console.error(error);
                      messageApi.open({
                        key,
                        type: 'error',
                        content: 'An error occurred while creating the GDPR Job',
                        duration: 3,
                      });
                    }
                  });
                }}>
                Submit
              </Button>
            </Col>

            <Col sm={24} md={24} lg={24} xl={24}>
              {Array.isArray(job?.requests) && job.requests.length > 0 && (
                <>
                  <Card title='Requests' size='small' bordered={false}>
                    <div>
                      <RequestsList />
                      <span className='text-xs opacity-60'>
                        Generated files are automatically deleted after 30 days, and the <i>ZIP</i> and <i>Files</i> sections become unavailable after this period.
                      </span>
                    </div>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </PermissionsProvider>
      )}
    </>
  );
};

JobType1.propTypes = {};

export default JobType1;
