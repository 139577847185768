import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useUserInfo from 'src/hooks/useUserInfo';
//import useUserInfo from 'src/hooks/useUserInfo';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropertyItem from 'src/components/layout/PropertyItem';
import TicketActiveViewers from './TicketActiveViewers';
import { notEmptyArray } from 'src/misc/Misc';
import { /*Button,*/ Modal, message } from 'antd';
import { patchTicket, getTicketMembers } from 'src/api/teacket';

import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import DeploymentPicker from './DeploymentPicker';
import { uuid } from 'short-uuid';
import Journey from './Journey';
import CategoryUrls from './CategoryUrls';
import { needNumberOfQuestions } from '../Misc/misc';
import CustomFieldInt from './TicketCustomProps/CustomFieldInt';
import { getTicketWidgetsValues } from 'src/api/teacket';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const TicketProperties = () => {
  const WIDGET_ID = 'Properties';
  const { executeAsyncProcess } = useAsyncProcesses();
  const { currentTicket, activeViewers, servicesForTenant, updateCurrentTicket, currentTicketNumberOfQuestions, setCurrentTicketNumberOfQuestions, /*currentTicketReadOnly, currentTicketReadOnlyWidgetList, determineIsWidgetReadOnly, getServiceSettingForCurrentTicket, currentTicketIsMasterTenant,*/ currentTicketAccDetails, setCurrentTicketOppDetails, setCurrentTicketMembers, getServicePropsForServiceId } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  const serviceName = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.name;

  // account and opportunity
  // const [canUpdateOppAcc, setCanUpdateOppAcc] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  // const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(false);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [journeyModalOpen, setJourneyModalOpen] = useState(false);
  //const [showJourney, setShowJourney] = useState(false);

  // deployment
  // const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [deploymentModalOpen, setDeploymentModalOpen] = useState(false);
  // const [canUpdateDeployment, setCanUpdateDeployment] = useState(false);
  const [deployment, setDeployment] = useState(null);

  //others
  const [urlList, setUrlList] = useState(null);

  // const [deploymentOptions, setDeploymentOptions] = useState(null);

  // useEffect(() => {
  //   // const sf = '1' === getServiceSettingForCurrentTicket('SnowflakeOpportunitySupport');
  //   // setSnowflakeOpportunitySupport(sf);

  //   // const gc = '1' === getServiceSettingForCurrentTicket('GcDeploymentSupport');
  //   // setGcDeploymentSupport(gc);

  //   // const sj = '1' === getServiceSettingForCurrentTicket('ShowJourney');
  //   // setShowJourney(sj);
  // }, []);

  useEffect(() => {
    if (!currentTicket) return;
    loadData();
  }, [currentTicket]);

  useEffect(() => {
    const cl = getServicePropsForServiceId(currentTicket.service_id, 'categoryList');
    const ul = cl?.find((c) => c.category === currentTicket.category)?.urlList;
    setUrlList(true === notEmptyArray(ul) ? ul : null);
  }, [currentTicket?.category]);

  // useEffect(() => {
  //   setCanUpdateOppAcc(determineIfCanUpdateOppAcc());
  //   setCanUpdateDeployment(determineIfCanUpdateDeployment());
  // }, [currentTicketReadOnly, currentTicketReadOnlyWidgetList, currentTicketIsMasterTenant]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const result = await getTicketWidgetsValues(currentTicket.id, WIDGET_ID);
        console.log('[TicketCompletionDates] loadData :', result);
        setCurrentTicketNumberOfQuestions(result.find((x) => x.field === 'Number of questions'));
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleAccountModalOnSelect = (v) => {
    console.log('handleAccountModalOnSelect', v);
    setAccount(v);
  };

  const handleOpportunityModalOnSelect = (v) => {
    console.log('handleOpportunityModalOnSelect', v);
    setOpportunity(v);
  };

  const handleDeploymentModalOnSelect = (v) => {
    console.log('handleDeploymentModalOnSelect', v);
    setDeployment(v);
  };

  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name } = account;

        const oppProps = setCurrentTicketOppDetails(null);
        let ticketProps = { ...currentTicket.props, ...oppProps, ...account };

        const ticket = await patchTicket(currentTicket.id, { acc_id, acc_name, opp_id: null, opp_name: null, props: ticketProps });

        // Reload members
        const result = await getTicketMembers(currentTicket.id);
        setCurrentTicketMembers(result);

        updateCurrentTicket(ticket);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Account updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setAccountModalOpen(false);
  };

  const handleOpportunityModalOnOk = () => {
    console.log('handleOpportunityModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name, OPPORTUNITY_ID: opp_id, OPPORTUNITY_NAME: opp_name } = opportunity;

        const oppProps = setCurrentTicketOppDetails(opportunity);
        const accProps = currentTicketAccDetails;

        let ticketProps = { ...currentTicket.props, ...oppProps, ...accProps };

        const ticket = await patchTicket(currentTicket.id, { acc_id, acc_name, opp_id, opp_name, props: ticketProps });
        ticketProps = { ...ticket.props, ...oppProps };
        updateCurrentTicket({ ...ticket, props: ticketProps });

        // Reload members
        const result = await getTicketMembers(currentTicket.id);
        setCurrentTicketMembers(result);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Opportunity updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setOpportunityModalOpen(false);
  };

  const handleDeploymentModalOnOk = () => {
    console.log('handleDeploymentModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { value: dep_id, label: dep_name } = deployment;
        const ticket = await patchTicket(currentTicket.id, { dep_id, dep_name });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Deployment updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setDeploymentModalOpen(false);
  };

  // const determineIfCanUpdateDeployment = () => {
  //   if (true !== determineIsWidgetReadOnly('Properties') && currentTicketIsMasterTenant) return true;
  //   return false;
  // };

  // const determineIfCanUpdateOppAcc = () => {
  //   if (true !== determineIsWidgetReadOnly('Properties') && currentTicketIsMasterTenant) return true;
  //   return false;
  // };

  return (
    <>
      {contextHolder}
      {accountModalOpen && (
        <Modal
          title={'Search for account'}
          open={true}
          onCancel={() => {
            setAccountModalOpen(false);
          }}
          onOk={handleAccountModalOnOk}
        >
          <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
        </Modal>
      )}
      {opportunityModalOpen && (
        <Modal
          title={'Search for opportunity'}
          open={true}
          onCancel={() => {
            setOpportunityModalOpen(false);
          }}
          onOk={handleOpportunityModalOnOk}
          okButtonProps={{ disabled: isOkDisabled }}
        >
          <SnowflakeOpportunityPicker onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} />
        </Modal>
      )}
      {deploymentModalOpen && (
        <Modal
          title={'Select a deployment'}
          open={true}
          onCancel={() => {
            setDeploymentModalOpen(false);
          }}
          onOk={handleDeploymentModalOnOk}
        >
          <DeploymentPicker accId={currentTicket.acc_id} defaultValue={currentTicket.dep_id} onSelect={handleDeploymentModalOnSelect} />
        </Modal>
      )}
      {journeyModalOpen && (
        <Modal title={'Journey'} open={true} centered width={'75%'} onOk={() => setJourneyModalOpen(false)} autoFocusButton='ok' onCancel={() => setJourneyModalOpen(false)} cancelButtonProps={{ style: { display: 'none' } }}>
          <Journey ticketId={currentTicket.id} ticketCreatedDate={currentTicket.created_at} ticketClosedDate={currentTicket.closed_at} accId={currentTicket.acc_id} oppId={currentTicket.opp_id} />
        </Modal>
      )}

      <div className='flex flex-col gap-2 mt-4'>
        {true === notEmptyArray(activeViewers.filter((user) => user.user_id !== userId)) && <PropertyItem label='Others are also viewing this ticket' value={<TicketActiveViewers />} loading={!currentTicket} propertyColor='#78350f' />}
        <PropertyItem label='Service' value={serviceName} />
        <PropertyItem label='Category / SubCategory' value={currentTicket.category} secondValue={currentTicket.sub_category} />
        {urlList && <PropertyItem label='External Links' value={<CategoryUrls urlList={urlList} vertical={true} />} />}
        {true === needNumberOfQuestions(currentTicket) && currentTicketNumberOfQuestions && (
          <CustomFieldInt
            ticketId={currentTicket?.id}
            widgetId={WIDGET_ID}
            field={currentTicketNumberOfQuestions}
            onUpdate={(v) => {
              const { value } = v?.result ?? {};
              setCurrentTicketNumberOfQuestions({ ...currentTicketNumberOfQuestions, value });
            }}
          />
        )}
        {/* <PropertyItem label='Account' value={currentTicket?.acc_name} secondValue={currentTicket?.acc_id} onClick={canUpdateOppAcc ? () => setAccountModalOpen(true) : null} /> */}
        {/* {true === snowflakeOpportunitySupport && <PropertyItem label='Opportunity' value={currentTicket?.opp_name} secondValue={currentTicket?.opp_id} onClick={canUpdateOppAcc ? () => setOpportunityModalOpen(true) : null} />} */}
        {/* {currentTicket.acc_id && true === gcDeploymentSupport && <PropertyItem label='Deployment' value={currentTicket.dep_name} secondValue={currentTicket.dep_id} onClick={canUpdateDeployment ? () => setDeploymentModalOpen(true) : null} />} */}
        {/* <PropertyItem label='Region' value={currentTicket.region} /> */}
        {/* {showJourney && currentTicket.acc_id && (
          <Button type='primary' onClick={() => setJourneyModalOpen(true)}>
            Show Journey
          </Button>
        )} */}
      </div>
    </>
  );
};

TicketProperties.propTypes = {};

export default TicketProperties;
