import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { Input, Space, Tooltip, Button } from 'antd';
import useTheme from 'src/hooks/useTheme';
import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .ant-input {
    line-height: normal;
  }
`;

const CollapsibleField = ({ label, value, editable = null, maxLength = 100, edgeGradientColor = null }) => {
  const { theme } = useTheme();

  const [collapsed, setCollapsed] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState(null);

  const handleToggleCollapsed = () => setCollapsed(!collapsed);

  const handleOnEdit = () => {
    setCollapsed(false);
    setEditing(true);
    setNewValue(value ?? '');
  };

  const handleOnSave = () => {
    setEditing(false);
    if (!editable?.onChange) return;
    editable.onChange(newValue);
  };

  const handleOnCancel = () => {
    setEditing(false);
  };

  const handleNewValueOnChange = (e) => {
    setNewValue(e.target.value);
  };

  return (
    <StyledDiv className='relative'>
      <div className='absolute top-[-9px] left-[8px] z-10 flex flex-row items-center gap-2 text-xs px-1' style={{ backgroundColor: theme.backgroundBase }}>
        <div className={'font-semibold opacity-80'}>{label ?? 'Unknown property'}</div>
        <>
          {!editing && (
            <>
              {editable && (
                <Tooltip title='Click to edit'>
                  <FaEdit className='opacity-60 cursor-pointer' onClick={handleOnEdit} />
                </Tooltip>
              )}
              <>
                {collapsed ? (
                  <Tooltip title='Click to expand'>
                    <BsArrowsExpand className='opacity-60 cursor-pointer' onClick={handleToggleCollapsed} />
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title='Click to collapse'>
                      <BsArrowsCollapse className='opacity-60 cursor-pointer' onClick={handleToggleCollapsed} />
                    </Tooltip>
                  </>
                )}
              </>
            </>
          )}
        </>
        {collapsed ? <span className='font-extralight text-xs text-orange-600'>collapsed</span> : ''}
        {editing ? <span className='font-extralight text-xs text-orange-600'>editing</span> : ''}
      </div>
      <div className={'relative rounded-xl py-2 px-3'} style={{ border: `1px solid ${theme.borderLight}`, position: 'relative', overflow: 'hidden' }}>
        {collapsed && edgeGradientColor && <div style={{ zIndex: 1, position: 'absolute', bottom: '0px', left: '0px', right: '0px', height: '36px', background: `linear-gradient(180deg, #ffffff00 0%, ${edgeGradientColor} 70%)`, pointerEvents: 'none', userSelect: 'none' }} />}
        {editable && true === editing ? (
          <>
            <div>{true !== editable?.multiline ? <Input value={newValue} onChange={handleNewValueOnChange} maxLength={maxLength} showCount multiline /> : <Input.TextArea value={newValue} onChange={handleNewValueOnChange} autoSize={{ minRows: 1, maxRows: 50 }} maxLength={maxLength} showCount />}</div>
            <Space className='my-2'>
              <Button size='small' type='primary' onClick={handleOnSave}>
                Save
              </Button>
              <Button size='small' onClick={handleOnCancel}>
                Cancel
              </Button>
            </Space>
          </>
        ) : (
          <div>
            <div style={{ wordBreak: 'break-word' }} className={collapsed ? 'mx-3 my-1 max-h-4 overflow-hidden overflow-ellipsis' : 'mx-3 my-1 overflow-auto'} dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </div>
    </StyledDiv>
  );
};

CollapsibleField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editable: PropTypes.object,
  maxLength: PropTypes.number,
  edgeGradientColor: PropTypes.string,
};

export default CollapsibleField;
