import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
// import { objToQueryStringParams } from 'src/misc/Misc';

//#region "DICTIONARY - USER"

export const getDictionaryGroup = async (group) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/dictionaries/groups/${encodeURIComponent(group)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

//#endregion
