//#region Imports

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import MuiTheme from 'src/misc/MuiTheme';
import { ResponsiveChartContainer, ChartsXAxis, ChartsYAxis, ChartsLegend, ChartsTooltip, ChartsAxisHighlight, MarkPlot, LineHighlightPlot, BarPlot } from '@mui/x-charts';
import NoData from 'src/components/layout/NoData';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';
import { IconButton, Dialog, DialogContent } from '@mui/material';
import { AiOutlineFullscreen, AiOutlineClose } from 'react-icons/ai';

//#endregion

const ByCategoryByMonthChart = () => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  //#region Use States

  const [data, setData] = useState(null);
  const [hiddenSeries, setHiddenSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //#endregion

  //#region Use Effects

  useEffect(() => {
    const { byCategoryByMonthChart } = dashboardDataTickets ?? {};
    if (!byCategoryByMonthChart) return;

    const monthsMap = new Map();
    const categoriesMap = new Map(); // Track total count per category
    const categoriesSet = new Set();

    // First pass - collect data and total counts
    byCategoryByMonthChart.forEach((item) => {
      const monthKey = new Date(item.month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      const category = item.category || 'Unknown';
      categoriesSet.add(category);

      // Update category total count
      categoriesMap.set(category, (categoriesMap.get(category) || 0) + parseInt(item.count));

      if (!monthsMap.has(monthKey)) {
        monthsMap.set(monthKey, {});
      }
      monthsMap.get(monthKey)[category] = parseInt(item.count);
    });

    const months = Array.from(monthsMap.keys());
    // Sort categories by total count descending
    const categories = Array.from(categoriesSet).sort((a, b) => categoriesMap.get(b) - categoriesMap.get(a));

    const chartData = {
      series: categories
        .filter((category) => !hiddenSeries.includes(category))
        .map((category) => ({
          data: months.map((month) => monthsMap.get(month)[category] || 0),
          label: category,
          type: 'bar',
          stack: 'total',
        })),
      xAxis: months,
    };

    setData(chartData);
  }, [dashboardDataTickets, hiddenSeries]);

  //#endregion

  //#region No Data Rendering

  if (!data) {
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );
  }

  //#endregion

  //#region Chart Data & Options

  const chartColors = colors.categories.map((color) => (isDarkMode ? color.dark : color.light));

  //#endregion

  //#region Chart Content

  const ChartContent = ({ height = 400 }) => (
    <ResponsiveChartContainer
      skipAnimation={true}
      colors={chartColors}
      margin={{ top: 100, right: 30, bottom: 120, left: 30 }}
      xAxis={[
        {
          id: 'x-axis',
          scaleType: 'band',
          data: data?.xAxis,
          tickLabelStyle: {
            angle: -45,
            textAnchor: 'end',
          },
        },
      ]}
      yAxis={[
        {
          id: 'y-axis',
          tickMinStep: 1,
        },
      ]}
      series={data?.series}
      height={400}>
      <ChartsLegend position={{ vertical: 'top', horizontal: 'middle' }} onItemClick={handleLegendClick} />
      <BarPlot borderRadius={10} />
      <ChartsXAxis />
      <ChartsYAxis />
      <MarkPlot />
      <LineHighlightPlot />
      <ChartsAxisHighlight x='line' />
      <ChartsTooltip trigger='axis' />
    </ResponsiveChartContainer>
  );

  ChartContent.propTypes = {
    height: PropTypes.number,
  };

  //#endregion

  //#region Handle Clicks

  const handleLegendClick = (event, itemId) => {
    setHiddenSeries((prev) => (prev.includes(itemId.label) ? prev.filter((item) => item !== itemId.label) : [...prev, itemId.label]));
  };

  const handleFullscreenClick = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  //#endregion

  return (
    <MuiTheme>
      {/* Fullscreen Button */}
      <div style={{ position: 'relative' }}>
        <IconButton
          onClick={handleFullscreenClick}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
          }}>
          <AiOutlineFullscreen className='text-genesys-gray-500' />
        </IconButton>
        <ChartContent />
      </div>

      {/* Fullscreen Modal */}
      <Dialog fullWidth maxWidth='xl' open={isModalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}>
            <AiOutlineClose />
          </IconButton>
          <ChartContent height={700} />
        </DialogContent>
      </Dialog>
    </MuiTheme>
  );
};

export default ByCategoryByMonthChart;
