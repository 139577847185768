import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Radio, Button, Space, message } from 'antd';
import { patchRoleAssignmentProps } from 'src/api/accesscontrol';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { uuid } from 'short-uuid';

const AssignmentsDepartments = ({ record, onSave }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const [departmentsMode, setDepartmentsMode] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (!record) return;
    setData();
  }, [record]);

  const setData = () => {
    setDepartmentsMode(record?.props?.departmentsMode ?? null);
    setDepartments(record?.props?.departments?.join('\n') ?? null);
    setChanged(false);
  };

  const handleDepartmentsModeOnChange = (e) => {
    console.log('handleDepartmentsModeOnChange:', e.target.value);
    setDepartmentsMode(e.target.value);
    if (null === e.target.value) setDepartments(null);
    setChanged(true);
  };

  const handleDepartmetsOnChange = (e) => {
    console.log('handleDepartmetsOnChange:', e.target.value);
    setDepartments(e.target.value);
    setChanged(true);
  };

  const handleDepartmentsOnBlur = (e) => {
    console.log('handleDepartmentsOnBlur', e.target.value);
    setDepartments(e.target.value.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gm, '\r\n').trim());
  };

  const handleSaveBtnOnClick = async () =>
    executeAsyncProcess(async () => {
      try {
        console.log('handleSaveBtnOnClick');
        const splitDepartments =
          departments
            ?.split(/\r\n|\n|\r/)
            ?.map((x) => x?.trim())
            ?.filter((x) => x?.length > 0) ?? null;
        console.log('splitDepartments:', splitDepartments, departmentsMode);

        if (null !== departmentsMode && true === notArrayOrEmpty(splitDepartments)) {
          messageApi.open({
            key: uuid(),
            type: 'error',
            content: 'Department list is empty',
            duration: 3,
          });
          return;
        }

        const assignment = await patchRoleAssignmentProps(record?.assignment_id, { departmentsMode, departments: splitDepartments });
        setChanged(false);
        if (onSave) onSave(assignment);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Item updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });

  return (
    <div className='flex flex-col gap-2 m-1 p-4 border border-solid border-sky-300 dark:border-sky-700 rounded-lg'>
      {contextHolder}
      <div>
        <Radio.Group onChange={handleDepartmentsModeOnChange} value={departmentsMode}>
          <Radio value={null}>Whole tenant</Radio>
          <Radio value={'include'}>Includes</Radio>
          <Radio value={'exclude'}>Excludes</Radio>
        </Radio.Group>
      </div>
      <div>
        <div>
          Department list <span className='font-light opacity-60'>(1 per line)</span>:
        </div>
        <div>
          <Input.TextArea value={departments} onChange={handleDepartmetsOnChange} onBlur={handleDepartmentsOnBlur} autoSize={{ minRows: 2, maxRows: 24 }} disabled={!departmentsMode} />
        </div>
      </div>
      <div>
        <Space>
          <Button type='primary' size='small' onClick={handleSaveBtnOnClick} disabled={!changed}>
            Save
          </Button>
          <Button size='small' disabled={!changed} onClick={setData}>
            Cancel
          </Button>
          {changed && <div className='text-xs font-light text-red-500'>changes are not saved</div>}
        </Space>
      </div>
    </div>
  );
};

AssignmentsDepartments.propTypes = {
  record: PropTypes.object,
  onSave: PropTypes.func,
};

export default AssignmentsDepartments;
