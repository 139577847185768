import React, { useEffect, useContext, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import LoadingLabel from 'src/components/layout/LoadingLabel';
import ConversationHistoryItem from './ConversationHistoryItem';
import { getTicketConversation } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import ScrollableSection from 'src/components/layout/ScrollableSection';
import { notEmptyArray } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';
import useUserInfo from 'src/hooks/useUserInfo';

const ConversationHistory = () => {
  const { id: myUserId } = useUserInfo();
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { currentTicket, currentTicketConversation, setCurrentTicketConversation, addCurrentTicketConversationMessage, updateCurrentTicketConversationMessage } = useContext(TeacketContext);

  const [scrollDown, setScrollDown] = useState(true);

  useEffect(() => {
    if (!currentTicket?.id) return;
    if (!currentTicketConversation) loadData();
  }, [currentTicket]);

  useEffect(() => {
    if (true === scrollDown) return;
    setScrollDown(true);
  }, [scrollDown]);

  useEffect(() => {
    setScrollDown(false);
  }, [currentTicketConversation]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getTicketConversation(currentTicket.id);
        console.log('resp', resp);
        setCurrentTicketConversation(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!currentTicketConversation) return <LoadingLabel />;

  const handleOnWsMessageReceived = async (message) => {
    console.log('handleOnWsMessageReceived', message);
    try {
      switch (message.action) {
        case 'CONVERSATION_CREATED':
          addCurrentTicketConversationMessage(message.data);
          break;

        case 'CONVERSATION_UPDATED':
          updateCurrentTicketConversationMessage(message.data);

          break;

        default:
          console.error('Unknown action', message.action);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['CONVERSATION_CREATED', 'CONVERSATION_UPDATED']}></GlobalWsMessenger>
      {true === notEmptyArray(currentTicketConversation) && (
        <ScrollableSection maxHeight='50vh' scrollDown={scrollDown} edgeGradientColor={theme.backgroundBase + 'ee'}>
          {currentTicketConversation.map((item, index) => (
            <ConversationHistoryItem key={index} item={item} side={myUserId === item.user_id ? 'right' : 'left'} />
          ))}
        </ScrollableSection>
      )}
    </>
  );
};

export default ConversationHistory;
