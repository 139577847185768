import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getTimeTrackingDashboardUsersAccounts } from 'src/api/teacket';
import Space2 from 'src/components/layout/Space2';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import DashboardDataUsersAccountsByDate from './DashboardDataUsersAccountsByDate';
import Na from 'src/components/layout/Na';

const DashboardDataUsersAccounts = ({ userId, from, to, serviceId }) => {
  const { commandPressed } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const columns = [
    {
      title: 'Account',
      key: 'account',
      render: (record) => {
        if (!record.acc_id || !record.acc_name)
          return (
            <Space2>
              <Na />
              {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>no account</span>}
            </Space2>
          );
        return (
          <Space2>
            <span className='font-bold text-sm'>{record?.acc_name}</span>
            {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>{record?.acc_id}</span>}
          </Space2>
        );
      },
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className='text-sm'>{record?.hours}</span>,
    },
  ];

  useEffect(() => {
    console.log('userId:', userId);
    if (!userId) return;
    loadData();
  }, [userId]);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      const resp = await getTimeTrackingDashboardUsersAccounts(serviceId, userId, from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (!data && loading) return <SkeletonTable />;

  return (
    <CondensedTableLegacy
      showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'acc_id'}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }} className='pl-12'>
            <DashboardDataUsersAccountsByDate from={from} to={to} serviceId={serviceId} userId={userId} accountId={record?.acc_id} />
          </p>
        ),
        rowExpandable: () => true,
      }}
    />
  );
};

DashboardDataUsersAccounts.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  serviceId: PropTypes.string,
  userId: PropTypes.string,
};

export default DashboardDataUsersAccounts;
