import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { getDistinctPlatforms } from 'src/api/snowflake';
import { isRequestCancelled } from 'src/misc/Misc';

const PlatformPicker = ({ serviceId, defaultValue, onChange, allowClear = true, width = '100%' }) => {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options) return;
    loadData();
  }, []);

  useEffect(() => {
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [options]);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = (await getDistinctPlatforms())?.map((x) => {
        return { value: x, label: x };
      });
      setOptions(resp);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      setOptions([]);
      console.log(error);
    }
    setLoading(false);
  };

  const handleOnChange = (value) => {
    // console.log('handleOnChange()', value);
    setSelected(value);
    if (!onChange) return;

    if (!value) {
      onChange(null);
      return;
    }

    onChange(value);
  };

  return <Select placeholder='[ALL]' options={options} value={selected} style={{ width }} onChange={handleOnChange} loading={loading} allowClear={allowClear} showSearch />;
};

PlatformPicker.propTypes = {
  serviceId: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  width: PropTypes.string,
};

export default PlatformPicker;
