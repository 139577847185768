import React, { useState, useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAdminMessages, postAdminMessage } from 'src/api/notifications';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import useTheme from 'src/hooks/useTheme';
import { Space, Tag } from 'antd';

//import PropTypes from 'prop-types';

const AdminMsgsSelector = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { adminMessagesQuery, adminMessagesSelectedId, setAdminMessagesSelectedId, adminMessagesList, setAdminMessagesList, adminMessagesNewCreated, setAdminMessagesNewCreated } = useContext(MasterAdminContext);

  const pageSizeOptions = [20, 50, 100];

  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: 'Title / ID / Created at',
      key: 'title',
      render: (record) => {
        const NEW_ROW_PERIOD = 60 * 1000; // 1 minute in milliseconds
        const diff = dayjs().diff(dayjs(record.created_at));
        const newRow = diff <= NEW_ROW_PERIOD;
        return (
          <div>
            <div className='overflow-hidden' style={{ wordBreak: 'break-all' }}>
              <Space>
                <span className={record.id === adminMessagesSelectedId ? 'font-bold' : null}>
                  <Highlighter textToHighlight={record?.title ?? ''} searchWords={[`${adminMessagesQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
                </span>
                {newRow && (
                  <Tag color='blue' size='small'>
                    just created
                  </Tag>
                )}
              </Space>
            </div>
            <div className='flex flex-row gap-2'>
              <div className='w-1/2 text-[0.6rem] opacity-60'>
                <Highlighter textToHighlight={record?.id ?? ''} searchWords={[`${adminMessagesQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
              </div>
              <div className='w-1/2 text-[0.6rem] opacity-60 text-right'>created {dayjs(record.created_at).fromNow()}</div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setAdminMessagesList(null);
    setAdminMessagesSelectedId(null);
    loadData();
  }, [page]);

  useEffect(() => {
    setAdminMessagesList(null);
    setPage(1);
    setAdminMessagesSelectedId(null);
    loadData();
  }, [adminMessagesQuery, pageSize]);

  useEffect(() => {
    if (!adminMessagesNewCreated) return;
    CreateNew();
  }, [adminMessagesNewCreated]);

  const loadData = async (forcePage = null) => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminMessages(adminMessagesQuery, forcePage ?? page, pageSize);
        setTotal(resp?.total ?? 0);
        setAdminMessagesList(resp?.entities ?? null);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const CreateNew = () => {
    executeAsyncProcess(async () => {
      try {
        setAdminMessagesList(null);
        setPage(1);
        setAdminMessagesSelectedId(null);
        setAdminMessagesNewCreated(false);
        const resp = await postAdminMessage({ title: 'New message', description: 'New message content' });
        await loadData(1);
        setAdminMessagesSelectedId(resp.id);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <CondensedTableLegacy
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        columns={columns}
        dataSource={adminMessagesList}
        rowKey={'id'}
        onRow={(record) => {
          return {
            style: { cursor: 'pointer', background: record.id === adminMessagesSelectedId ? theme.backgroundLight : null },
            onClick: async () => {
              setAdminMessagesSelectedId(record.id);
            },
          };
        }}
      />
    </>
  );
};

AdminMsgsSelector.propTypes = {};

export default AdminMsgsSelector;
