import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, Tooltip, Tag, message } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { useParams } from 'react-router-dom';
import LoaderServicesForTenant from './Loaders/LoaderServicesForTenant';
import LoaderCurrentTicket from './Loaders/LoaderCurrentTicket';
import useUserInfo from 'src/hooks/useUserInfo';
import { MdContentCopy } from 'react-icons/md';
import { getComponentsGallery } from './Misc/ticketLayout';
import SubscribeForLiveEvents from './Components/TicketSubscribeForNotifiactions';
import TicketShare from './Components/TicketShare';
import { uuid } from 'short-uuid';
import TicketTypeTag from './Components/TicketTypeTag';
//import { useLocation } from 'react-router-dom';
//import PropTypes from 'prop-types';

const Ticket = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  //const location = useLocation(); // Access the current location

  const { currentTicket, currentTicketReadOnly, getWidgetListForCurrentTicket } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { ticketId } = useParams();
  const { permissions: userPermissions } = useUserInfo();

  const componentsGallery = getComponentsGallery(userPermissions, getWidgetListForCurrentTicket());
  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: <>Ticket</>,
      },
    ]);

    // const queryParams = new URLSearchParams(location.search);
    // const showMessage = queryParams.get('showCreateNewConfirmation');
    // const key = uuid();

    // if (showMessage) {
    //   message.success({
    //     content: 'Thank you for submitting the ticket. We will get back to you shortly.',
    //     duration: 10,
    //     key,
    //   });
    // }

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  const handleCopyUrl = () => {
    console.log('handleCopyUrl()');
    navigator.clipboard.writeText(getTicketUrl());
    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Copied',
      duration: 3,
    });
  };

  const getTicketUrl = () => `${window.location.origin}/teacket/tickets/${currentTicket.id}`;

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create', 'apps.teacket.ticket.edit']}>
      {contextHolder}
      <TitleBar
        title={
          <Space className='btn'>
            <BsCupHotFill />
            <div>
              <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
            </div>
          </Space>
        }
        afterTitleExtras={
          <div className='flex flex-row gap-2 items-center'>
            {currentTicket?.type && <TicketTypeTag type={currentTicket.type} styling='mb-1' />}
            <PageSwitch pageList={pageList} label={`Ticket ${currentTicket?.id ?? '...'}`} />
            {currentTicket && (
              <Tooltip title='Copy ticket URL' mouseEnterDelay={1}>
                <MdContentCopy className='hover:scale-110 cursor-pointer' onClick={handleCopyUrl} />
              </Tooltip>
            )}
            {currentTicket && currentTicketReadOnly && <Tag color='volcano'>Read only</Tag>}
          </div>
        }
      >
        <SubscribeForLiveEvents />
        <TicketShare />
        {layoutItemsSelector}
      </TitleBar>
      <LoaderServicesForTenant>
        <LoaderCurrentTicket ticketId={ticketId}>{currentTicket && <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={`${LOCAL_STORAGE_KEYS.layoutTeacketTicket}.${currentTicket.service_id}`} layoutHiddenKey={`${LOCAL_STORAGE_KEYS.layoutTeacketTicketHidden}.${currentTicket.service_id}`} />}</LoaderCurrentTicket>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// Ticket.propTypes = {};

export default Ticket;
