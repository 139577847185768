import { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { getDictionaryGroup } from 'src/api/dictionaries';

const useDictionary = (group) => {
  const { dictionary, updateDictionary } = useContext(GlobalContext);

  useEffect(() => {
    if (!dictionary[group]) loadDictionaryGroup();
  }, [group]);

  const loadDictionaryGroup = async () => {
    console.log('loadDictionaryGroup()', group);
    updateDictionary(group, []);
    const resp = (await getDictionaryGroup(group)) ?? [];
    if (resp) updateDictionary(group, resp);
  };

  return dictionary[group];
};

export default useDictionary;
