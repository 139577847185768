import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { getAdminServiceProps } from 'src/api/teacket';
import { isRequestCancelled } from 'src/misc/Misc';

const TicketTypePicker = ({ serviceIds, mode, defaultValue, onChange, allowClear = true, width = '100%', placeholder, disabled }) => {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!serviceIds?.length) {
      return;
    }

    if (options) return;

    loadData();
  }, [serviceIds]);

  useEffect(() => {
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [options]);

  const loadData = async () => {
    setLoading(true);
    try {
      let resp = [];
      for (const serviceId of serviceIds) {
        const ticketTypes = (await getAdminServiceProps(serviceId, 'ticketTypes'))?.map((x) => {
          return { value: x.type, label: x.name };
        });
        resp.push(...ticketTypes);
      }
      resp.unshift({ value: '[STANDARD]', label: '[STANDARD]' });
      setOptions(resp);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      setOptions([]);
      console.log(error);
    }
    setLoading(false);
  };

  const handleOnChange = (value) => {
    // console.log('handleOnChange()', value);
    setSelected(value);
    if (!onChange) return;

    if (!value) {
      onChange(null);
      return;
    }

    onChange(value);
  };

  return (
    <Select
      placeholder={placeholder || '[ALL]'}
      options={options}
      disabled={disabled}
      value={selected}
      style={{ width }}
      mode={mode}
      onChange={handleOnChange}
      loading={loading}
      allowClear={allowClear}
      showSearch
      optionRender={({ value }) => {
        const item = options.find((x) => x.value === value);
        if (!item) return null;
        return (
          <div className='flex flex-col'>
            <div className='overflow-hidden font-bold'>{item.label}</div>
            <div className='text-xs font-light opacity-60 overflow-hidden'>{item.value}</div>
          </div>
        );
      }}
    />
  );
};

TicketTypePicker.propTypes = {
  serviceIds: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  width: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TicketTypePicker;
