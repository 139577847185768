//#region Imports

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { ConfigProvider } from 'antd';
import CondensedTableLegacy from './CondensedTableLegacy';
// import styled from 'styled-components';
import { FileTextOutlined } from '@ant-design/icons';

//#endregion

//#region Dragging support

const DragIndexContext = createContext({
  active: -1,
  over: -1,
});

const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  let style = {};
  if (active && active === id) {
    style = {
      backgroundColor: 'gray',
      opacity: 0.5,
    };
  } else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? {
            borderRight: '1px dashed gray',
          }
        : {
            borderLeft: '1px dashed gray',
          };
  }
  return style;
};

const TableBodyCell = (props) => {
  const dragState = useContext(DragIndexContext);
  return (
    <td
      {...props}
      key={props.id}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
      }}
    />
  );
};

TableBodyCell.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
};

const TableHeaderCell = (props) => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
  });
  const style = {
    ...props.style,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
          userSelect: 'none',
        }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };
  return <th {...props} key={props.id} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

TableHeaderCell.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
};

//#endregion

const CondensedTable = ({ name, columns, dataSource, rowKey, pagination, noDataMessage }) => {
  const getInitialColumns = useCallback(() => {
    const savedOrder = JSON.parse(localStorage.getItem(`spc.layout.${name}.columnOrder`));
    let orderedColumns = columns;

    if (savedOrder && savedOrder.length > 0) {
      // Create a map of columns by their key
      const columnMap = columns.reduce((acc, col, i) => {
        acc[`${i}`] = col;
        return acc;
      }, {});

      // Map saved order to actual columns, fallback to original order if not found
      orderedColumns = savedOrder.map((key) => columnMap[key]).filter(Boolean);

      // Add any columns that weren't in the saved order
      const savedKeys = new Set(savedOrder);
      columns.forEach((col, i) => {
        if (!savedKeys.has(`${i}`)) {
          orderedColumns.push(col);
        }
      });
    }

    return orderedColumns.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        key: `header-${i}`,
      }),
      onCell: () => ({
        id: `${i}`,
        key: `cell-${i}`,
      }),
    }));
  }, [columns, name]); // Add dependencies

  const [tableColumns, setTableColumns] = useState(getInitialColumns);

  // Update columns when they change
  useEffect(() => {
    setTableColumns(getInitialColumns());
  }, [columns]);

  const [dragIndex, setDragIndex] = useState({
    active: -1,
    over: -1,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setTableColumns((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        const newOrder = arrayMove(prevState, activeIndex, overIndex);
        localStorage.setItem(`spc.layout.${name}.columnOrder`, JSON.stringify(newOrder.map((col) => col.key)));
        return newOrder;
      });
    }
    setDragIndex({
      active: -1,
      over: -1,
    });
  };

  const onDragOver = ({ active, over }) => {
    const activeIndex = tableColumns.findIndex((i) => i.key === active.id);
    const overIndex = tableColumns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left',
    });
  };

  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: 'center',
      }}>
      <FileTextOutlined
        style={{
          marginTop: 25,
          fontSize: 40,
        }}
      />
      <p>{noDataMessage}</p>
    </div>
  );

  return name ? (
    <DndContext sensors={sensors} modifiers={[restrictToHorizontalAxis]} onDragEnd={name ? onDragEnd : undefined} onDragOver={name ? onDragOver : undefined} collisionDetection={closestCenter}>
      <SortableContext items={tableColumns.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
        <DragIndexContext.Provider value={dragIndex}>
          <ConfigProvider renderEmpty={noDataMessage ? customizeRenderEmpty : undefined}>
            {/* <CondensedTable1> */}
            <CondensedTableLegacy
              columns={tableColumns}
              dataSource={dataSource}
              rowKey={rowKey}
              pagination={pagination}
              components={{
                header: {
                  cell: TableHeaderCell,
                },
                body: {
                  cell: TableBodyCell,
                },
              }}
            />
            {/* </CondensedTable1> */}
          </ConfigProvider>
        </DragIndexContext.Provider>
      </SortableContext>
      <DragOverlay>
        <th
          style={{
            backgroundColor: 'gray',
            padding: 16,
          }}>
          {tableColumns[tableColumns.findIndex((i) => i.key === dragIndex.active)]?.title}
        </th>
      </DragOverlay>
    </DndContext>
  ) : (
    <ConfigProvider renderEmpty={noDataMessage ? customizeRenderEmpty : undefined}>
      {/* <CondensedTable1> */}
      <CondensedTableLegacy
        columns={tableColumns}
        dataSource={dataSource}
        rowKey={rowKey}
        pagination={pagination}
        components={{
          header: {
            cell: TableHeaderCell,
          },
          body: {
            cell: TableBodyCell,
          },
        }}
      />
      {/* </CondensedTable1> */}
    </ConfigProvider>
  );
};

CondensedTable.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array,
  rowKey: PropTypes.string,
  pagination: PropTypes.object,
  noDataMessage: PropTypes.string,
};

export default CondensedTable;
